import { useEffect, useState } from "react";

//timeLeft should be in seconds 
const Timer = (props) => {
    const { timeLeft, callback } = props;
    const [ remainingSeconds, setRemainingSeconds ] = useState(timeLeft)
    
    useEffect(() => {
        const intervalId = setInterval(() => {
        setRemainingSeconds(prevSeconds => prevSeconds - 1);
        }, 1000);
    
        return () => clearInterval(intervalId);
    }, []);
    
    useEffect(() => {
        if (remainingSeconds === 0) {
        callback();
        }
    }, [remainingSeconds]);
    
    let currentSeconds = remainingSeconds;
    let displayHours = null;
    let displayMinutes = "00";
    let displaySeconds = "00";
    if(remainingSeconds >= 60*60){ //check for minimum hours
        displayHours = Math.floor(currentSeconds/(60*60));
        currentSeconds = currentSeconds - (displayHours*(60*60)); 
        displayHours = displayHours<10?"0"+displayHours:displayHours;               
    }
    if(currentSeconds >= 60 ){ //Minimum 1 minute
        displayMinutes = Math.floor(currentSeconds/60);
        currentSeconds = currentSeconds - (displayMinutes*60);
        displayMinutes = displayMinutes<10?"0"+displayMinutes:displayMinutes;                
    }
    currentSeconds = currentSeconds<10?"0"+currentSeconds:currentSeconds;
    return (<>{displayHours && (<>{displayHours}:</>)}{ displayMinutes }:{currentSeconds}</>);
}

export default Timer;