import axios from "axios";
import { env } from "../../../config";

export function getResultsRequest(request) {
    const { test_id, token } = request;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/results/test-results', { test_id: test_id }, { headers: headers })
            .then(function (response) {
                if (response && response.data && response.data.success) {
                    resolve({
                        status: 'success',
                        results: response.data.results
                    });
                } else {
                    resolve({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });
                }
            })
            .catch(function (error) {
                resolve({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}