import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Paper,
  Snackbar,
  TextField,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  AssignCourseStudentsRequest,
  getCourseStudentsRequest,
  getUnAssignedStudents,
  removeCourseStudentsRequest,
} from "../../../../redux/sagas/requests/course";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteDialog from "../../../../components/dialogs/DeleteDialog";

export default function AssignStudents() {
  const [students, setStudents] = useState([]);
  const [assignedStudents, setAssignedStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state) => state.user);
  const { courseId } = useParams();
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteAssign, setDeleteAssign] = useState(null);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    setLoading(true);
    setStudents([]);
    setSelectedStudents([]);
    const result = await getUnAssignedStudents({
      token,
      uuid: courseId,
    });
    if (result?.success) {
      setStudents(result.students);
    }
    const assignedStu = await getCourseStudentsRequest({
      token,
      uuid: courseId,
    });
    if (assignedStu?.assignedStudents) {
      setAssignedStudents(assignedStu?.assignedStudents);
    }
    setLoading(false);
  }

  const handleSelectedOptionsChange = (event, newValue) => {
    setSelectedStudents(newValue);
  };

  const filterOptions = createFilterOptions({
    limit: 10,
  });

  const handleAssignButtonClick = async () => {
    const student_list = selectedStudents.map((stu) => stu.id);
    if (student_list.length > 0) {
      setLoading(true);
      await AssignCourseStudentsRequest({
        token,
        uuid: courseId,
        students: student_list,
      });
      init();
      handleDialogClose();
      setSuccessMessage("Students have been successfully assigned.");
    }
  };

  const handleClearMessage = () => {
    setSuccessMessage("");
  };

  const handleDialogOpen = () => {
    setOpenAssignDialog(true);
  };
  const handleDialogClose = () => {
    setOpenAssignDialog(false);
  };

  const handleDeleteDialogOpen = (uuid) => {
    setDeleteAssign(uuid);
    setDeleteDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialog(false);
    setDeleteAssign(null);
  };

  const handleDeleteConfirm = async () => {
    if (deleteAssign) {
      await removeCourseStudentsRequest({
        token,
        uuid: deleteAssign,
      });
      setDeleteDialog(false);
      setDeleteAssign(null);      
      init();
      setSuccessMessage("Successfully remove.");
    }
  };
  // console.log("assignedStudents", assignedStudents);

  return (
    <Box sx={{ p: 2 }}>
      <DeleteDialog
        open={deleteDialog}
        handleClose={handleDeleteDialogClose}
        handleDeleteAction={handleDeleteConfirm}
        message={"Would you like to remove this student?"}
        title={"Confirm"}
      />
      <Snackbar
        open={successMessage !== ""}
        autoHideDuration={3000}
        onClose={handleClearMessage}
      >
        <Alert>{successMessage}</Alert>
      </Snackbar>
      <Dialog
        open={openAssignDialog}
        fullWidth={true}
        maxWidth={"xs"}
        onClose={handleDialogClose}
      >
        <DialogTitle>Assign Students</DialogTitle>
        <DialogContent>
          {loading && <LinearProgress />}
          <Autocomplete
            disabled={loading}
            multiple
            name="students"
            id="Students"
            value={selectedStudents}
            options={students}
            getOptionLabel={(option) =>
              option.first_name +
              " " +
              option.last_name +
              " ( " +
              option.email +
              " ) "
            }
            filterOptions={filterOptions}
            onChange={handleSelectedOptionsChange}
            renderInput={(params) => (
              <TextField {...params} variant="standard" label="Students" />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={handleAssignButtonClick}
            disabled={selectedStudents.length === 0 || loading}
            variant="contained"
          >
            Assign
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ textAlign: "center" }}>
        <Button onClick={handleDialogOpen} variant="outlined">
          Assign Students
        </Button>
        <Divider sx={{ mt: 2, mb: 2 }} />
      </Box>
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Assigned on</TableCell>
                <TableCell align="right">Progress</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assignedStudents.length === 0  && (
                  <TableRow>
                    <TableCell colSpan={4} sx={{ textAlign: 'center' }} >
                      <Alert severity="info">No students have been assigned.</Alert>
                    </TableCell>
                  </TableRow>
              )}
              {assignedStudents.map((stu, index) => (
                <TableRow
                  sx={{ "&:last-child td, &:last-child td": { border: 0 } }}
                  key={index}
                >
                  <TableCell
                    component="td"
                    scope="row"
                  >{`${stu.first_name} ${stu.last_name}`}</TableCell>
                  <TableCell align="right">
                    {new Date(stu.created_at).toLocaleString("en-GB", {
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    })}
                  </TableCell>
                  <TableCell align="right">{stu.progress ? stu.progress : 0}%</TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() =>
                        handleDeleteDialogOpen(stu.coursestudent_uuid)
                      }
                      disabled={stu.start_date !== null}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
