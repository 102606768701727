/** Actions */
export const GET_TESTS = "GET_TESTS";
export const GET_TEST = "GET_TEST";
export const START_TEST = "START_TEST";
export const START_PRACTICE_TEST = "START_PRACTICE_TEST";
export const SUBMIT_TEST_ANSWERS = "SUBMIT_TEST_ANSWERS";
export const GET_STUDENT_RESULTS = "GET_STUDENT_RESULTS";

const SET_TESTS = "SET_TESTS";
const SET_TEST = "SET_TEST";
const SET_TEST_STATUS = "SET_TEST_STATUS";
const SET_TIME_LEFT = "SET_TIME_LEFT";
const SET_STUDENT_RESULTS = "SET_STUDENT_RESULTS";
const SET_TEST_PRACTICE = "SET_TEST_PRACTICE";


const initialState = {
  tests: [],
  test: null,
  startTestStatus: false,
  timeLeft: 0,
  submittingAnswers: false,
  studentResults: null,
  isStudentResultsLoading: false,
  isPractice: false,
  practiceId: "",
};

export const getTests = () => ({
  type: GET_TESTS
});

export const setTests = (tests) => ({
  type: SET_TESTS,
  tests
});

export const getTest = (testId) => ({
  type: GET_TEST,
  testId
});

export const setTest = (test) => ({
  type: SET_TEST,
  test
});

export const startTest = (assignedId) => ({
  type: START_TEST,
  assignedId
});

export const startPracticeTest = (assignedId) => ({
  type: START_PRACTICE_TEST,
  assignedId
});

export const setTestStatus = (status) => ({
  type: SET_TEST_STATUS,
  status
});

export const setTimeLeft = (timeLeft = 0) => ({
  type: SET_TIME_LEFT,
  timeLeft
});

export const submitTestAnswers = (testAnswers, navigate) => ({
  type: SUBMIT_TEST_ANSWERS,
  testAnswers,
  navigate
});

export const getStudentResults = (testId) => ({
  type: GET_STUDENT_RESULTS,
  testId
});

export const setStudentResults = (results) => ({
  type: SET_STUDENT_RESULTS,
  results
});

export const setPracticeMode = (isPractice, practiceId) => ({
  type: SET_TEST_PRACTICE,
  isPractice,
  practiceId
});


const studentTestReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TESTS:
      const { tests } = action;
      return { ...state, tests, test: null };
    case SET_TEST:
      const { test } = action;
      return { ...state, test: test, startTestStatus: false };
    case START_TEST:
      return { ...state, startTestStatus: false, timeLeft: 0 };
    case START_PRACTICE_TEST:
      return { ...state, startTestStatus: false, timeLeft: 0 };
    case SET_TIME_LEFT:
      return { ...state, timeLeft: action.timeLeft };
    case SET_TEST_STATUS:
      return { ...state, startTestStatus: action.status };
    case SUBMIT_TEST_ANSWERS:
      return { ...state, testAnswers: action.testAnswers, submittingAnswers: false };
    case GET_STUDENT_RESULTS:
      return { ...state, studentResults: null, isStudentResultsLoading: true };
    case SET_STUDENT_RESULTS:
      return { ...state, studentResults: action.results, isStudentResultsLoading: false }
    case SET_TEST_PRACTICE:
      console.log("action", action)
      return { ...state, isPractice: action.isPractice, practiceId: action.practiceId }
    default:
      return state;
  }
}
export default studentTestReducer;