import { Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, Skeleton, Typography, useMediaQuery } from '@mui/material';
import MainCard from '../../components/MainCard';
import MainLayout from '../../layout/MainLayout';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import "./categories.css";
import CreateCategory from './CreateCategory';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories, getCategory, removeCategory } from '../../redux/reducers/category';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateCategory from './UpdateCategory';
import { useTheme } from '@emotion/react';


const Categories = () => {

    const [breadcrumbLinks, setBreadcrumbLinks] = useState([]);
    const [openCreateCategory, setOpenCreateCategory] = useState(false);
    const [openUpdateCategory, setOpenUpdateCategory] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const { categories, loadingCategories, category, parentCategories } = useSelector((state) => state.category);
    const theme = useTheme();        
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));    
    const { cateId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const handleDeleteDialogClose = () => {
        setOpenDeleteDialog(false);
    }

    const handleDeleteDialogOpen = () => {
        setOpenDeleteDialog(true);
    }

    const handleDeleteConfirmAction = () => {        
        let link = '/categories';
        if(category.parent_id){
            const pCate = parentCategories.find(item => item.id === category.parent_id);
            if(pCate.uuid){
                link += '/'+pCate.uuid;
            }
        }
        setOpenDeleteDialog(false);
        dispatch(removeCategory(category.uuid, navigate, link));        
    }

    const DeleteDialog = () => {        
        return (<Dialog
            fullScreen={fullScreen}
            open={openDeleteDialog}
            onClose={handleDeleteDialogClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">            
            { categories?.length > 0? "Can't delete this category!":"Would like to delete this category?"}
            </DialogTitle>                                            
            <DialogContent>            
                { categories?.length > 0?                
                <Alert severity="warning">You cannot remove this category until you remove all of its child categories. Please ensure that you delete any child categories associated with this category before attempting to remove it.</Alert>                                    
                :
                <DialogContentText>
                    Deleting these categories will prevent users from assigning them to records in the application.
                </DialogContentText>
                }
            </DialogContent>                            
            { categories?.length > 0?
            <DialogActions>
                <Button autoFocus onClick={ handleDeleteDialogClose } >Close</Button>
            </DialogActions>
            :
            <DialogActions>
                <Button autoFocus onClick={ handleDeleteDialogClose } >
                    No
                </Button>
                <Button autoFocus onClick={ handleDeleteConfirmAction } >
                    Yes
                </Button>
            </DialogActions>
            }
        </Dialog>);
    }

    useEffect(() => {
        dispatch(getCategories(cateId));
        if (cateId) {            
            dispatch(getCategory(cateId));
        }
    }, [, cateId]);

    useEffect(()=>{        
        let breadCrumbs = [];
        if(parentCategories.length > 0){
            breadCrumbs.push({
                id: `category-main`,
                title: `Categories`,
                url: `/categories`,  
            });     
            for(let i=parentCategories.length-1; i>=0; i--){            
                if(i === 0){
                    breadCrumbs.push({
                        id: `category-main${parentCategories[i].uuid}`,
                        title: `${parentCategories[i].name}`,                        
                    });
                }else{
                    breadCrumbs.push({
                        id: `category-main${parentCategories[i].uuid}`,
                        title: `${parentCategories[i].name}`,
                        url: `/categories/${parentCategories[i].uuid}`,                    
                    });
                }                
            }           
        }else{
            breadCrumbs.push({
                id: `category-main`,
                title: `Categories`,                
            });             
        }
        setBreadcrumbLinks(breadCrumbs);
    },[,parentCategories]);


    const handleCloseCreateCategory = () => {
        setOpenCreateCategory(false);
        dispatch(getCategories(cateId));
    }
    const handleOpenCreateCategory = () => {
        setOpenCreateCategory(true);
    }
    const handleCategoryClick = (uuid) => {
        navigate(`/categories/${uuid}`)
    }    
    const handleOpenUpdateCategory = () => {
        setOpenUpdateCategory(true);
    }
    const handleCloseUpdateCategory = () => {
        setOpenUpdateCategory(false);
        dispatch(getCategories(cateId));
        if (cateId) {            
            dispatch(getCategory(cateId));
        }
    }
    return (<MainLayout customBreadcrumblinks={breadcrumbLinks} >
            <CreateCategory open={openCreateCategory} handleCloseCreateCategory={handleCloseCreateCategory} cateId={cateId} />            
            { cateId &&
            <MainCard title="Category Details" sx={{ mb:2 }}>
                <UpdateCategory open={openUpdateCategory} handleClose={handleCloseUpdateCategory} category={category} />
                <DeleteDialog />
                <b>Category Name: { category?.name }</b>
                <Box sx={{ mt:3 }} >                   
                    <IconButton aria-label='Delete' onClick={handleDeleteDialogOpen} ><DeleteIcon /></IconButton>
                    <IconButton aria-label='Edit' onClick={handleOpenUpdateCategory} ><EditIcon /></IconButton>                    
                </Box>
            </MainCard>
            }
            <MainCard title={ cateId?'Sub Categories':'Categories' }>
                <Box sx={{ m: 1, mb: 4 }} >
                    <Button onClick={handleOpenCreateCategory} variant="contained" className="btn-add-test" startIcon={<AddIcon />} >Create Category</Button>
                </Box>        
                {loadingCategories &&
                    <Grid container>
                        <Grid item xs={12} sx={{ mb: 2 }} ><Skeleton variant="rounded" /></Grid>
                        <Grid item xs={12} sx={{ mb: 2 }} ><Skeleton variant="rounded" /></Grid>
                        <Grid item xs={12} sx={{ mb: 2 }} ><Skeleton variant="rounded" /></Grid>
                        <Grid item xs={12} sx={{ mb: 2 }} ><Skeleton variant="rounded" /></Grid>
                        <Grid item xs={12} sx={{ mb: 2 }} ><Skeleton variant="rounded" /></Grid>
                    </Grid>
                }
                <Grid container>
                    {categories.map(category => (
                        <Grid key={category.uuid} item xs={12} sm={4} md={3} xl={2}>
                            <Box onClick={() => handleCategoryClick(category.uuid)} key={category.uuid} className='category-container'>
                                <Typography variant='h5'>{category.name}</Typography>
                                <Typography variant='9'>{category.sub_category_count} sub categories</Typography><br />
                                {/* <Typography variant='9'>0 questions</Typography> */}
                            </Box>
                        </Grid>
                    )
                    )}
                </Grid>
            </MainCard>
        </MainLayout>);
}

export default Categories;

