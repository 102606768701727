import React, { useEffect, useRef, useState } from 'react';
import YouTubePlayer from 'youtube-player';

// function getYoutubeVideoId(youtubeLink) {
//     const videoID = youtubeLink.match(/[?&]v=([^&]+)/)[1];
//     return videoID;
// }

function getYoutubeVideoId(youtubeLink) {
    // Regular expression to match YouTube video ID from URL or embed code
    const regex = /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|.*&v=))([^"&?\/\s]{11})/;
    const match = youtubeLink.match(regex);
    if (match && match[1]) {
        return match[1];
    } else {
        return youtubeLink;
    }
}


const YoutubePlayerComponent = ({ uniqueId, link, width = "240", height = "240" }) => {

    const [videoId, setVideoId] = useState("");
    const playerRef = useRef(null);


    // useEffect(() => {
    //     console.log("link in", link);
    //     if (link !== "") {
    //         setVideoId(getYoutubeVideoId(link));
    //     } else {
    //         setVideoId("");
    //     }
    // }, [link]);

    // useEffect(() => {        
    //     let player;
    //     if (videoId !== "") {
    //         player = YouTubePlayer(`player-${uniqueId}`, {
    //             videoId: videoId,
    //             width: width,
    //             height:height,
    //             playerVars: {
    //                 autoplay: 0,
    //                 controls: 1,
    //                 disablekb: 0,
    //                 enablejsapi: 0,
    //                 modestbranding: 0,
    //                 rel: 0,
    //                 showinfo: 0
    //             }
    //         });
    //     }
    //     return () => {
    //         if (player) {
    //             player?.destroy();
    //         }
    //     };
    // }, [videoId]);

    // return <div id={`player-${uniqueId}`} ref={playerRef}></div>;
    console.log("videoId", link)
    return <iframe        
        allowFullScreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        title="Wildlife Windows 7 Sample Video"
        width={width}
        height={height}
        src={getYoutubeEmbedUrl(link)}></iframe>
};

export default YoutubePlayerComponent;



function getYoutubeEmbedUrl(url) {
    // Define a regular expression to extract the video ID from various YouTube URL formats
    const youtubeUrlRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=))([^#&?]*)/;
  
    // Extract the video ID from the provided URL using the regex
    const match = url.match(youtubeUrlRegex);
    let videoId;
    if (match) {
      videoId = match[1];
    } else {
      // If no match is found, assume the input is already the video ID
      videoId = url;
    }
  
    // Return the embed URL using the extracted video ID
    return `https://www.youtube.com/embed/${videoId}?autoplay=0&controls=1&disablekb=0&enablejsapi=1&modestbranding=0&rel=0&showinfo=0&widgetid=1`;
  }
{/* <iframe width="1236" height="695" src="https://www.youtube.com/embed/jVIQ89zmTMI" title="Test Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */ }
