import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MainCard from '../../components/MainCard';
import MainLayout from '../../layout/MainLayout';
import { getStudentById } from '../../redux/reducers/student';
import StudentForm from './forms/StudentForm';

const EditUser = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();    
    const { selectedUser } = useSelector((state) => state.student)             

    useEffect(()=> {                
        if(userId){                                                
            dispatch(getStudentById(userId));
        }
    },[])
    return (
        <MainLayout>
            <MainCard title="Update User">
                <StudentForm type="update" userId={userId} selectedUser={selectedUser} />
            </MainCard>
        </MainLayout>
    );
}

export default EditUser;
