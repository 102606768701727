import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  TablePagination,
  Alert,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { getTests } from "../../redux/reducers/test";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const TestsTable = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [testList, setTestList] = useState([]);
  const dispatch = useDispatch();
  const { tests } = useSelector((state) => state.test);

  useEffect(() => {
    setTestList(tests ? tests : []);
  }, [tests]);

  useEffect(() => {
    dispatch(getTests());
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredTests = testList.filter((test) =>
    test.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <TextField
        label="Search"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: 10 }}
        autoComplete="off"
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Auto Score</TableCell>
              <TableCell>Max Score</TableCell>
              <TableCell>Min Score</TableCell>
              <TableCell>Max Time (Minutes)</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTests
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((test, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Link to={"/tests/view/" + test?.uuid}>{test.name}</Link>
                  </TableCell>
                  <TableCell>
                    {test.auto_score ? "Yes" : "No"}
                  </TableCell>
                  <TableCell>{test.max_score}</TableCell>
                  <TableCell>{test.min_score}</TableCell>
                  <TableCell>{test.max_time}</TableCell>
                  <TableCell>
                    <Tooltip
                      title="Edit"
                      component={Link}
                      to={"/tests/edit/" + test.uuid}
                    >
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            {filteredTests?.length === 0 && (
              <TableRow>
                <TableCell colSpan={6}>
                  <Alert severity="warning">No result found!</Alert>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredTests.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default TestsTable;
