import axios from "axios";
import { env } from "../../../config";

class SectionAPI {
    static create(request) {
        const { section, token } = request;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': token
        };
        return new Promise((resolve, reject) => {
            axios.post(env.REACT_APP_API_HOST + '/api/access/section/create', section, { headers: headers })
                .then(function (response) {
                    if(response && response.data && response.data.status) {
                        resolve({
                            status: response.data.status,
                            message: response.data.message,                            
                        });
                    }else{
                        reject({
                            status: 'failed',
                            message: 'Something went wrong!'
                        });    
                    }                
                })
                .catch(function (error) {
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });
                });
        });
    }

    static update(request) {
        const { section, token } = request;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': token
        };
        return new Promise((resolve, reject) => {
            axios.post(env.REACT_APP_API_HOST + '/api/access/section/update', section, { headers: headers })
                .then(function (response) {
                    if(response && response.data && response.data.status) {
                        resolve({
                            status: response.data.status,
                            message: response.data.message,                            
                        });
                    }else{
                        reject({
                            status: 'failed',
                            message: 'Something went wrong!'
                        });    
                    }                
                })
                .catch(function (error) {
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });
                });
        });
    }

    static remove(request) {        
        const { uuid, token } = request;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': token
        };
        return new Promise((resolve, reject) => {
            axios.post(env.REACT_APP_API_HOST + '/api/access/section/delete', { uuid: uuid }, { headers: headers })
                .then(function (response) {
                    if(response && response.data && response.data.status) {
                        resolve({
                            status: response.data.status,
                            message: response.data.message,                            
                        });
                    }else{
                        reject({
                            status: 'failed',
                            message: 'Something went wrong!'
                        });    
                    }                
                })
                .catch(function (error) {
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });
                });
        });
    }

    static list(request) {
        const { testId, token } = request;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': token
        };        
        return new Promise((resolve, reject) => {
            axios.post(env.REACT_APP_API_HOST + '/api/access/section/list', { test_id: testId }, { headers: headers })
                .then(function (response) {
                    if(response && response.data && response.data.status) {
                        resolve(response.data.results);
                    }else{
                        reject({
                            status: 'failed',
                            message: 'Something went wrong!'
                        });    
                    }                
                })
                .catch(function (error) {
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });
                });
        });
    }

    static get(request) {
        const { uuid, token } = request;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': token
        };        
        return new Promise((resolve, reject) => {
            axios.post(env.REACT_APP_API_HOST + '/api/access/section/get', {uuid}, { headers: headers })
                .then(function (response) {
                    if(response && response.data && response.data.status) {
                        resolve(response.data.result);
                    }else{
                        reject({
                            status: 'failed',
                            message: 'Something went wrong!'
                        });    
                    }                
                })
                .catch(function (error) {
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });
                });
        });
    }

}
export default SectionAPI;