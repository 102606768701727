import { Alert, Avatar, Box, Button, Card, CardContent, Divider, Grid, IconButton, LinearProgress, Typography } from "@mui/material";
import React, { useEffect } from "react";
import AddIcon from '@mui/icons-material/Add';
import AddStudentsModal from "./AddStudentsModal";
import { useState } from "react";
import { addStudentsToTest, getStudents, removeStudent } from "../../../redux/reducers/test";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const StudentCard = (props) => {
    const [addModal, setAddModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { testId } = useParams();
    const { students } = props;


    const handleOpenModal = () => {
        setAddModal(true);
    }

    const handleCloseModal = () => {
        setAddModal(false);
    }

    const handleStudentAdd = (studentsIds) => {
        setAddModal(false);
        if (studentsIds.length > 0) {
            setLoading(true);
            const students = {
                'test_id': testId,
                'student_ids': studentsIds
            }
            dispatch(addStudentsToTest(students))
        }
    }

    const handleStudentRemoved = (uuid) => {
        dispatch(removeStudent(uuid, testId));        
    }

    return (
        <Box>
            <Box sx={{ p: 1, mb: 1, textAlign: 'center' }} >
                <Alert sx={{ mb: 2 }} severity='info' >To add new students, simply click on the 'Add Students' button and use the search function to find the desired students. Once you have found them, select them and they will be added to your list of students.</Alert>
                <Button onClick={handleOpenModal} variant="outlined" title="Add Students"> <AddIcon /> Add Students</Button>
                <AddStudentsModal modalOpen={addModal} handleModalClose={handleCloseModal} handleStudentAdd={handleStudentAdd} />
            </Box>
            <Divider />
            {loading && <LinearProgress />}
            <Grid container sx={{ mt: 2 }}>
                {students?.map((student) => (
                <Grid key={ student.students_tests_id } item sx={{ p:2 }} md={3} sm={6} xs={12} >
                    <Card>
                        <CardContent>
                            <Typography variant="h5" component="h2">{ student.first_name + " " + student.last_name }</Typography>
                            <Typography color="textSecondary" gutterBottom>
                                Assigned Date: { new Date(student.assigned_at).toLocaleString('en-GB', {
                                                    day: 'numeric',
                                                    month: 'numeric',
                                                    year: 'numeric',
                                                    hour: 'numeric',
                                                    minute: 'numeric',        
                                                }) }
                            </Typography>
                            <IconButton onClick={ () => handleStudentRemoved(student.students_tests_id) } >
                                <DeleteForeverIcon/>
                            </IconButton>
                        </CardContent>
                    </Card>
                </Grid>
                ))}
                {!students && <Alert severity="warning">No students have been added yet. Please add students to begin.</Alert> }
            </Grid>
        </Box>
    );
};

export default StudentCard;