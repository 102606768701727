import { useEffect } from "react";
import Layout from "../layout/Layout"
import { useDispatch, useSelector } from "react-redux";
import { setGoBackLink } from "../../redux/reducers/util";
import { Alert, Avatar, Backdrop, Box, Button, Checkbox, Chip, CircularProgress, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, LinearProgress, Paper, Radio, RadioGroup, Typography } from "@mui/material";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import moment from "moment/moment";
import { useState } from "react";
import { ArrowBackIos, ArrowForwardIos, Check, Clear, ExitToApp, MobileScreenShareOutlined } from "@mui/icons-material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ForwardIcon from '@mui/icons-material/Forward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Timer from "./Timer";
import { submitTestAnswers } from "../../redux/reducers/studentTest";
import "./startTest.css";
import SubmitAlert from "./SubmitAlert";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { env } from "../../config";
import axios from "axios";

const StartTest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { testId } = useParams();
  const [updateCounter, setUpdateCounter] = useState(0);
  const { test: testResponse, timeLeft, submittingAnswers, isPractice, practiceId } = useSelector((state) => state.studentTest);
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [studentAnswers, setStudentAnswers] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [questions, setQuestions] = useState([]);
  const [test, setTest] = useState(null);
  const [assignment, setAssignment] = useState(null);
  const [sections, setSections] = useState(null);
  const [isTimeExpired, setIsTimeExpired] = useState(false);
  const [answerStatus, setAnswerStatus] = useState([]);
  const [isAnswered, setIsAnswered] = useState(false);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [savedDataLoading, setSavedDataLoading] = useState(false);
  const [hasSavedData, setHasSavedData] = useState(false);
  const [activeSection, setActiveSection] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { token } = useSelector((state) => state.user);
  const [savedStudentAnswers, setSavedStudentAnswers] = useState([]);
  const [searchParams] = useSearchParams();
  const practiceMode = searchParams.get('practiceId') ? true : false;

  const headers = {
    "Content-Type": "application/json",
    Authorization: token,
  };

  useEffect(() => {
    if (studentAnswers?.length > 0) {
      storeProgress("studentAnswers", studentAnswers)
    }
    if (answerStatus?.length > 0) {
      storeProgress("answerStatus", answerStatus)
    }
  }, [studentAnswers, answerStatus, updateCounter]);


  const storeProgress = async (field_name, data) => {
    try {
      if (isPractice) {
        await axios.post(
          env.REACT_APP_API_HOST + `/api/access/studenttest/save-test-progress-data-practice`, {
          field_name,
          data: JSON.stringify(data),
          test_student_id: assignment?.uuid,
          practice_id: practiceId
        },
          { headers: headers }
        );
      } else {
        await axios.post(
          env.REACT_APP_API_HOST + `/api/access/studenttest/save-test-progress-data`, {
          field_name,
          data: JSON.stringify(data),
          test_student_id: assignment?.uuid
        },
          { headers: headers }
        );
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if (assignment && !isPractice) {
      getStoredProgress();
    }
  }, [assignment]);

  const getStoredProgress = async (field_name, data) => {
    try {
      setHasSavedData(false);
      setSavedDataLoading(true);
      const { data } = await axios.get(
        env.REACT_APP_API_HOST + `/api/access/studenttest/get-test-progress-data/${assignment?.uuid}`,
        { headers: headers }
      );
      if (data) {
        const studentAnswersIn = data?.find(item => item.field_name === "studentAnswers");
        if (studentAnswersIn?.data) {
          setSavedStudentAnswers(JSON.parse(studentAnswersIn?.data));
        }
        const answerStatusIn = data?.find(item => item.field_name === "answerStatus");
        if (answerStatusIn?.data) {
          setAnswerStatus(JSON.parse(answerStatusIn?.data));
        }
        setHasSavedData(true);
      }
      setSavedDataLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if (practiceMode) {
      dispatch(setGoBackLink(`/test/${testId}?practice=1`));
    } else {
      dispatch(setGoBackLink(`/test/${testId}`));
    }
    setTimeout(() => {
      if (!testResponse) {
        if (practiceMode) {
          navigate(`/test/${testId}?practice=1`);
        } else {
          navigate(`/test/${testId}`);
        }
      }
    }, 1500);
  }, [practiceMode]);

  const handleConfirmModalSubmit = () => {
    let submitAnswersFormatted = [];
    submitAnswersFormatted.assignmentId = assignment.uuid;
    submitAnswersFormatted.test_id = test.uuid;
    submitAnswersFormatted.answers = studentAnswers.map((assign) => {
      return { uuid: assign.uuid, attempted: assign?.attempted, options: assign.options };
    });
    setConfirmSubmit(false);
    setLoading(true);
    dispatch(submitTestAnswers(submitAnswersFormatted, navigate));
  }

  useEffect(() => {
    if (isTimeExpired) { //if time expired then submit the answers
      handleConfirmModalSubmit();
    }
  }, [isTimeExpired])

  useEffect(() => {
    if (testResponse) {
      const { sections: testSections } = testResponse;
      setTest(testResponse.test);
      setAssignment(testResponse.assignment);
      if (testSections && testSections?.length > 0) {
        setSections(testSections);
        let orderedQuestions = [];
        testSections.map(section => {
          section?.questions.map(quest => {
            const foundQuestion = testResponse.questions.find(q => q.id === quest.questions_id);
            orderedQuestions.push(foundQuestion);
          })
        });
        setQuestions(orderedQuestions);
      } else {
        setQuestions(testResponse.questions);
        setSections(null);
      }
    }
  }, [testResponse]);

  const getActiveSection = (question) => {
    for (let i = 0; i < sections?.length; i++) {
      const section = sections[i];
      for (let j = 0; j < section.questions.length; j++) {
        const _question = section.questions[j];
        if (_question.questions_id === question.id) {
          return _question.sections_id;
        }
      }
    }
    return null;
  };

  useEffect(() => {
    if (currentIndex >= 0) {
      let status = answerStatus;
      if (status[currentIndex] === undefined) {
        status[currentIndex] = "visited";
        setAnswerStatus(status);
      }
      setActiveQuestion(studentAnswers[currentIndex]);
      let _isAnswered = false;
      studentAnswers[currentIndex]?.options.map(option => { if (option.checked === true) { _isAnswered = true; } });
      setIsAnswered(_isAnswered);
      setActiveSection(getActiveSection(studentAnswers[currentIndex]));
    } else {
      setActiveSection(null);
    }
  }, [currentIndex]);

  useEffect(() => { //initialize student answers        
    if (questions && !savedDataLoading) {
      if (savedStudentAnswers?.length > 0) {
        setStudentAnswers(savedStudentAnswers);
        setCurrentIndex(answerStatus?.length ?? 0);
      } else {
        let answers = questions.map((question) => {
          return { ...question, attempted: false, options: question?.options.map((option) => { return { ...option, checked: false }; }) };
        });
        setStudentAnswers(answers);
      }
    }
  }, [questions, savedDataLoading]);

  useEffect(() => {
    if (questions && questions.length > 0) {
      setCurrentIndex(0);
    }
  }, [questions]);

  const timeCompleted = () => { //Terminate the test
    setIsTimeExpired(true);
  };

  const handleAnswerChange = (e) => {
    let answers = studentAnswers;
    if (answers[currentIndex]) {
      let answer = { ...answers[currentIndex] };
      let options = answer.options;
      options.map((option, i) => {
        if (e.target.value === option.uuid) {
          options[i].checked = e.target.checked;
          setIsAnswered(true);
        } else {
          options[i].checked = false;
        }
      });
      answer.options = options;
      answer.attempted = true;
      answers[currentIndex] = answer;
      setStudentAnswers(answers);
      setUpdateCounter(updateCounter + 1);
      setActiveQuestion(studentAnswers[currentIndex]);
    }
  }

  if (!test || submittingAnswers) {
    return (<Layout><LinearProgress /></Layout>);
  }
  if (isTimeExpired) {
    return (<Layout>
      <Box flexDirection="column" display="flex" justifyContent="center" alignItems="center" height="80vh">
        <Alert severity="warning" >Attention: Your allotted time for this test has elapsed. Please make sure to manage your time effectively in future tasks. Thank you for your participation</Alert>
        <Box marginTop={1}>
          <IconButton component={Link} to={`/test/${testId}`} title="Go Back"><ExitToApp /></IconButton>
        </Box>
      </Box>
    </Layout>);
  }
  const handleChangeQuestion = (id) => {
    setCurrentIndex(questions.findIndex(q => q.id === id));
  }

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  }

  const handleNext = () => {
    if (currentIndex < questions.length) {
      setCurrentIndex(currentIndex + 1);
    }
  }

  const handleAnswerClear = () => {
    let answers = studentAnswers;
    if (answers[currentIndex]) {
      let answer = { ...answers[currentIndex] };
      let options = answer.options;
      options.map((option, i) => { options[i].checked = false; });
      answer.options = options;
      answer.attempted = false;
      answers[currentIndex] = answer;
      setStudentAnswers(answers);
      setUpdateCounter(updateCounter + 1);
      answers[currentIndex] = answer;
      let newStatus = answerStatus;
      newStatus[currentIndex] = "visited";
      setAnswerStatus(newStatus);
      setIsAnswered(false);
    }
  }

  const handleMarkReviewAndNext = () => {
    let newStatus = answerStatus;
    newStatus[currentIndex] = "review";
    setAnswerStatus(newStatus);
    if (questions.length - 1 !== currentIndex) {
      handleNext();
    }
  }

  const handleMarkReviewSaveAndNext = () => {
    let newStatus = answerStatus;
    newStatus[currentIndex] = "save-review";
    setAnswerStatus(newStatus);
    setUpdateCounter(updateCounter + 1);
    if (questions.length - 1 !== currentIndex) {
      handleNext();
    }
  }

  const handleSaveAndNext = () => {
    let newStatus = answerStatus;
    newStatus[currentIndex] = "save";
    setAnswerStatus(newStatus);
    setUpdateCounter(updateCounter + 1);
    if (questions.length - 1 !== currentIndex) {
      handleNext();
    }
  }

  const handleQuestionSubmit = () => {
    setConfirmSubmit(true);
  }

  const handleCloseSubmitConfirm = () => {
    setConfirmSubmit(false);
  }



  const handleSectionClick = (id) => {
    const _section = sections.find(section => section.id === id);
    const _question = _section.questions[0];
    setCurrentIndex(questions.findIndex(q => q.id === _question.questions_id));
  }

  let sectionQuestions = [];
  let c_index = -1;
  sections?.map((section, sectionIndex) => {
    sectionQuestions.push(
      <Box key={section.uuid} >
        <Divider sx={{ mb: 1, mt: 2 }} >{section.name}</Divider>
        {section.questions.map((question, questionIndex) => {
          c_index++;
          let selectClass = ""
          if (answerStatus[c_index] !== undefined) {
            switch (answerStatus[c_index]) {
              case "visited":
                selectClass = "not-answered-block";
                break;
              case "review":
                selectClass = "marked-review-block";
                break;
              case "save-review":
                selectClass = "answered-marked-review-block";
                break;
              case "save":
                selectClass = "answered-block";
                break;
              default:
                break;
            }
          }
          return <Box onClick={(e) => handleChangeQuestion(question.questions_id)} key={question.uuid} sx={{ cursor: "pointer" }} className={`status-block ${selectClass}`}><span className="top-bar" ></span>{c_index + 1}</Box>;
        })}
      </Box>
    );
  })
  return (
    <Layout>
      <Backdrop open={loading} sx={{ zIndex: 1 }} >
        <CircularProgress />
      </Backdrop>
      <SubmitAlert open={confirmSubmit} handleClose={handleCloseSubmitConfirm} handleSubmit={handleConfirmModalSubmit} answerStatus={answerStatus} questions={questions} />
      <Grid container sx={{ p: 5, pt: 2 }}>
        <Grid item xs={6} sm={6} md={3} xl={2} sx={{ p: 1 }} >
          <Paper sx={{ p: 1, textAlign: "center" }} >
            <Typography variant="p" color="text.secondary" >Time Left</Typography>
            <Typography variant="h5"><Timer timeLeft={(timeLeft)} callback={timeCompleted} /></Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} ></Grid>
        {/* <Grid item xs={6} sm={6} md={3} xl={2} sx={{ p: 1 }} >
                    <Paper sx={{ p: 1, textAlign: "center" }} >
                        <Typography variant="p" color="text.secondary" >Subject</Typography>
                        <Typography variant="h5">Maths</Typography>
                    </Paper>
                </Grid> */}
        {(sections && sections.length > 0) && (
          <Grid item xs={12} md={12}  >
            {sections.map(section => (
              <Chip
                onClick={() => handleSectionClick(section.id)}
                key={section.uuid}
                label={section.name}
                className={`section-select-chip ${activeSection === section.id ? 'active' : ''}`}
              />
            ))}
            <Divider />
          </Grid>
        )}
        <Grid item xs={12} md={9}>
          <Grid component={Paper} sx={{ m: 1, p: 1 }} item xs={12} md={12}>
            <Box>
              {currentIndex + 1}. <Box sx={{ display: "inline-block" }} dangerouslySetInnerHTML={{ __html: activeQuestion?.description }} />
            </Box>
          </Grid>
          {activeQuestion?.type === "objective" && activeQuestion?.options.length > 0 ? (
            <Grid component={Paper} sx={{ m: 1, p: 1 }} item xs={12} md={12}>
              <FormGroup>
                {activeQuestion?.options.map(option => {
                  return (
                    <FormControlLabel key={option.uuid} value={option.uuid} label={<span dangerouslySetInnerHTML={{ __html: option.text }} />} control={<Radio checked={option.checked} onChange={handleAnswerChange} />} />
                  )
                })}
              </FormGroup>
            </Grid>) :
            (
              <>Descriptive</>
            )
          }
          <Grid item xs={12} md={12} sx={{ pl: 1, pr: 1, mt: 1 }} >
            <Box display={isMobile ? "block" : "flex"} >
              <Button fullWidth={isMobile} onClick={handleAnswerClear} variant="outlined" sx={{ mb: 1, mr: 1, color: 'red' }} color="primary" ><Clear /> {'Clear'}</Button>
              <Button fullWidth={isMobile} sx={{ mb: 1, mr: 1 }} onClick={handleMarkReviewAndNext} variant="outlined"><ForwardIcon /> {'Mark for Review'} {questions.length - 1 === currentIndex || `& Next`}</Button>
              <Box flexGrow={1} />
              <Button fullWidth={isMobile} disabled={!isAnswered} onClick={handleMarkReviewSaveAndNext} variant="outlined" sx={{ mb: 1, mr: 1, color: 'blue' }} ><CheckCircleOutlineIcon /> {'Mark for Review & Save'}</Button>
              <Button fullWidth={isMobile} disabled={!isAnswered} onClick={handleSaveAndNext} variant="outlined" sx={{ mb: 1, color: 'green' }} ><ArrowForwardIosIcon /> {(questions.length - 1 === currentIndex ? `Save` : `Save & Next`)}</Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} sx={{ pl: 1, pr: 1, mt: 1 }} >
            <Divider sx={{ mb: 1 }} />
            <Box display={isMobile ? "block" : "flex"} >
              <Button fullWidth={isMobile} sx={{ mb: 1, mr: 1 }} disabled={currentIndex === 0} onClick={handlePrevious} startIcon={<ArrowBackIos />} variant="contained">
                {'Previous'}
              </Button>
              <Button fullWidth={isMobile} sx={{ mb: 1, mr: 1 }} disabled={questions.length - 1 === currentIndex} onClick={handleNext} endIcon={<ArrowForwardIos />} variant="contained">
                {'Next'}
              </Button>
              <Box flexGrow={1} />
              <Button fullWidth={isMobile} sx={{ mb: 1 }} onClick={handleQuestionSubmit} endIcon={<Check />} variant="contained" color="success">
                {'Submit'}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3} sx={{ p: 1 }}>
          <Paper sx={{ p: 1 }} >
            <Box component="span" className="status-block" >{questions.length - answerStatus.filter(Boolean).length}</Box> <b>Not visited</b>
            <Divider sx={{ m: 1 }} />
            <Box component="span" className="status-block not-answered-block" >{answerStatus.filter((s) => s === "visited").length}</Box> <b>Not Answered</b>
            <Divider sx={{ m: 1 }} />
            <Box component="span" className="status-block answered-block" >{answerStatus.filter((s) => s === "save").length}</Box> <b>Answered</b>
            <Divider sx={{ m: 1 }} />
            <Box component="span" className="status-block marked-review-block" >{answerStatus.filter((s) => s === "review").length}</Box> <b>Marked for review</b>
            <Divider sx={{ m: 1 }} />
            <Box component="span" className="status-block answered-marked-review-block" ><span className="top-bar" ></span>{answerStatus.filter((s) => s === "save-review").length}</Box> <b>Answered & Marked for Review (will be evaluated)</b>
          </Paper>
          {(sections && sections.length > 0) ? (
            <Paper sx={{ p: 1, mt: 2 }} className="questionlist-block" >
              {sectionQuestions}
            </Paper>
          ) : (
            <Paper sx={{ p: 1, mt: 2 }} className="questionlist-block" >
              {questions.map((question, index) => {
                let selectClass = ""
                if (answerStatus[index] !== undefined) {
                  switch (answerStatus[index]) {
                    case "visited":
                      selectClass = "not-answered-block";
                      break;
                    case "review":
                      selectClass = "marked-review-block";
                      break;
                    case "save-review":
                      selectClass = "answered-marked-review-block";
                      break;
                    case "save":
                      selectClass = "answered-block";
                      break;
                    default:
                      break;
                  }
                }
                return <Box onClick={(e) => handleChangeQuestion(question.id)} key={index} sx={{ cursor: "pointer" }} className={`status-block ${selectClass}`}><span className="top-bar" ></span>{index + 1}</Box>;
              })}
            </Paper>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
}
export default StartTest;