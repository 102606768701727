import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Box, LinearProgress, Alert } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as yup from 'yup';
import "./items.css";
import { courseItemUploadRequest, createCourseItemRequest, updateCourseItemRequest } from '../../../../../redux/sagas/requests/course';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function VideoItem({ open, handleClose, type, onSuccess, updateItem }) {
  const [fileItem, setFileItem] = useState("");
  const { token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [publicUrl, setPublicUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { courseId, cateId } = useParams();

  function initState() {
    setFileItem();
    setLoading(false);
    setPublicUrl("");
    setErrorMessage("");
    formik.resetForm();
  }

  useEffect(() => {
    if (updateItem) {
      setPublicUrl(updateItem.url);
      setFileItem(updateItem.url);
    }
  }, [updateItem]);

  const handleCloseThis = () => {
    initState();
    handleClose();
  }


  const formik = useFormik({
    initialValues: {
      title: updateItem?.name ?? "",
    },
    validationSchema: yup.object({
      title: yup.string().required('Title is required'),
    }),
    onSubmit: async (values) => {
      if (publicUrl === "") {
        setErrorMessage("Upload Video");
      } else {
        if (updateItem) { //update
          await updateCourseItemRequest({
            token,
            input: {
              uuid: updateItem.uuid,
              name: values.title,
              url: publicUrl,
            }
          });
        } else {
          await createCourseItemRequest({
            token,
            input: {
              name: values.title,
              course_id: courseId,
              url: publicUrl,
              type: type,
              categoryUuid: cateId
            }
          });
        }
        initState();
        onSuccess('Video content has successfully created');
      }
    }
  });

  const handleUploadFile = async (event) => {
    const selectedFile = event.target.files[0];
    const isVideo = selectedFile.type.startsWith('video');
    if (isVideo) {
      setErrorMessage("");
      setLoading(true);
      const formData = new FormData();
      formData.append("upload", selectedFile);
      const res = await courseItemUploadRequest({
        token,
        formData
      });
      if (res.success) {
        setFileItem(selectedFile);
        setPublicUrl(res.url);
      } else {
        setErrorMessage("Something went wrong!");
      }
      setLoading(false);
    }
  }

  const handleResetUpload = () => {
    setFileItem("");
    setPublicUrl("");
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="xs"
      onClose={handleCloseThis}
    >
      <DialogTitle sx={{ fontSize: "17px", fontWeight: "bold" }}>
        Video Content
        <IconButton
          aria-label="close"
          onClick={handleCloseThis}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {errorMessage !== "" && <Alert severity='error'>{errorMessage}</Alert>}
        <TextField
          autoFocus
          margin="dense"
          id="title"
          label="Content title"
          type="text"
          fullWidth
          variant="standard"
          placeholder='Introduction to trigonometry'
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />
        {loading && (
          <LinearProgress sx={{ mt: 5 }} />
        )}
        {(!loading && publicUrl === "") && (
          <Button sx={{ mt: 5 }} fullWidth component="label" variant="contained" startIcon={<CloudUploadIcon />}>
            <VisuallyHiddenInput onChange={handleUploadFile} name='attachedFile' type="file" accept='video/*' /> Upload
          </Button>
        )}
        {
          publicUrl &&
          <div>
            <Box className="video-container" >
              <video className="video-item" controls controlsList="nodownload">
                <source src={publicUrl} />
                Your browser does not support the video.
              </video>
            </Box>
            <Button onClick={handleResetUpload} sx={{ mt: 1 }} >Reset</Button>
          </div>

        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseThis} >Cancel</Button>
        <Button disabled={fileItem === "" || formik.values.title === ""} variant="contained" onClick={formik.handleSubmit} >Save</Button>
      </DialogActions>
    </Dialog>
  )
}
