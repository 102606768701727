import { Alert, Autocomplete, Box, Button, CircularProgress, Divider, Modal, Paper, TextField, Typography } from '@mui/material';
import './AddStudentsModal.css';
import { useEffect, useState } from 'react';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { getStudentList } from '../../../redux/reducers/student';
import { useParams } from 'react-router-dom';
import { getUnAssignedStudents } from '../../../redux/reducers/test';

export default function AddStudentsModal({ modalOpen, handleModalClose, handleStudentAdd }) {
    
    const { unAssignedStudents } = useSelector((state) => state.test);    
    const [selectedOptions, setSelectedOptions] = useState([]);
    const { testId } = useParams();
    const dispatch = useDispatch();

    const filterOptions = createFilterOptions({
        limit: 10
    });

    const handleSelectedOptionsChange = (event, newValue) => {
        setSelectedOptions(newValue);
    };

    const handleAddButton = () => {
        let studentIds = [];        
        selectedOptions.forEach(element => {
            studentIds.push(element.id+"");
        });        
        handleStudentAdd(studentIds);        
    }
    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Box >
                <Paper sx={{ minWidth: "350px" }} >
                    <Typography sx={{ m: 1, fontSize: "15px", textAlign: "center", pt: 1 }} variant="h4">Add Students</Typography>
                    <Divider />
                     {unAssignedStudents? (
                        <Box sx={{ mb: 2, p: 1 }} >
                            <Autocomplete
                                multiple
                                id="tags-students"
                                options={unAssignedStudents}                            
                                getOptionLabel={(option) => option.first_name + " " + option.last_name + " ( " + option.email + " ) "}
                                filterOptions={filterOptions}
                                onChange={handleSelectedOptionsChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Students"
                                        placeholder="Type student name or email"
                                    />
                                )}
                            />
                            <p>{ unAssignedStudents?.length } records found</p>
                        </Box>
                     ):(
                        <Alert sx={{ mt: 2, mb: 2 }} severity='warning'>No students are available to add!</Alert>
                     )}   
                    <Divider />
                    <Box sx={{ p: 2, textAlign: "right" }} >
                        <Button onClick={handleModalClose} sx={{ mr: 1 }} variant="contained" >Cancel</Button>
                        {unAssignedStudents && (
                        <Button onClick={handleAddButton} variant="contained" color="success" >Add</Button>
                        )}
                    </Box>
                </Paper>
            </Box>
        </Modal>
    );
}
