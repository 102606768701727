import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import MainCard from '../../components/MainCard';
import MainLayout from '../../layout/MainLayout';
import { getUserById } from '../../redux/reducers/user';
import UserForm from './forms/UserForm';

const EditUser = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();    
    const { selectedUser } = useSelector((state) => state.user)             

    useEffect(()=> {                
        if(userId){                                    
            dispatch(getUserById(userId));
        }
    },[])
    return (
        <MainLayout>
            <MainCard title="Update User">
                <UserForm type="update" userId={userId} selectedUser={selectedUser} />
            </MainCard>
        </MainLayout>
    );
}

export default EditUser;
