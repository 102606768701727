import { useTheme } from "@emotion/react";
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeSection } from "../../../redux/reducers/section";
import { useEffect } from "react";

const SectionDelete = ({ open, uuid, onClose, refetchSections }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const { loading, message, error } = useSelector((state) => state.section)
    const [alert, setAlert] = useState(null);

    const handleClose = () => {
        if (!loading)
            onClose();
    }

    const handleDelete = () => {        
        dispatch(removeSection(uuid));
    }

    const handleAlertClose = () => {
        setAlert(null);
    }

    useEffect(() => {
        if (message?.status === "success") {
            onClose();
            refetchSections();
            setAlert({
                severity: 'success',
                message: message.message
            });
        }
        if (error) {
            setAlert({
                severity: 'error',
                message: error.message
            });
        }
    }, [message, error]);

    return (
        <>
            <Snackbar open={alert !== null} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity={alert?.severity} sx={{ width: '100%' }}>{alert?.message}</Alert>
            </Snackbar>
            <Dialog
                open={open}
                fullScreen={fullScreen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{ fontWeight: 'bold' }} >
                    {"Confirm Deletion"}
                </DialogTitle>
                <DialogContent>
                    {loading ? (
                        <Box sx={{ textAlign: 'center', mt: 5, pb: 5 }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <DialogContentText id="alert-dialog-description">
                            Deleting this section will remove all the questions within it permanently. This action cannot be undone. Are you sure you want to proceed with the deletion?
                        </DialogContentText>
                    )}

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={handleDelete} color="error" autoFocus>Yes</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default SectionDelete;