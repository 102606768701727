import MainCard from '../../components/MainCard';
import MainLayout from '../../layout/MainLayout';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import StudentTable from './StudentTable';


const Students = () => (
    <MainLayout>
        <MainCard title="Student List">
            <Box sx={{ m:1, mb:4 }} display="flex" justifyContent="flex-end">
                <Button component={Link} to="/students/new" variant="contained" size="small">Add Student</Button>                
            </Box>
            <StudentTable />
        </MainCard>
    </MainLayout>
);

export default Students;
