import './App.css';

import ThemeCustomization from './themes';
import ProtectedRoutes, { useAuth } from "./ProtectedRoutes";

import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Login from './pages/auth/Login';
import { Backdrop, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { adminRoutes } from './routes/AdminRoutes';
import { studentRoutes } from './routes/StudentRoutes';
import Register from './pages/auth/Register';
import VerifyAccount from "./pages/auth/VerifyAccount"
import ForgotPassword from './pages/auth/ForgotPassword';

function Logout() {
  localStorage.clear();
  window.location.href = '/login';
}

function App() {  
  const { role } = useSelector((state) => state.user);
  const isAuth = useAuth();

  let routes = [];  
  if (role && role < 50) { //Admin user
    routes = adminRoutes(role);
  }else if (role === "100"){
    routes = studentRoutes;
  }
  
  return (
    <ThemeCustomization>
      <div className="App">
        <Backdrop
          sx={{ color: '#fff', zIndex: 10000 }}
          open={false}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Routes>          
            {isAuth ? (
              <Route path='/' element={<Navigate to="/dashboard" />} />
            ): (
              <Route path='/' element={<Navigate to="/login" />} />
            )}          
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/verify' element={<VerifyAccount />} />   
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/logout' element={<Logout />} />
          <Route element={<ProtectedRoutes />}>
            {routes.map(route =>(
              <Route key={route.path} path={route.path} element={route.element} />
            ))}    
          </Route>
        </Routes>
      </div>
    </ThemeCustomization>
  );
}

export default App;
