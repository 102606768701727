import { Box, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getResultsRequest } from "../../../redux/sagas/requests/results";

const Results = ({ testId, studentId }) => {
    const { token } = useSelector((state) => state.user);
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchResult();
    }, []);

    const fetchResult = async () => {
        try{
            setLoading(true);
            const res = await getResultsRequest({ token, test_id: testId });
            setResults(res.results);            
        }catch(err){

        } finally {
            setLoading(false);
        }        
    }
    
    return (
        <Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Student Name</TableCell>
                            <TableCell>Rank</TableCell>
                            <TableCell>Score</TableCell>
                        </TableRow>
                    </TableHead>
                    {loading ? (
                        <TableBody><TableRow ><TableCell colSpan={3} ><LinearProgress /></TableCell></TableRow></TableBody>) : (
                        <TableBody>
                            {results?.map((result, index) => (
                                <TableRow key={index} sx={{ backgroundColor: result?.student_id+"" === studentId?'#e6efff':'#ffffff' }} >
                                    <TableCell>{ `${result?.first_name} ${result?.last_name}` }</TableCell>
                                    <TableCell>{ result?.score === null? (<i>Test not started</i>): index+1 }</TableCell>
                                    <TableCell>{ result?.score === null? (<i>Test not started</i>): result?.score }</TableCell>                                    
                                </TableRow>
                            ))}
                        </TableBody>)}
                </Table>
            </TableContainer>
        </Box>
    );
}

export default Results;