import React from "react";
import "./itemlist.css";
import { Box, Button, Grid, IconButton } from "@mui/material";
import {
  ITEM_IMAGE,
  ITEM_PDF,
  ITEM_TEXT,
  ITEM_VIDEOS,
  ITEM_YOUTUBE,
  ITEM_CATEGORY,
  ITEM_TEST,
} from "../../../courses/constants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TestItem } from "./components/testItem";
import YoutubePlayerComponent from "../../../../components/YouTubePlayer";

export default function ItemList({
  libraryItems,
  handleUpdate,
  handleDelete,
  changePosition,
}) {
  const navigate = useNavigate();
  // const { courseId } = useParams();

  const handleCategoryViewClick = (item) => {
    navigate(`/library/${item.uuid}`);
  };

  return (
    <Grid container sx={{ m: 2 }}>

      {libraryItems.map((item, index) => (
        <Grid
          key={item.uuid}
          item
          md={3}
          sm={6}
          xs={12}
          className="itemcontainer"
        >
          <div className="courseitem">
            <h3 className="courseitem-title">
              {item?.type.replace("ITEM_", "")}
            </h3>
            <div className="courseitem-content">
              <h5 className="courseitem-content-title">{item?.name}</h5>
              {item?.type === ITEM_TEXT && (
                <div
                  className="courseitem-content-text"
                  dangerouslySetInnerHTML={{ __html: item.text }}
                ></div>
              )}
              {item?.type === ITEM_IMAGE && (
                <img className="courseitem-content-image" src={item.url} />
              )}
              {item?.type === ITEM_VIDEOS && (
                <video className="courseitem-content-video" controls controlsList="nodownload">
                  <source src={item.url} />
                  Your browser does not support the video.
                </video>
              )}
              {(item?.type === ITEM_PDF) && (
                <iframe width="100%" height="240px" src={item.url} />
              )}
              {(item?.type === ITEM_YOUTUBE) && (
                <YoutubePlayerComponent uniqueId={item.uuid} width="100%" height="240px" link={item?.url} />
              )}
              {item?.type === ITEM_CATEGORY && (
                <Box sx={{ mt: 2, p: 2 }}>
                  <Button
                    onClick={() => handleCategoryViewClick(item)}
                    sx={{ width: "100%" }}
                    variant="contained"
                  >
                    View
                  </Button>
                </Box>
              )}
              {item?.type === ITEM_TEST && (
                <TestItem item={item} />
              )}
            </div>
            <div className="courseitem-actions">
              <IconButton onClick={() => handleUpdate(item)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDelete(item)}>
                <DeleteIcon />
              </IconButton>
              {/* <IconButton
                onClick={() => changePosition(item.uuid, item.position - 1)}
                disabled={index === 0}
              >
                <KeyboardArrowLeftIcon />
              </IconButton> */}
              {/* <IconButton
                onClick={() => changePosition(item.uuid, item.position + 1)}
                disabled={index === libraryItems.length - 1}
              >
                <KeyboardArrowRightIcon />
              </IconButton> */}
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  );
}
