import PropTypes from 'prop-types';

// material-ui
import { Box, Grid, LinearProgress } from '@mui/material';

// project import
import AuthCard from './AuthCard';
import Logo from '../../assets/images/logo_only.jpg';
import AuthFooter from '../../components/cards/AuthFooter';

// assets
import AuthBackground from '../../assets/images/background-img.jpg';
import { ButtonBase } from '@mui/material';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

// ==============================|| AUTHENTICATION - WRAPPER ||============================== //

const AuthWrapper = ({ children }) => {
    const isMobile = useMediaQuery('(max-width:776px)');
    console.log("isMobile", isMobile);
    return (
        <Box sx={{ minHeight: '100vh' }}>
            <Grid
                container
                direction="column"
                justifyContent="flex-end"
                sx={{
                    minHeight: '100vh'
                }}
            >
                {isMobile ? (
                    <Grid item xs={12} sx={{ ml: 3, mt: 3, mb: 8, textAlign: 'center' }}>
                        <ButtonBase disableRipple component={Link} sx={{ position: 'absolute', ml:-8 }} >
                            <img width={'80px'} src={Logo} />
                        </ButtonBase>
                    </Grid>
                ) : (
                    <Grid item xs={12} sx={{ ml: 3, mt: 3 }}>
                        <ButtonBase disableRipple component={Link} sx={{ position: 'absolute', mt: -5 }} >
                            <img width={'100px'} src={Logo} />
                        </ButtonBase>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{ minHeight: { xs: 'calc(100vh - 134px)', md: 'calc(100vh - 112px)' } }}
                    >
                        <Grid item>
                            <AuthCard>{children}</AuthCard>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                    <AuthFooter />
                </Grid>
            </Grid>
        </Box>
    );
}

AuthWrapper.propTypes = {
    children: PropTypes.node
};

export default AuthWrapper;
