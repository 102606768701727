import MainCard from '../../components/MainCard';
import MainLayout from '../../layout/MainLayout';
import TestForm from './forms/TestForm';

const NewTest = () => (
    <MainLayout>
        <MainCard title="Add Test">
            <TestForm />            
        </MainCard>
    </MainLayout>
);

export default NewTest;
