import { Box, Grid, Typography } from "@mui/material";
import MainCard from "../../components/MainCard";
import MainLayout from "../../layout/MainLayout";
import "./dashboard.css";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ListIcon from "@mui/icons-material/List";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import GroupsIcon from "@mui/icons-material/Groups";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const { role } = useSelector((state) => state.user);

  return (
    <MainLayout>
      <MainCard>
        <Grid container>
          <Grid
            item
            md={2}
            xs={6}
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              className="dmenu-item"
              sx={{ backgroundColor: "#182e61" }}
              component={Link}
              to={`/tests/new`}
            >
              <AddCircleIcon className="dmenu-item_icon" />
              <p className="dmenu-item-text">Create test</p>
            </Box>
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              className="dmenu-item"
              sx={{ backgroundColor: "#6e3710" }}
              component={Link}
              to={`/tests`}
            >
              <ListIcon className="dmenu-item_icon" />
              <p className="dmenu-item-text">Tests</p>
            </Box>
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              className="dmenu-item"
              sx={{ backgroundColor: "#186154" }}
              component={Link}
              to={`/questions/create`}
            >
              <ControlPointIcon className="dmenu-item_icon" />
              <p className="dmenu-item-text">Create Question</p>
            </Box>
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              className="dmenu-item"
              sx={{ backgroundColor: "#6e1210" }}
              component={Link}
              to={`/questions`}
            >
              <LibraryBooksIcon className="dmenu-item_icon" />
              <p className="dmenu-item-text">Question Bank</p>
            </Box>
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              className="dmenu-item"
              sx={{ backgroundColor: "#913920" }}
              component={Link}
              to={`/students/new`}
            >
              <PersonAddIcon className="dmenu-item_icon" />
              <p className="dmenu-item-text">Create Student</p>
            </Box>
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              className="dmenu-item"
              sx={{ backgroundColor: "#a31029" }}
              component={Link}
              to={`/students`}
            >
              <GroupsIcon className="dmenu-item_icon" />
              <p className="dmenu-item-text">Students</p>
            </Box>
          </Grid>
          {role < 30 && (
            <>
              <Grid
                item
                md={2}
                xs={6}
                sx={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  className="dmenu-item"
                  sx={{ backgroundColor: "#5c0a1a" }}
                  component={Link}
                  to={`/users/new`}
                >
                  <PersonAddIcon className="dmenu-item_icon" />
                  <p className="dmenu-item-text">Create User</p>
                </Box>
              </Grid>
              <Grid
                item
                md={2}
                xs={6}
                sx={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  className="dmenu-item"
                  sx={{ backgroundColor: "#330a5c" }}
                  component={Link}
                  to={`/users`}
                >
                  <GroupsIcon className="dmenu-item_icon" />
                  <p className="dmenu-item-text">Users</p>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </MainCard>
    </MainLayout>
  );
};

export default Dashboard;
