import axios from "axios";
import { env } from "../../../config";

export function createCourseRequest(request) {
  const { course, token } = request;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token
  }
  return new Promise((resolve, reject) => {
    axios.post(env.REACT_APP_API_HOST + '/api/access/courses/create', course, { headers: headers })
      .then(function (response) {
        if (response && response.data && response.data.success) {
          resolve({
            success: response.data.success,
          });
        } else {
          reject({
            status: 'failed',
            message: 'Something went wrong!'
          });
        }
      })
      .catch(function (error) {
        reject({
          status: 'failed',
          message: 'Something went wrong!'
        });
      });
  });
}

export function getCoursesRequest(request) { //course list
  const { token } = request;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token
  }
  return new Promise((resolve, reject) => {
    axios.get(env.REACT_APP_API_HOST + '/api/access/courses/list', { headers: headers })
      .then(function (response) {
        if (response && response.data && response.data.success && response.data.courses) {
          resolve({
            success: response.data.success,
            courses: response.data.courses
          });
        } else {
          reject({
            status: 'failed',
            message: 'Something went wrong!'
          });
        }
      })
      .catch(function (error) {
        reject({
          status: 'failed',
          message: 'Something went wrong!'
        });
      });
  });
}

export function courseItemUploadRequest(request) {
  const { token, formData } = request;
  const headers = {
    'Content-Type': 'multipart/form-data',
    'Authorization': token
  }
  return new Promise((resolve, reject) => {
    axios.post(env.REACT_APP_API_HOST + '/api/file/upload-courseitem', formData, { headers: headers })
      .then(function (response) {
        if (response && response.data && response.data.uploaded) {
          resolve({
            success: true,
            url: response.data.url
          });
        } else {
          resolve({
            success: false,
            message: 'Invalid document!'
          });
        }
      })
      .catch(function (error) {
        resolve({
          success: false,
          message: 'Something went wrong!'
        });
      });
  });
}

export function createCourseItemRequest(request) {
  const { token, input } = request;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token
  }
  return new Promise((resolve, reject) => {
    axios.post(env.REACT_APP_API_HOST + '/api/access/courses/create-courseitem', input, { headers: headers })
      .then(function (response) {
        if (response && response.data && response.data.success) {
          resolve({
            success: true,
          });
        } else {
          resolve({
            success: false,
            message: 'Something went wrong'
          });
        }
      })
      .catch(function (error) {
        resolve({
          success: false,
          message: 'Something went wrong!'
        });
      });
  });
}

export function updateCourseItemRequest(request) {
  const { token, input } = request;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token
  }
  return new Promise((resolve, reject) => {
    axios.post(env.REACT_APP_API_HOST + '/api/access/courses/update-courseitem', input, { headers: headers })
      .then(function (response) {
        if (response && response.data && response.data.success) {
          resolve({
            success: true,
          });
        } else {
          resolve({
            success: false,
            message: 'Something went wrong'
          });
        }
      })
      .catch(function (error) {
        resolve({
          success: false,
          message: 'Something went wrong!'
        });
      });
  });
}

export function courseItemPositionRequest(request) {
  const { token, input } = request;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token
  }
  return new Promise((resolve, reject) => {
    axios.post(env.REACT_APP_API_HOST + '/api/access/courses/update-courseitem-position', input, { headers: headers })
      .then(function (response) {
        if (response && response.data && response.data.success) {
          resolve({
            success: true,
          });
        } else {
          resolve({
            success: false,
            message: 'Something went wrong'
          });
        }
      })
      .catch(function (error) {
        resolve({
          success: false,
          message: 'Something went wrong!'
        });
      });
  });
}


export function getCourseItemsRequest(request) { //course list
  const { token, courseId, cateId } = request;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token
  }
  return new Promise((resolve, reject) => {
    axios.post(env.REACT_APP_API_HOST + '/api/access/courses/get-courseitems', { courseId, cateId }, { headers: headers })
      .then(function (response) {
        if (response && response.data && response.data.success) {
          resolve({
            success: response.data.success,
            courseitems: response.data.courseitems
          });
        } else {
          reject({
            status: 'failed',
            message: 'Something went wrong!'
          });
        }
      })
      .catch(function (error) {
        reject({
          status: 'failed',
          message: 'Something went wrong!'
        });
      });
  });
}

export function getUnAssignedStudents(request) {
  const { token, uuid } = request;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token
  }
  return new Promise((resolve, reject) => {
    axios.post(env.REACT_APP_API_HOST + '/api/access/courses/get-unassigned-students', { uuid }, { headers: headers })
      .then(function (response) {
        if (response && response.data && response.data.success) {
          resolve({
            success: response.data.success,
            students: response.data.students
          });
        } else {
          reject({
            status: 'failed',
            message: 'Something went wrong!'
          });
        }
      })
      .catch(function (error) {
        reject({
          status: 'failed',
          message: 'Something went wrong!'
        });
      });
  });
}


export function AssignCourseStudentsRequest(request) {
  const { token, uuid, students } = request;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token
  }
  return new Promise((resolve, reject) => {
    axios.post(env.REACT_APP_API_HOST + '/api/access/courses/assign-students', { uuid, students }, { headers: headers })
      .then(function (response) {
        if (response && response.data && response.data.success) {
          resolve({
            success: response.data.success,
          });
        } else {
          reject({
            status: 'failed',
            message: 'Something went wrong!'
          });
        }
      })
      .catch(function (error) {
        reject({
          status: 'failed',
          message: 'Something went wrong!'
        });
      });
  });
}

export function getCourseStudentsRequest(request) {
  const { token, uuid } = request;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token
  }
  return new Promise((resolve, reject) => {
    axios.post(env.REACT_APP_API_HOST + '/api/access/courses/assigned-students', { uuid }, { headers: headers })
      .then(function (response) {
        if (response && response.data && response.data.success) {
          resolve({
            success: response.data.success,
            assignedStudents: response.data.courseStudents
          });
        } else {
          reject({
            status: 'failed',
            message: 'Something went wrong!'
          });
        }
      })
      .catch(function (error) {
        reject({
          status: 'failed',
          message: 'Something went wrong!'
        });
      });
  });
}

export function removeCourseStudentsRequest(request) {
  const { token, uuid } = request;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token
  }
  return new Promise((resolve, reject) => {
    axios.post(env.REACT_APP_API_HOST + '/api/access/courses/remove-student', { uuid }, { headers: headers })
      .then(function (response) {
        if (response && response.data && response.data.success) {
          resolve({
            success: response.data.success
          });
        } else {
          reject({
            status: 'failed',
            message: 'Something went wrong!'
          });
        }
      })
      .catch(function (error) {
        reject({
          status: 'failed',
          message: 'Something went wrong!'
        });
      });
  });
}

export function getStudentCourses(request) {
  const { token } = request;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token
  }
  return new Promise((resolve, reject) => {
    axios.get(env.REACT_APP_API_HOST + '/api/access/courses/student-get-courses', { headers: headers })
      .then(function (response) {
        if (response && response.data && response.data.success) {
          resolve({
            success: response.data.success,
            courses: response.data.courses
          });
        } else {
          reject({
            status: 'failed',
            message: 'Something went wrong!'
          });
        }
      })
      .catch(function (error) {
        reject({
          status: 'failed',
          message: 'Something went wrong!'
        });
      });
  });
}

export function getStudentCourse(request) {
  const { token, uuid } = request;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token
  }
  return new Promise((resolve, reject) => {
    axios.post(env.REACT_APP_API_HOST + '/api/access/courses/student-get-course', { uuid }, { headers: headers })
      .then(function (response) {
        if (response && response.data && response.data.success) {
          resolve({
            success: response.data.success,
            course: response.data.course,
            courseStudent: response.data.courseStudent,
            courseItems: response.data.courseItems
          });
        } else {
          reject({
            status: 'failed',
            message: 'Something went wrong!'
          });
        }
      })
      .catch(function (error) {
        reject({
          status: 'failed',
          message: 'Something went wrong!'
        });
      });
  });
}

export function updateStudentProgress(request) {
  const { token, uuid, progress, progress_json } = request;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token
  }
  return new Promise((resolve, reject) => {
    axios.post(env.REACT_APP_API_HOST + '/api/access/courses/student-update-course-progress', { uuid, progress, progress_json }, { headers: headers })
      .then(function (response) {
        if (response && response.data && response.data.success) {
          resolve({
            success: response.data.success,
          });
        } else {
          reject({
            status: 'failed',
            message: 'Something went wrong!'
          });
        }
      })
      .catch(function (error) {
        reject({
          status: 'failed',
          message: 'Something went wrong!'
        });
      });
  });
}


export function getCourseItemCategoriesRequest(request) {
  const { token, uuid } = request;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token
  }
  return new Promise((resolve, reject) => {
    axios.post(env.REACT_APP_API_HOST + '/api/access/courses/get-courseitem-categories', { uuid }, { headers: headers })
      .then(function (response) {
        if (response && response.data && response.data.success) {
          resolve({
            success: response.data.success,
            categories: response.data.categories
          });
        } else {
          reject({
            status: 'failed',
            message: 'Something went wrong!'
          });
        }
      })
      .catch(function (error) {
        reject({
          status: 'failed',
          message: 'Something went wrong!'
        });
      });
  });
}

export function getCourseRequest(request) {
  const { token, uuid } = request;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token
  }
  return new Promise((resolve, reject) => {
    axios.post(env.REACT_APP_API_HOST + '/api/access/courses/get', { uuid }, { headers: headers })
      .then(function (response) {
        if (response && response.data && response.data.success) {
          resolve({
            success: response.data.success,
            course: response.data.course
          });
        } else {
          reject({
            status: 'failed',
            message: 'Something went wrong!'
          });
        }
      })
      .catch(function (error) {
        reject({
          status: 'failed',
          message: 'Something went wrong!'
        });
      });
  });
}

export function updateCourseRequest(request) {
  const { token, uuid, name } = request;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token
  }
  return new Promise((resolve, reject) => {
    axios.post(env.REACT_APP_API_HOST + '/api/access/courses/update', { uuid, name }, { headers: headers })
      .then(function (response) {
        if (response && response.data && response.data.success) {
          resolve({
            success: response.data.success,
          });
        } else {
          reject({
            status: 'failed',
            message: 'Something went wrong!'
          });
        }
      })
      .catch(function (error) {
        reject({
          status: 'failed',
          message: 'Something went wrong!'
        });
      });
  });
}

export function libraryVideoUploadRequest(request) {
  const { token, formData } = request;
  const headers = {
    'Content-Type': 'multipart/form-data',
    'Authorization': token
  }
  return new Promise((resolve, reject) => {
    // axios.post(env.REACT_APP_API_HOST + '/api/file/upload-library-video', formData, { headers: headers })
    //   .then(function (response) {
    //     if (response && response.data && response.data.uploaded) {
    //       resolve({
    //         success: true,
    //         url: response.data.url
    //       });
    //     } else {
    //       resolve({
    //         success: false,
    //         message: 'Invalid document!'
    //       });
    //     }
    //   })
    //   .catch(function (error) {
    //     resolve({
    //       success: false,
    //       message: 'Something went wrong!'
    //     });
    //   });
  });
}