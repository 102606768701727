import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Box, LinearProgress, Alert, FormControl, InputLabel, Typography } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as yup from 'yup';
import "./items.css";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { env } from '../../../../../config';
import { createCourseItemRequest, updateCourseItemRequest } from '../../../../../redux/sagas/requests/course';

export default function TextItem({ open, handleClose, type, onSuccess, updateItem }) {    
    const { token } = useSelector((state) => state.user);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { courseId, cateId } = useParams();

    function initState() {        
        setLoading(false);
        setErrorMessage("");
        formik.resetForm();
    }

    const handleCloseThis = () => {
        initState();
        handleClose();           
    }


    const formik = useFormik({
        initialValues: {
            title: updateItem?.name ?? "",
            description: updateItem?.text ?? ""
        },
        validationSchema: yup.object({
            title: yup.string().required('Title is required'),
            description: yup.string('Enter the description').required('Description is required'),
        }),
        onSubmit: async (values) => {          
            if(updateItem){ //update item                
                await updateCourseItemRequest({
                    token,
                    input: {
                        uuid: updateItem.uuid,
                        name: values.title,                        
                        text: values.description,                        
                    }
                });
            }else{
                await createCourseItemRequest({
                    token,
                    input: {
                        name: values.title,
                        course_id: courseId,
                        text: values.description,
                        type: type,
                        categoryUuid: cateId
                    }
                });
            }
            initState();
            onSuccess("Text content has successfully created");
        }
    });

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth="xs"
            onClose={handleCloseThis}
        >
            <DialogTitle sx={{ fontSize: "17px", fontWeight: "bold" }}>
                Text Content
                <IconButton
                    aria-label="close"
                    onClick={handleCloseThis}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                {errorMessage !== "" && <Alert severity='error'>{errorMessage}</Alert>}
                <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label="Content title"
                    type="text"
                    fullWidth
                    variant="standard"
                    placeholder='Introduction to trigonometry'
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                />
                <FormControl sx={{ width: '100%', mt: 4 }} size="small" >
                    <InputLabel sx={{ position: 'absolute', mt: '-30px', ml: '-13px' }} >Description</InputLabel>
                    <Typography sx={{ color: 'red' }}>{formik.errors.description}</Typography>
                    <CKEditor
                        editor={ClassicEditor}
                        data={formik?.values?.description ? formik?.values?.description : ""}
                        config={{
                            ckfinder: {
                                uploadUrl: env.REACT_APP_API_HOST + '/api/file/uploads'
                            }
                        }}
                        onReady={editor => {
                            // You can store the "editor" and use when it is needed.                                    
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            formik.setFieldValue('description', data);
                        }}
                        onBlur={(event, editor) => {
                        }}
                        onFocus={(event, editor) => {
                        }}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseThis} >Cancel</Button>
                <Button variant="contained" onClick={formik.handleSubmit} >Save</Button>
            </DialogActions>
        </Dialog>
    )
}
