import { Alert, Autocomplete, Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Modal, Paper, Select, TextField, Typography } from "@mui/material";
import { Formik, useFormik } from "formik";
import { useEffect, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MainLayout from "../../../layout/MainLayout";
import MainCard from "../../../components/MainCard";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createQuestion, getQuestion, setQuestion, updateQuestion } from "../../../redux/reducers/test";
import CheckIcon from '@mui/icons-material/Check';
import { env } from "../../../config";
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import * as yup from 'yup';
import { getAllCategories } from "../../../redux/reducers/questions";


const BeforeCheck = () => {
    return (
        <Box sx={{ pr: 1 }}>
            <CheckIcon />
        </Box>
    );
}

const OptionTemplate = ({ id, text, isCorrect, handleRemoveOption, handleOptionTextChange, handleOptionCheckbox }) => {
    const removeOption = () => {
        handleRemoveOption(id);
    }
    const handleTextChange = (e) => {
        handleOptionTextChange(e, id);
    }
    const handleStatusChange = (e) => {
        handleOptionCheckbox(e.target.checked, id);
    }
    return (
        <Grid container component={Paper} sx={{ p: 2, mb: 2 }} >
            <Grid item xs={12} md={8} >
                <CKEditor
                    editor={ClassicEditor}
                    data={`${text}`}
                    config={{
                        ckfinder: {
                            uploadUrl: env.REACT_APP_API_HOST + '/api/file/uploads'
                        }
                    }}
                    onReady={editor => {
                        // You can store the "editor" and use when it is needed.                                    
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        handleTextChange(data);                        
                    }}
                    onBlur={(event, editor) => {
                    }}
                    onFocus={(event, editor) => {
                    }}
                />
            </Grid>
            <Grid item xs={12} md={3} sx={{ pl: 2 }} >
                <FormGroup>
                    <FormControlLabel
                        name="auto_score"
                        id="input-auto_score"
                        control={<Checkbox
                            defaultChecked={isCorrect === 1}
                            onChange={handleStatusChange}
                        />} label="Correct" />
                </FormGroup>
            </Grid >
            <Grid item xs={12} md={1} sx={{ pl: 1 }} >
                <IconButton onClick={removeOption} aria-label="delete"><DeleteForeverIcon /></IconButton>
            </Grid>
        </Grid>);
}

const CreateQuestion = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { questionId } = useParams();
    const { question } = useSelector((state) => state.test)
    const { allCategories } = useSelector((state) => state.questions)
    const [showOptions, setShowOptions] = useState(true);
    const [options, setOptions] = useState([]);
    const [optionsIndex, setOptionsIndex] = useState(1);
    const [optionsError, setOptionsError] = useState(null);

    useEffect(() => {
        dispatch(getAllCategories());
    }, []);

    useEffect(() => {
        if (questionId) {
            dispatch(getQuestion(questionId));
        } else {
            dispatch(setQuestion(null));
            setOptions([]);
            setOptionsIndex(1);
            setOptionsError(null);
            formik.resetForm();
        }
    }, [questionId]);

    useEffect(() => {
        if (question && questionId) {            
            formik.setFieldValue('questionType', question.type);
            formik.setFieldValue('difficulty', question.difficulty);
            formik.setFieldValue('score', question.score);            
            formik.setFieldValue('description', question?.description?question?.description:'');
            formik.setFieldValue('explanation', (question.explanation?question.explanation:''));
            if(question?.categories){
                formik.setFieldValue('categories', question.categories);
            }
            if (question.type === 'objective' && question.options) {
                let optionsArray = [];
                question?.options.map((option, index) => {
                    optionsArray.push({
                        id: option.uuid,
                        text: option.text,
                        correct: option.is_correct
                    });
                });
                setOptions(optionsArray);
                setOptionsIndex(question.options.length + 1)
                setShowOptions(true);
            } else {
                setShowOptions(false);
                setOptions([]);
                setOptionsIndex(1);
            }
        }
    }, [question]);

    const handleAddOption = () => {
        let newOption = {
            id: optionsIndex,
            text: `<p>Option ${optionsIndex}</p>`,
            correct: false
        }
        setOptionsIndex(optionsIndex + 1);
        let newOptions = options ? options : [];
        newOptions.push(newOption);
        setOptions(newOptions);
    }

    const handleRemoveOption = (id) => {
        let newOptions = [];
        for (const index in options) {
            if (options[index].id !== id) {
                newOptions.push(options[index]);
            }
        }
        setOptions(newOptions);
    }

    const handleOptionTextChange = (e, id) => {
        let newOptions = [];
        for (const index in options) {
            if (options[index].id === id) {
                let update = { ...options[index], text: e };
                newOptions.push(update);
            } else {
                newOptions.push(options[index]);
            }
        }
        setOptions(newOptions);
    }

    const handleOptionCheckbox = (e, id) => {
        let newOptions = [];
        for (const index in options) {
            if (options[index].id === id) {
                let update = { ...options[index], correct: e };
                newOptions.push(update);
            } else {
                newOptions.push(options[index]);
            }
        }
        setOptions(newOptions);
    }

    const handleTypeChange = (e) => {
        if (e.target.value === "objective") {
            setShowOptions(true);
        } else {
            setShowOptions(false);
            setOptions([]);
            setOptionsIndex(1);
        }
    }

    let initValues = {        
        questionType: 'objective',
        difficulty: 'easy',
        score: 1,
        description: '',
        categories: [],
        explanation: ''
    }


    const validationSchema = yup.object({        
        questionType: yup
            .string('Select the type')
            .required('Type is required'),
        difficulty: yup
            .string('Select the Difficulty level')
            .required('Difficulty level is required'),
        score: yup
            .string('Select the Score')
            .required('Score is required'),
        description: yup
            .string('Enter the description'),
        
    });

    const formik = useFormik({
        initialValues: initValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {            
            let inputValues = { ...values };
            inputValues.categories = values.categories.map(category => category.uuid);                                                
            setOptionsError(null);            
            if (showOptions) {
                if (options && options.length >= 2) {
                    inputValues.options = options;
                    if (questionId) {
                        inputValues.uuid = questionId;
                        dispatch(updateQuestion(inputValues, navigate));
                    } else {                        
                        dispatch(createQuestion(inputValues, navigate));
                    }
                } else { //show alert
                    setOptionsError("Please make sure to add at least two option to this question as it is an objective question and requires multiple options for the user to choose from.");
                }
            } else {
                if (questionId) {
                    inputValues.uuid = questionId;
                    dispatch(updateQuestion(inputValues, navigate));
                } else {
                    dispatch(createQuestion(inputValues, navigate));
                }
            }
        },
    });

    let OptionsDOM = [];
    for (const index in options) {
        OptionsDOM.push(<OptionTemplate
            key={index}
            id={options[index].id}
            text={options[index].text}
            isCorrect={options[index].correct}
            handleRemoveOption={handleRemoveOption}
            handleOptionTextChange={handleOptionTextChange}
            handleOptionCheckbox={handleOptionCheckbox}
        />);
    }
    
    return (
        <MainLayout>
            <MainCard title={questionId ? 'Update Question' : 'Add Question'}>
                <form onSubmit={formik.handleSubmit} >
                    <Grid
                        container
                        justifyContent="left"
                        sx={{ height: "100%" }}
                    >
                        <Grid item xl md xs={12} sx={{ p: 2 }} >                            
                            <Grid item className="ckeditor-question" xl={12} xs={12} sm={12} md={12} sx={{ pt: 5 }} >
                                <FormControl sx={{ width: '100%' }} size="small" >
                                    <InputLabel sx={{ position: 'absolute', mt:'-30px', ml: '-13px', fontWeight: 'bold' }} >Question</InputLabel>
                                    <Typography sx={{ color: 'red' }}>{formik.errors.description}</Typography>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data= { formik?.values?.description?formik?.values?.description:"" }
                                        config={{
                                            ckfinder: {
                                                uploadUrl: env.REACT_APP_API_HOST + '/api/file/uploads'
                                            }
                                        }}
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.                                    
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            formik.setFieldValue('description', data);
                                        }}
                                        onBlur={(event, editor) => {
                                        }}
                                        onFocus={(event, editor) => {
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xl={12} xs={12} sm={12} md={12} sx={{ pt: 2 }} >
                                <TextField
                                    name="score"
                                    fullWidth={true}
                                    id="input-score"
                                    label="Score"
                                    variant="standard"
                                    placeholder="Score"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={formik.values.score}
                                    onChange={formik.handleChange}
                                    error={formik.touched.score && Boolean(formik.errors.score)}
                                    helperText={formik.touched.score && formik.errors.score}
                                />
                            </Grid>
                            {(allCategories && allCategories.length > 0) &&
                            <Grid item xl={12} xs={12} sm={12} md={12} sx={{ pt: 2 }} >                                
                                <Autocomplete
                                    multiple
                                    name="categories"
                                    id="categories"
                                    options={allCategories}
                                    getOptionLabel={(option) => option.name}                                    
                                    value={formik.values.categories}
                                    onChange={(event, value) => formik.setFieldValue('categories', value)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Categories"                                            
                                        />
                                    )}
                                />
                            </Grid>}
                            <Grid item xl={12} xs={12} sm={12} md={12} sx={{ pt: 2 }} >
                                <FormControl sx={{ width: '100%', mt: 3 }} size="small"
                                >
                                    <InputLabel id="input-question-difficulty-label">Difficulty level</InputLabel>
                                    <Select
                                        labelId="input-question-difficulty-label"
                                        id="input-question-difficulty"
                                        label="Difficulty level"
                                        name="difficulty"
                                        value={formik.values.difficulty}
                                        onChange={formik.handleChange}
                                    >
                                        <MenuItem value="easy">Easy</MenuItem>
                                        <MenuItem value="moderate">Moderate</MenuItem>
                                        <MenuItem value="difficult">Difficult</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xl={12} xs={12} sm={12} md={12} sx={{ pt: 2 }} >
                                <FormControl sx={{ width: '100%', mt: 3 }} size="small"
                                >
                                    <InputLabel id="input-question-type-label">Question type</InputLabel>
                                    <Select
                                        labelId="input-question-type-label"
                                        id="input-question-type"
                                        label="Question type"
                                        name="questionType"
                                        value={formik.values.questionType}
                                        onChange={e => { formik.handleChange(e); handleTypeChange(e) }}
                                    >
                                        <MenuItem value="objective">Objective</MenuItem>
                                        <MenuItem value="subjective">Subjective</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            
                            <Grid item sx={{ mt: 5 }} xs={12} >
                                <Divider />
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-start"
                                maxWidth={true}
                                sx={{ pt: 4 }} >
                                <Grid item xs={12} md="auto" >
                                    <Button className="button-responsive-auto" component={Link} to={`/questions`} sx={{ mr: 2, mb: 2 }} variant="outlined">Cancel</Button>
                                </Grid>
                                <Grid item xs={12} md="auto" >
                                    <Button type="submit" className="button-responsive-auto" variant="contained" color="success" >{questionId ? 'Update' : "Create"}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider sx={{ display: { xs: 'none' } }} orientation="vertical" flexItem></Divider>
                        <Grid item xl md xs={12} sx={{ p: 2, display: { xs: 'none', md: 'block' } }} >
                            <Grid item className="ckeditor-question" xl={12} xs={12} sm={12} md={12} sx={{ pt: 5 }} >
                                <FormControl sx={{ width: '100%' }} size="small" >                                    
                                    <InputLabel sx={{ position: 'absolute', mt:'-30px', ml: '-13px', fontWeight: 'bold' }} >Question explanation</InputLabel>
                                    <Typography sx={{ color: 'red' }}>{formik.errors.explanation}</Typography>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data= { formik?.values?.explanation?formik?.values?.explanation:"" }
                                        config={{
                                            ckfinder: {
                                                uploadUrl: env.REACT_APP_API_HOST + '/api/file/uploads'
                                            }
                                        }}
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.                                    
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            formik.setFieldValue('explanation', data);
                                        }}
                                        onBlur={(event, editor) => {
                                        }}
                                        onFocus={(event, editor) => {
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            {
                                showOptions && (
                                    <>
                                        <Grid item sx={{ mt: 5, mb: 4 }} xs={12} >
                                            <Divider textAlign="left">Options</Divider>
                                        </Grid>
                                        {optionsError && (
                                            <Grid item sx={{ mt: 5, mb: 4 }} xs={12} >
                                                <Alert severity="error">{optionsError}</Alert>
                                            </Grid>
                                        )}
                                        {OptionsDOM.length > 0 ?
                                            OptionsDOM :
                                            (<Alert severity="info">No options have been added yet.</Alert>)
                                        }
                                        <Grid item sx={{ mt: 2 }} xs={12} >
                                            <Button onClick={handleAddOption} variant="contained"><AddIcon /> Add Option</Button>
                                        </Grid>
                                    </>
                                )
                            }
                            {/* <Alert severity="info">
                                <h3 sx={{ color: "#353535" }}>{questionId ? "Update" : "Create"} question</h3>
                                <Divider />
                                {
                                    questionId ?
                                        (<Typography sx={{ mt: 3 }}>To update the question, please fill in the following fields:</Typography>) :
                                        (<Typography sx={{ mt: 3 }}>To create a new question, please fill in the following fields:</Typography>)
                                }
                                <List>
                                    <ListItem disablePadding>
                                        <ListItemIcon>
                                            <BeforeCheck />
                                        </ListItemIcon>
                                        <ListItemText primary="Title: This is the heading or title of your question. Please make it clear and concise so that others can understand what you're asking." />
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemIcon>
                                            <BeforeCheck />
                                        </ListItemIcon>
                                        <ListItemText primary="Score: The score represents the level of difficulty of your question." />
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemIcon>
                                            <BeforeCheck />
                                        </ListItemIcon>
                                        <ListItemText primary="Difficulty: The difficulty of your question helps others to know what to expect. Please choose a difficulty level that accurately represents the level of expertise required to answer your question." />
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemIcon>
                                            <BeforeCheck />
                                        </ListItemIcon>
                                        <ListItemText primary="Type: This field allows you to specify the type of your question. There are two options available: subjective and objective. If you're asking a question that requires an explanation or opinion, choose 'subjective.' If you're asking a question that requires a specific answer, choose 'objective.'" />
                                    </ListItem>

                                    <ListItem disablePadding>
                                        <ListItemIcon>
                                            <BeforeCheck />
                                        </ListItemIcon>
                                        <ListItemText primary="Description: In this field, you can type your question and use advanced tools to add images and mathematical equations if needed. This field is where you can provide all the details and information related to your question." />
                                    </ListItem>
                                </List>
                            </Alert> */}
                        </Grid>
                    </Grid>
                </form>
            </MainCard>
        </MainLayout>
    );
}

export default CreateQuestion;