import axios from "axios";
import { env } from "../../../config";

export function createStudentRequest(request) {            
    const { user, token } = request;    
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/student/create', user, { headers: headers })
            .then(function (response) {
                if(response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                        result: response.data.result
                    });
                }else{
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });    
                }                
            })
            .catch(function (error) {
                reject({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function getStudentList(request) {    
    const { token } = request;    
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.get(env.REACT_APP_API_HOST + '/api/access/student/list', { headers: headers })
            .then(function (response) {
                if(response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                        result: response.data.result
                    });
                }else{
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });    
                }                
            })
            .catch(function (error) {
                reject({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function getStudent(request) {    
    const { token, userId } = request;        
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/student/get', { userId: userId }, { headers: headers })
            .then(function (response) {
                if(response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                        result: response.data.result
                    });
                }else{
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });    
                }                
            })
            .catch(function (error) {
                reject({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function updateStudent(request) {    
    const { token, user } = request;        
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/student/update', user, { headers: headers })
            .then(function (response) {
                if(response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                        result: response.data.result
                    });
                }else{
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });    
                }                
            })
            .catch(function (error) {
                reject({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function updateStudentPassword(request) {    
    const { token, user } = request;        
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/student/passwordupdate', { userId: user.userId, password: user.passwordUpdate }, { headers: headers })
            .then(function (response) {
                if(response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                        result: response.data.result
                    });
                }else{
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });    
                }                
            })
            .catch(function (error) {
                reject({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function deleteStudentRequest(request) {        
    const { userId, token } = request;    
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/student/delete', {userId:userId}, { headers: headers })
            .then(function (response) {
                if(response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message
                    });
                }else{
                    resolve({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });    
                }                
            })
            .catch(function (error) {
                resolve({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}