import React, { useEffect, useState } from 'react'
import MainLayout from '../../layout/MainLayout';
import MainCard from '../../components/MainCard';
import { Box, Button } from '@mui/material';
import { TableData } from './TableData';
import CreateCourse from './components/create';
import { getCoursesRequest } from '../../redux/sagas/requests/course';
import { useSelector } from 'react-redux';
import AlertMessages from '../../components/snackbar';
import UpdateCourse from './components/update';

export default function Courses() {
  const [openCreateCourse, setOpenCreateCourse] = useState(false);  
  const { token } = useSelector((state) => state.user);  
  const [courseList, setCourseList] = useState([]);
  const [ successMessage, setSuccessMessage ] = useState("");
  const [ errorMessage, setErrorMessage ] = useState("");
  const [openUpdateCourse, setOpenUpdateCourse] = useState("");  

  useEffect(() => {
    async function getCourses(){
      const courses = await getCoursesRequest({token});
      if(courses.success === true){
        setCourseList(courses.courses);
      } else {
        setErrorMessage("Something went wrong");
      }
    }  
    getCourses(); // Call the function here  
  }, [token]);

  async function getCourses(){
    const courses = await getCoursesRequest({token});
    if(courses.success === true){
      setCourseList(courses.courses);
    } else {
      setErrorMessage("Something went wrong");
    }
  } 


  function handleOpenCreateCourse(){
    setOpenCreateCourse(true);
  }
  function handleCloseCreateCourse(){
    setOpenCreateCourse(false);
  }
  function createCallback() {
    setOpenCreateCourse(false);
    setOpenUpdateCourse("");
    getCourses();
  }
  function clearMessages(){
    setErrorMessage("");
    setSuccessMessage("");
  }

  function handleCloseUpdateCourse(){
    setOpenUpdateCourse("");
  }

  const handleUpdateCourse = (uuid) => {    
    setOpenUpdateCourse(uuid);
  }

  return (
    <MainLayout>
      <MainCard title="Courses">  
        <AlertMessages success={successMessage} error={errorMessage} clear={clearMessages} />        
        <CreateCourse open={openCreateCourse} handleClose={handleCloseCreateCourse} callback={createCallback} />
        <UpdateCourse uuid={openUpdateCourse} open={Boolean(openUpdateCourse !== "")} handleClose={handleCloseUpdateCourse} callback={createCallback} />
        <Box sx={{ m: 1, mb: 4 }} display="flex" justifyContent="flex-end">
          <Button onClick={handleOpenCreateCourse} variant="contained" size="small">Create Course</Button>
        </Box>
        <TableData list={courseList} onEdit={handleUpdateCourse} />
      </MainCard>
    </MainLayout>
  )
}
