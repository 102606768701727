import { applyMiddleware, combineReducers, createStore } from "redux";
import userReducer from "./reducers/user";
import createSagaMiddleware from "redux-saga";
import { watcherSaga } from "./sagas/rootSaga";
import utilReducer from "./reducers/util";
import studentReducer from "./reducers/student";
import testReducer from "./reducers/test";
import studentTest from "./reducers/studentTest";
import category from "./reducers/category";
import questions from "./reducers/questions";
import sectionReducer from "./reducers/section";

const reducer = combineReducers({  
  user: userReducer,
  util: utilReducer,
  student: studentReducer,
  test: testReducer,
  studentTest: studentTest,
  category: category,
  questions: questions,
  section: sectionReducer
});

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

const store = createStore(reducer, {}, applyMiddleware(...middleware));

sagaMiddleware.run(watcherSaga);

export default store;
