const CryptoJS = require("crypto-js");

exports.decryptData = (responseData) => {
    const encryptedData = responseData.data;
    const iv = CryptoJS.enc.Hex.parse(responseData.iv);
    const secretKey = CryptoJS.enc.Hex.parse(responseData.key);
    const decryptedData = CryptoJS.AES.decrypt(
        { ciphertext: CryptoJS.enc.Base64.parse(encryptedData) },
        secretKey,
        { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    ).toString(CryptoJS.enc.Utf8);
    const jsonData = JSON.parse(decryptedData);
    return jsonData;
}