
const pages = {
    id: 'authentication',
    title: 'Authentication',
    type: 'group',
    children: [       
        {
            id: 'Notification',
            title: 'Notification',
            type: 'item',
            url: '/login',
            target: true
        }, 
        {
            id: 'Packages',
            title: 'Packages',
            type: 'item',
            url: '/packages',
            target: false
        }, 
        {
            id: 'Courses',
            title: 'Courses',
            type: 'item',
            url: '/courses',
            target: false
        }, 
        {
            id: 'Library',
            title: 'Library',
            type: 'item',
            url: '/library',
            target: false
        }, 
        {
            id: 'Categories',
            title: 'Categories',
            type: 'item',
            url: '/categories',
            target: false
        }, 
        {
            id: 'questions',
            title: 'Question Bank',
            type: 'item',
            url: '/questions',
            target: false
        },
        {
            id: 'tests',
            title: 'Test',
            type: 'item',
            url: '/tests',
            target: false
        },
        {
            id: 'students',
            title: 'Students',
            type: 'item',
            url: '/students',
            target: false
        },
        {
            id: 'users',
            title: 'Users',
            type: 'item',
            url: '/users',
            target: false
        },
        {
            id: 'Results',
            title: 'Results',
            type: 'item',
            url: '/results',            
            target: false
        },
        {
            id: 'Logout',
            title: 'Logout',
            type: 'item',
            url: '/logout',
            target: false
        },
        {
            id: 'new-user',
            title: 'New',
            type: 'item',
            parentLinks: [
                {
                    id: 'users',
                    url: '/users',
                    title: "Users"
                }
            ],
            visibility: 'Hidden',
            url: '/users/new',
            target: false
        },
        {
            id: 'view-user',
            title: 'View',
            type: 'item',
            parentLinks: [
                {
                    id: 'users',
                    url: '/users',
                    title: "Users"
                }
            ],
            visibility: 'Hidden',
            url: '/users/view/*',
            target: false
        },
        {
            id: 'new-student',
            title: 'New',
            type: 'item',
            parentLinks: [
                {
                    id: 'students',
                    url: '/students',
                    title: "Students"
                }
            ],
            visibility: 'Hidden',
            url: '/students/new',
            target: false
        },
        {
            id: 'view-student',
            title: 'View',
            type: 'item',
            parentLinks: [
                {
                    id: 'students',
                    url: '/students',
                    title: "Students"
                }
            ],
            visibility: 'Hidden',
            url: '/students/view/*',
            target: false
        },
        {
            id: 'edit-student',
            title: 'Edit',
            type: 'item',
            parentLinks: [
                {
                    id: 'students',
                    url: '/students',
                    title: "Students"
                }
            ],
            visibility: 'Hidden',
            url: '/students/edit/*',
            target: false
        },
        {
            id: 'new-test',
            title: 'Test',
            type: 'item',
            parentLinks: [
                {
                    id: 'tests',
                    url: '/tests',
                    title: "Tests"
                }
            ],
            visibility: 'Hidden',
            url: '/tests/new',
            target: false
        },
        {
            id: 'view-test',
            title: 'View',
            type: 'item',
            parentLinks: [
                {
                    id: 'tests',
                    url: '/tests',
                    title: "Tests"
                },
                {
                    id: 'parent-test',
                    url: '/tests/{1}/view',
                    title: "View"
                }
            ],
            visibility: 'Hidden',
            url: '/tests/view/*',
            target: false
        },
        {
            id: 'view-test-add-question',
            title: 'Add Question',
            type: 'item',
            parentLinks: [
                {
                    id: 'tests',
                    url: '/tests',
                    title: "Tests"
                },
                {
                    id: 'parent-test',
                    url: '/tests/{1}/view',
                    title: "View"
                }
            ],
            visibility: 'Hidden',
            url: '/tests/view/*/add-question',
            target: false
        },
        {
            id: 'view-test-edit-question',
            title: 'Update Question',
            type: 'item',
            parentLinks: [
                {
                    id: 'tests',
                    url: '/tests',
                    title: "Tests"
                },
                {
                    id: 'parent-test',
                    url: '/tests/{1}/view',
                    title: "View"
                }
            ],
            visibility: 'Hidden',
            url: '/tests/view/*/edit-question/*',
            target: false
        }, 
        {
            id: 'categories-child',
            title: 'Categories',
            type: 'item',
            url: '/categories/*',
            target: false,
            visibility: 'Hidden',
        },        
        {
            id: 'create-question',
            title: 'Add Question',
            type: 'item',
            parentLinks: [
                {
                    id: 'questions-create-question',
                    url: '/questions',
                    title: "Question Bank"
                }
            ],
            visibility: 'Hidden',
            url: '/questions/create',
            target: false
        },        
        {
            id: 'view-question',
            title: 'View',
            type: 'item',
            parentLinks: [
                {
                    id: 'questions-create-question',
                    url: '/questions',
                    title: "Question Bank"
                }
            ],
            visibility: 'Hidden',
            url: '/questions/view/*',
            target: false
        },        
        {
            id: 'edit-question',
            title: 'Edit',
            type: 'item',
            parentLinks: [
                {
                    id: 'questions-create-question',
                    url: '/questions',
                    title: "Question Bank"
                }
            ],
            visibility: 'Hidden',
            url: '/questions/edit/*',
            target: false
        },
        {
            id: 'view-test-section-question',
            title: 'Section Question',
            type: 'item',
            parentLinks: [
                {
                    id: 'tests',
                    url: '/tests',
                    title: "Tests"
                },
                {
                    id: 'parent-test',
                    url: '/tests/{1}/view',
                    title: "View"
                }
            ],
            visibility: 'Hidden',
            url: '/tests/view/*/*/questions',
            target: false
        },
        {
            id: 'view-courses',
            title: 'View',
            type: 'item',
            parentLinks: [
                {
                    id: 'Courses',                                        
                    url: '/courses',
                    title: 'Courses'
                }
            ],
            visibility: 'Hidden',
            url: '/courses/view/*',
            target: false
        },
    ]
};

export default pages;
