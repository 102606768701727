import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import MainCard from "../../components/MainCard";

import { useSelector } from "react-redux";
import {
  Alert,
  Box,
  Button,
  Dialog,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import CreatePackage from "./createPackage";
import axios from "axios";
import { env } from "../../config";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteDialog from "../../components/dialogs/DeleteDialog";

export default function Packages() {
  const [openCreateDialog, setCreateDialog] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [deleteSelected, setDeleteSelected] = useState("");
  const [editPackage, setEditPackage] = useState(null);
  const { token } = useSelector((state) => state.user);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const filteredPackages = packages.filter((pack) =>
    pack.package_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const headers = {
    "Content-Type": "application/json",
    Authorization: token,
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  useEffect(() => {
    let timer;

    if (successMessage !== "") {
      timer = setTimeout(() => {
        setSuccessMessage("");
      }, 6000);
    }

    return () => clearTimeout(timer);
  }, [successMessage]);

  useEffect(() => {
    let timer;

    if (errorMessage !== "") {
      timer = setTimeout(() => {
        setErrorMessage("");
      }, 6000);
    }

    return () => clearTimeout(timer);
  }, [errorMessage]);

  const fetchPackages = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        env.REACT_APP_API_HOST + "/api/access/packages/list",
        {
          headers: headers,
        }
      );
      if (data) {
        setPackages(data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeletePackage = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        env.REACT_APP_API_HOST + "/api/access/packages/remove",
        {
          uuid: deleteSelected,
        },
        {
          headers: headers,
        }
      );
      if (data) {
        setSuccessMessage("Package has been successfully deleted.");
      }
    } catch (err) {
      setErrorMessage("Something went wrong.");
      console.error(err);
    } finally {
      setLoading(false);
      setDeleteSelected("");
      fetchPackages();
    }
  };

  const handleDialogClose = () => {
    setEditPackage(null);
    setCreateDialog(false);
  };

  const handleDialogOpen = () => {
    setCreateDialog(true);
  };

  const onCreateSuccess = (msg) => {
    setCreateDialog(false);
    setSuccessMessage(msg);
    setEditPackage(null);
    fetchPackages();
  };

  const OnCreateFailed = (msg) => {
    setCreateDialog(false);
    setErrorMessage(msg);
  };

  return (
    <MainLayout>
      <MainCard title="Packages">
        {successMessage !== "" && (
          <Alert severity="success">{successMessage}</Alert>
        )}
        {errorMessage !== "" && <Alert severity="error">{errorMessage}</Alert>}
        <Box sx={{ m: 1, mb: 4 }} display="flex" justifyContent="flex-end">
          <Button onClick={handleDialogOpen} variant="contained" size="small">
            Create Package
          </Button>
        </Box>
        <CreatePackage
          open={openCreateDialog}
          editPackage={editPackage}
          onClose={handleDialogClose}
          onSuccess={onCreateSuccess}
          onError={OnCreateFailed}
        />
        <TextField
          label="Search Packages"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          style={{ marginBottom: "16px" }}
          autoComplete="off"
        />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Duration (in days)</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={4}>
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : filteredPackages.length > 0 ? (
              <TableBody>
                {filteredPackages
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((pack, index) => (
                    <TableRow key={index}>
                      <TableCell>{pack.package_name}</TableCell>
                      <TableCell>{pack.duration} Days</TableCell>
                      <TableCell>₹{pack.amount}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            setEditPackage(pack);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setDeleteSelected(pack.uuid);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={4}>
                    <Alert severity="info">No packages found.</Alert>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={packages.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
        <DeleteDialog
          open={deleteSelected !== ""}
          handleClose={() => {
            setDeleteSelected("");
          }}
          handleDeleteAction={handleDeletePackage}
          message="Are you sure you want to delete this package? This action cannot be undone."
          title="Confirm Package Deletion"
        />
      </MainCard>
    </MainLayout>
  );
}
