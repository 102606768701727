import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { TextField, Checkbox, FormControlLabel } from "@mui/material";
import axios from "axios";
import { env } from "../../config";
import { useSelector } from "react-redux";

const CreatePackage = ({ open, onClose, onSuccess, onError, editPackage }) => {
  const { token } = useSelector((state) => state.user);
  const headers = {
    "Content-Type": "application/json",
    Authorization: token,
  };

  useEffect(() => {
    if (editPackage) {
      if (editPackage?.package_name) {
        formik.setFieldValue("packageName", editPackage?.package_name);
      }
      if (editPackage?.duration) {
        formik.setFieldValue("duration", editPackage?.duration);
      }
      if (editPackage?.amount) {
        formik.setFieldValue("amount", editPackage?.amount);
      }
      if (editPackage?.isRecurring) {
        formik.setFieldValue("amount", editPackage?.is_recurring);
      }
    } else {
      formik.resetForm();
    }
  }, [editPackage]);

  const validationSchema = yup.object({
    packageName: yup
      .string("Enter the package name")
      .required("Package name is required"),
    duration: yup
      .number("Enter the duration in days")
      .required("Duration is required")
      .positive()
      .integer(),
    amount: yup
      .number("Enter the amount")
      .required("Amount is required")
      .positive()
      .integer(),
    isRecurring: yup.boolean(),
  });
  console.log("editPackage", editPackage);
  const formik = useFormik({
    initialValues: {
      packageName: "",
      duration: "",
      isRecurring: false,
      amount: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      axios
        .post(
          env.REACT_APP_API_HOST +
            `/api/access/packages/${
              editPackage ? `update/${editPackage?.uuid}` : "create"
            }`,
          values,
          {
            headers: headers,
          }
        )
        .then(function (response) {
          if (response && response.data && response.data.success) {
            onSuccess(
              `Package has been successfully ${
                editPackage ? "update" : "create"
              }.`
            );
            formik.resetForm();            
          } else {
            throw "Invalid response";
          }
        })
        .catch(function (error) {
          console.log("error", error);
          onError("Something went wrong!");
        });
    },
  });

  return (
    <Dialog
      open={open || editPackage !== null}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle sx={{ fontSize: "20px" }}>
        {editPackage ? "Update" : "Create"} Package
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ pt: 2 }}>
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              id="packageName"
              name="packageName"
              label="Package Name"
              value={formik.values.packageName}
              autoComplete="off"
              onChange={formik.handleChange}
              error={
                formik.touched.packageName && Boolean(formik.errors.packageName)
              }
              helperText={
                formik.touched.packageName && formik.errors.packageName
              }
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              id="duration"
              name="duration"
              label="Duration in Days"
              type="number"
              autoComplete="off"
              value={formik.values.duration}
              onChange={formik.handleChange}
              error={formik.touched.duration && Boolean(formik.errors.duration)}
              helperText={formik.touched.duration && formik.errors.duration}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              id="amount"
              name="amount"
              label="Amount"
              type="number"
              autoComplete="off"
              value={formik.values.amount}
              onChange={formik.handleChange}
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
            />
          </Grid>
          {/* <Grid item xs={12} md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="isRecurring"
                  name="isRecurring"
                  checked={formik.values.isRecurring}
                  onChange={formik.handleChange}
                />
              }
              label="Is Recurring"
            />
          </Grid> */}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button variant="outlined" onClick={formik.submitForm}>
          {editPackage ? "Update" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatePackage;
