import MainCard from '../../components/MainCard';
import MainLayout from '../../layout/MainLayout';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link, useNavigate, useParams } from 'react-router-dom';
import TableData from './TableData';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Chip, Divider, Grid, IconButton, Paper, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getQuestion } from '../../redux/reducers/test';
import "./QuestionView.css";
import { useState } from 'react';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import { deleteQuestion } from '../../redux/reducers/questions';

const QuestionView = () => {
    const dispatch = useDispatch();
    const { question } = useSelector((state) => state.test);
    const { questionId } = useParams();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);    
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getQuestion(questionId));
    }, [])

    if (!question) {
        return (
            <MainLayout>
                <MainCard>
                    ...
                </MainCard>
            </MainLayout>
        );
    }
    const handleDeleteDialogOpen = () => {
        setOpenDeleteDialog(true);
    }

    const handleDeleteDialogClose = () => {
        setOpenDeleteDialog(false);
    }

    const handleDeleteConfirm = () => {
        dispatch(deleteQuestion(question.uuid, navigate));        
    }

    return (
        <MainLayout>
            <MainCard>                
                <DeleteDialog 
                    open={openDeleteDialog} 
                    title="Are you sure you want to delete the question?"
                    message="This action cannot be undone. Deleting the question will remove it permanently from the system."
                    handleClose={handleDeleteDialogClose}
                    handleDeleteAction={handleDeleteConfirm}
                />
                <Grid container className="image-handle" >
                    <Grid item xs={12} md={12} >
                        <Box display="flex" justifyContent="flex-end" mt={2}>
                            <IconButton component={Link} to={ `/questions/edit/${question.uuid}` } ><EditIcon /></IconButton>
                            <IconButton onClick={handleDeleteDialogOpen} ><DeleteIcon /></IconButton>
                        </Box>
                    </Grid>                                        
                    <Grid item xs={12} md={12} sx={{ mt: 2 }} >
                        <Typography sx={{ color: '#969696' }} variant='p'>Description</Typography>
                        <div dangerouslySetInnerHTML={{ __html: question.description }} ></div>
                        <Divider sx={{ mt: 1 }} />
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ mt: 2 }} >
                        <Typography sx={{ color: '#969696' }} variant='p'>Explanation</Typography>
                        <div dangerouslySetInnerHTML={{ __html: question.explanation }} ></div>
                        <Divider sx={{ mt: 1 }} />
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ mt: 2 }} >
                        <Typography sx={{ color: '#969696' }} variant='p'>Type</Typography>
                        <div className="capitalize" >{question.type}</div>
                        <Divider sx={{ mt: 1 }} />
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ mt: 2 }} >
                        <Typography sx={{ color: '#969696' }} variant='p'>Score</Typography>
                        <div>{question.score}</div>
                        <Divider sx={{ mt: 1 }} />
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ mt: 2 }} >
                        <Typography sx={{ color: '#969696' }} variant='p'>Difficulty</Typography>
                        <div className='capitalize' >{question.difficulty}</div>
                        <Divider sx={{ mt: 1 }} />
                    </Grid>
                    {(question?.categories && question?.categories.length > 0) && (
                        <Grid item xs={12} md={12} sx={{ mt: 2 }} >
                            <Typography sx={{ color: '#969696' }} variant='p'>Categories</Typography>
                            <Box sx={{ mt:1 }} >
                            { question.categories.map((category) => (<Chip sx={{ mr:2, mb:1 }} key={category.uuid} label={ category.name } />))}  
                            </Box>                          
                            <Divider sx={{ mt: 1 }} />
                        </Grid>
                    )}
                    {question.type === "objective" &&
                        <Grid item xs={12} md={12} sx={{ mt: 2 }} >
                            <Typography sx={{ color: '#969696' }} variant='p'>Options</Typography>
                            { question?.options.map((option)=> {
                                return (
                                    <Paper key={option.uuid} sx={{ p: 1, borderLeft: option.is_correct?"5px solid green":"5px solid red", mt: 1 }} dangerouslySetInnerHTML={{ __html: option.text }} ></Paper>
                                );
                            })}                                                        
                        </Grid>
                    }
                </Grid>
            </MainCard>
        </MainLayout>
    );
};

export default QuestionView;
