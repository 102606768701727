import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import "./bulkImportQuestions.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useEffect } from "react";
import { convertDocxToQuestions } from "../../redux/sagas/requests/questions";
import { useDispatch, useSelector } from "react-redux";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { getAllCategories } from "../../redux/reducers/questions";
import Instructions from "./Instructions";

const STATUS_UPLOAD = "STATUS_UPLOAD";
const STATUS_REVIEW_QUESTIONS = "STATUS_REVIEW_QUESTIONS";
const STATUS_QUESTIONS_SAVED = "STATUS_QUESTIONS_SAVED";

const BulkImportQuestions = ({ handleClose, open, callback }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorHints, setErrorHints] = useState([]);
  const { token } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(STATUS_UPLOAD);
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const { allCategories } = useSelector((state) => state.questions);
  const dispatch = useDispatch();

  const initImportPage = () => {
    setErrorMessage("");
    setCurrentStatus(STATUS_UPLOAD);
    setQuestions([]);
    setSelectedQuestion(null);
    setErrorHints([]);
    setSelectedCategories([]);
  };

  useEffect(() => {
    dispatch(getAllCategories());
  }, []);

  useEffect(() => {
    initImportPage();
  }, [open]);

  const handleUploadAgain = () => {
    initImportPage();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleFileChange = (event) => {
    initImportPage();
    const selectedFile = event.target.files[0];
    if (
      selectedFile &&
      selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      uploadDocxFile(selectedFile);
    } else {
      setErrorMessage(
        "Only .docx files are allowed for uploads. Please make sure your file is in the correct format and try again."
      );
    }
    event.target.value = "";
  };

  const handleDrop = async (e) => {
    initImportPage();
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    const allowedExtensions = ["docx"];
    if (file && allowedExtensions.includes(file.name.split(".").pop())) {
      uploadDocxFile(file);
    } else {
      setErrorMessage(
        "Only .docx files are allowed for uploads. Please make sure your file is in the correct format and try again."
      );
    }
  };

  const uploadDocxFile = async (file) => {
    initImportPage();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    const res = await convertDocxToQuestions({
      token,
      formData,
    });
    setIsLoading(false);
    if (res?.status === "success" && res?.results?.length > 0) {
      let isValidQuestions = true;
      let hints = [];

      for (let i = 0; i < res.results.length; i++) {
        const qust = res.results[i];
        if (!qust?.question || qust.question === "") {
          isValidQuestions = false;
          hints.push(`Empty title found for question no .${i + 1}.`);
        }
        if (!qust?.options || qust.options.length <= 1) {
          hints.push(`No options provided for question no. ${i + 1}`);
          isValidQuestions = false;
        }
        let hasCorrectMarker = 0;
        for (let j = 0; j < qust.options.length; j++) {
          const option = qust.options[j];
          if (option.text === "") {
            hints.push(
              `Empty title found for question no. ${i + 1}, Option no.${
                j + 1
              } .`
            );
            isValidQuestions = false;
          }
          if (option.correct === true) {
            hasCorrectMarker++;
          }
        }
        if (hasCorrectMarker === 0) {
          hints.push(`No correct option found for question no. ${i + 1}.`);
          isValidQuestions = false;
        } else if (hasCorrectMarker > 1) {
          hints.push(
            `Multiple correct options found for question no. ${i + 1}.`
          );
          isValidQuestions = false;
        }
      }
      if (isValidQuestions) {
        setCurrentStatus(STATUS_REVIEW_QUESTIONS);
        setQuestions(res?.results);
      } else {
        setErrorMessage(res?.message ? res?.message : "Invalid document");
        setErrorHints(hints);
      }
    } else {
      setErrorMessage(res?.message ? res?.message : "Invalid document");
    }
  };

  const handleImportClose = (e) => {
    let categoriesFormatted = [];
    for (let i = 0; i < selectedCategories.length; i++) {
      categoriesFormatted.push(selectedCategories[i].id);
    }
    const updatedQuestions = questions.map((question) => {
      const { tempId, ...questionWithoutTempId } = question;
      return questionWithoutTempId;
    });
    callback(updatedQuestions, categoriesFormatted);
  };

  const handleSelectQuestion = (quest) => {
    setSelectedQuestion(quest);
  };  
  return (
    <Dialog open={open} fullWidth={true} maxWidth={`xl`} onClose={handleClose}>
      <DialogContent>
        <Typography variant="h4">Import Questions</Typography>
        <Divider sx={{ mt: 1 }} />
        <Box className="item-container">
          {errorMessage && (
            <Alert severity="error">
              {errorMessage}{" "}
              {errorHints.length > 0 ? ", Fix the following errors" : ""}
              {errorHints.length > 0 && (
                <ul>
                  {errorHints.map((err, index) => (
                    <li key={index}>{err}</li>
                  ))}
                </ul>
              )}
            </Alert>
          )}
          {isLoading && (
            <Box>
              <LinearProgress />
            </Box>
          )}
          {currentStatus === STATUS_UPLOAD && (
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box className="drop-area-container">
                  <Box
                    className={`drop-area ${isDragging ? "active" : ""} `}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={handleDrop}
                  >
                    <CloudUploadIcon className="drop-area-icon" />
                    <Typography variant="h5" className="drop-text-color">
                      Drag & Drop
                    </Typography>
                    <Typography variant="h5" className="drop-text-color">
                      or
                    </Typography>
                    <Button className="drop-selectfile-btn" component="label">
                      Select file
                      <input
                        onChange={handleFileChange}
                        accept=".docx"
                        type="file"
                        hidden
                      />
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} sx={{ pt: 2 }}>
                <Instructions />
              </Grid>
            </Grid>
          )}
          {currentStatus === STATUS_REVIEW_QUESTIONS && (
            <Box>
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={2} sx={{ p: 1 }}>
                  <Paper>
                    <MenuList sx={{ maxHeight: "450px", overflow: "auto" }}>
                      {questions.map((qst, index) => (
                        <MenuItem
                          key={index}
                          selected={selectedQuestion?.tempId === qst.tempId}
                        >
                          <ListItemIcon>
                            <HelpCenterIcon />
                          </ListItemIcon>
                          <Tooltip title={qst.question} placement="right">
                            <ListItemText
                              onClick={() => handleSelectQuestion(qst)}
                            >
                              {qst.question.length > 20
                                ? `${qst.question.substring(0, 20)}...`
                                : qst.question}
                            </ListItemText>
                          </Tooltip>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Paper>
                </Grid>
                <Grid item xs={10} sx={{ p: 3 }}>
                  {selectedQuestion ? (
                    <Box>
                      <Paper
                        sx={{ mb: 2, p: 1 }}
                        dangerouslySetInnerHTML={{
                          __html: selectedQuestion.question,
                        }}
                      ></Paper>
                      {selectedQuestion.explanation !== "" && (
                        <Paper
                          sx={{ mb: 2, p: 2 }}
                          dangerouslySetInnerHTML={{
                            __html: selectedQuestion.explanation,
                          }}
                        ></Paper>
                      )}
                      {selectedQuestion?.options.map((option, index) => (
                        <Paper
                          key={index}
                          sx={{
                            p: 1,
                            borderLeft: `5px solid ${
                              option.correct ? "green" : "red"
                            }`,
                            mt: 1,
                          }}
                          dangerouslySetInnerHTML={{ __html: option.text }}
                        ></Paper>
                      ))}
                    </Box>
                  ) : (
                    <Alert sx={{ m: 1 }} severity="info">
                      Select a question from the list to view the question
                      details
                    </Alert>
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
          {currentStatus === STATUS_QUESTIONS_SAVED && (
            <Box sx={{ p: 5 }}>
              <Alert severity="success">10 Questions has been imported</Alert>
              <Grid sx={{ mt: 4 }} container justifyContent="flex-end">
                <Button onClick={handleClose} variant="contained">
                  Close
                </Button>
              </Grid>
            </Box>
          )}
        </Box>
        {currentStatus === STATUS_REVIEW_QUESTIONS &&
          allCategories &&
          allCategories.length > 0 && (
            <Box>
              <Divider />
              <Autocomplete
                multiple
                name="categories"
                id="categories"
                sx={{ mt: 2, mb: 2 }}
                options={allCategories}
                getOptionLabel={(option) => option.name}
                value={selectedCategories}
                onChange={(event, value) => setSelectedCategories(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Categories"
                  />
                )}
              />
              <Divider />
            </Box>
          )}
      </DialogContent>
      <DialogActions>
        {currentStatus === STATUS_REVIEW_QUESTIONS && (
          <Box>
            <Button
              sx={{ mr: 1, ml: 1 }}
              variant="contained"
              onClick={handleUploadAgain}
            >
              Upload again
            </Button>
            <Button
              sx={{ mr: 1, ml: 1 }}
              variant="contained"
              onClick={handleImportClose}
            >
              Save questions
            </Button>
            <Button sx={{ mr: 1, ml: 1 }} onClick={handleClose}>
              Close
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default BulkImportQuestions;
