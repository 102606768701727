import { Box, Card, CardContent, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import styles from "./CourseCard.module.css";
import { getStudentCourses } from '../../redux/sagas/requests/course';
import { useSelector } from 'react-redux';
import moment from "moment";
import { useNavigate } from 'react-router-dom';

const colorArray = ["#FBC252", "#144272", "#FEA1BF", "#850000", "#D0B8A8", "#A555EC", "#6D67E4", "#FF7000", "#68B984", "#434242", "#009EFF", "#D23369", "#3B185F", "#975C8D", "#C539B4", "#7743DB", "#8D72E1", "#2146C7", "#BCCEF8", "#FF8787"];

export default function CourseCard({course}) {
    
    const [bgColor, setBgColor] = useState("#58287F")    
    const navigate = useNavigate();

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * colorArray.length);
        const randomColor = colorArray[randomIndex];
        setBgColor(randomColor);        
    },[]);

    

    const handleCardNavigation = () => {
        navigate(`/course/${course.uuid}`)
    };

    return (
        <Card onClick={handleCardNavigation} className={styles.CourseCardComponent} sx={{ maxWidth: "100%", margin: 'auto', marginTop: 2 }}>
            <Box className={styles.topDesign} sx={{ backgroundColor: bgColor }} ></Box>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">{course?.name}</Typography>
                <Typography variant="body2" color="text.secondary">
                    Assigned on: {moment(course.created_at).format('DD/MM/YYYY hh:mm:A')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Progress: {course.progress ?? 0}%
                </Typography>
            </CardContent>
        </Card>
    )
}
