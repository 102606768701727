import { Grid, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import AuthVerify from "./auth-forms/AuthVerify";
import AuthWrapper from "./AuthWrapper";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const VerifyAccount = () => {
  const session = useSelector((state) => state.user);
  if (!session?.token) {
    return <Navigate to="/login" />;
  }
  return (
    <AuthWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
            sx={{ mb: { xs: -0.5, sm: 0.5 } }}
          >
            <Typography variant="h3">Verification</Typography>
            <Typography
              component={Link}
              to="/logout"
              variant="body1"
              sx={{ textDecoration: "none" }}
              color="primary"
            >
              Logout
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <AuthVerify />
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default VerifyAccount;
