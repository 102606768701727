import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Modal, Paper, useMediaQuery, useTheme } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import { useFormik, useFormikContext } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, getUserById, setSelectedUser, updatePassword, updateUser } from '../../../redux/reducers/user';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import { createStudent, deleteStudent, updateStudent, updateStudentPassword } from '../../../redux/reducers/student';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createTest, updateTest } from '../../../redux/reducers/test';

const StudentForm = (props) => {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const type = (props && props.type && props.type == "update") ? 'update' : 'create';
  const selectedUser = (props && props.selectedUser) ? props.selectedUser : undefined;
  const userId = (props && props.userId) ? props.userId : undefined;
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const { selectedTest } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let validationSchema = yup.object({
    name: yup
      .string('Enter the name')
      .required('Name is required'),
    max_score: yup
      .number('Enter the maximum score')
      .typeError('Score must be a number')
      .when('section_wise', {
        is: false,
        then: yup.number().required('Maximum score is required'),
      }),
    min_score: yup
      .number('Enter the minimum score')
      .typeError('Score must be a number')
      .when('section_wise', {
        is: false,
        then: yup.number().required('Minimum score is required'),
      }),
    negative_mark: yup
      .number('Enter the negative mark')
      .typeError('Negative mark must be a number')
      .when('section_wise', {
        is: false,
        then: yup.number().required('Negative mark is required'),
      }),
    max_time: yup
      .number('Enter the Max time (In minutes)')
      .min(1)
      .required('Max time is required'),
  });
  useEffect(() => {
    if (selectedTest) {
      formik.setFieldValue('name', selectedTest.name);
      formik.setFieldValue('auto_score', selectedTest.auto_score === 1 ? true : false);
      formik.setFieldValue('section_wise', selectedTest.section_wise === 1 ? true : false);
      formik.setFieldValue('max_score', selectedTest.max_score ? selectedTest.max_score : 0);
      formik.setFieldValue('min_score', selectedTest.min_score ? selectedTest.min_score : 0);
      formik.setFieldValue('negative_mark', selectedTest.negative_mark ? selectedTest.negative_mark : 0);
      formik.setFieldValue('max_time', selectedTest.max_time ? selectedTest.max_time : 30);
      formik.setFieldValue('allow_practice_mode', selectedTest.allow_practice_mode ? Boolean(selectedTest.allow_practice_mode) : false);
    }
  }, [selectedTest]);

  let initValues = {
    name: '',
    auto_score: true,
    section_wise: false,
    max_score: '100',
    min_score: '30',
    negative_mark: 0,
    max_time: 30,
    allow_practice_mode: false
  }
  const formik = useFormik({
    initialValues: initValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.section_wise === true) {
        const { name, section_wise, max_time, allow_practice_mode } = values;
        const newData = { name, section_wise, max_time, allow_practice_mode };
        if (selectedTest) { //update
          newData.uuid = selectedTest.uuid;
          dispatch(updateTest(newData, navigate));
        } else { //create new
          dispatch(createTest(newData, navigate));
        }
      } else {
        if (selectedTest) { // update
          values.uuid = selectedTest.uuid;
          dispatch(updateTest(values, navigate));

        } else { //create new
          dispatch(createTest(values, navigate));
        }
      }
    },
  });

  const handleDeleteAction = () => {
    dispatch(deleteStudent(atob(userId), navigate));
  }

  const handleDialogOpen = () => {
    setDeleteDialog(true);
  }

  const handleDialogClose = () => {
    setDeleteDialog(false);
  }

  return (
    <Box>
      <Dialog
        fullScreen={fullScreen}
        open={deleteDialog}
        onClose={handleDialogClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Would like to delete this user?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deleting this user will make the user won't be able to log in to the application. If the user created any records in the application, this action will make the user restrict to login but the name will appear on the records.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDialogClose} >
            No
          </Button>
          <Button autoFocus onClick={handleDeleteAction} >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <form onSubmit={formik.handleSubmit} >
        <Grid container>
          <Grid item xl={3} xs={12} sm={6} md={3} sx={{ p: 1 }} >
            <TextField
              name="name"
              fullWidth={true}
              id="input-name"
              label="Name"
              variant="standard"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              autoComplete='off'
            />
          </Grid >
          <Grid item xl={3} xs={12} sm={6} md={3} sx={{ p: 1, pt: 2, pl: 5, display: selectedTest ? 'none' : 'block' }} >
            <FormGroup>
              <FormControlLabel
                onChange={formik.handleChange}
                name="section_wise"
                id="input-section_wise"
                control={<Checkbox
                  checked={formik.values.section_wise}
                />} label="Show Question Section wise" />
            </FormGroup>
          </Grid >
          {formik.values.section_wise === false &&
            <>
              <Grid item xl={3} xs={12} sm={6} md={3} sx={{ p: 1, pt: 2, pl: 5 }} >
                <FormGroup>
                  <FormControlLabel
                    onChange={formik.handleChange}
                    name="auto_score"
                    id="input-auto_score"
                    control={<Checkbox
                      checked={formik.values.auto_score}
                    />} label="Auto score" />
                </FormGroup>
              </Grid >
              <Grid item xl={3} xs={12} sm={6} md={3} sx={{ p: 1 }} >
                <TextField
                  name="max_score"
                  fullWidth={true}
                  id="input-max_score"
                  label="Maximum Score"
                  variant="standard"
                  value={formik.values.max_score}
                  onChange={formik.handleChange}
                  error={formik.touched.max_score && Boolean(formik.errors.max_score)}
                  helperText={formik.touched.max_score && formik.errors.max_score}
                  autoComplete="off"
                />
              </Grid >
              <Grid item xl={3} xs={12} sm={6} md={3} sx={{ p: 1 }} >
                <TextField
                  name="min_score"
                  fullWidth={true}
                  id="input-min_score"
                  label="Minimum Score"
                  variant="standard"
                  value={formik.values.min_score}
                  onChange={formik.handleChange}
                  error={formik.touched.min_score && Boolean(formik.errors.min_score)}
                  helperText={formik.touched.min_score && formik.errors.min_score}
                  autoComplete="off"
                />
              </Grid >
              <Grid item xl={3} xs={12} sm={6} md={3} sx={{ p: 1 }} >
                <TextField
                  name="negative_mark"
                  fullWidth={true}
                  id="input-neg_mark"
                  label="Negative mark"
                  variant="standard"
                  value={formik.values.negative_mark}
                  onChange={formik.handleChange}
                  error={formik.touched.negative_mark && Boolean(formik.errors.negative_mark)}
                  helperText={formik.touched.negative_mark && formik.errors.negative_mark}
                  autoComplete="off"
                />
              </Grid >
            </>
          }
          <Grid item xl={3} xs={12} sm={6} md={3} sx={{ p: 1 }} >
            <TextField
              name="max_time"
              fullWidth={true}
              id="input-max_time"
              label="Max time (In minutes)"
              variant="standard"
              value={formik.values.max_time}
              onChange={formik.handleChange}
              error={formik.touched.max_time && Boolean(formik.errors.max_time)}
              helperText={formik.touched.max_time && formik.errors.max_time}
              autoComplete="off"
            />
          </Grid >
          <Grid item xl={3} xs={12} sm={6} md={3} sx={{ p: 1 }} >
            <FormControlLabel control={<Checkbox name='allow_practice_mode' checked={formik.values.allow_practice_mode} onChange={formik.handleChange} />} label="Allow Practice Mode" />
          </Grid >
          <Grid item xl={12} xs={12} sm={12} md={12} sx={{ pt: 2 }} >
            <Divider />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xl={12} xs={12} sm={12} md={12} sx={{ pt: 2 }} >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              maxWidth={true}
            >
              {type == "update" && (<>
                <Grid item xs={12} md="auto" >
                  <Button onClick={handleDialogOpen} className="button-responsive-auto" variant="outlined" sx={{ mr: 2, mb: 2 }} startIcon={<DeleteIcon />}>Delete</Button>
                </Grid></>)}
              <Grid item xs={12} md="auto" >
                <Button className="button-responsive-auto" component={Link} to={`/tests/view/${selectedTest?.uuid}`} sx={{ mr: 2, mb: 2 }} variant="outlined">Cancel</Button>
              </Grid>
              <Grid item xs={12} md="auto" >
                <Button type="submit" className="button-responsive-auto" variant="contained" color="success" >{selectedTest ? "Update" : "Create"}</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default StudentForm;
