import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Snackbar, Alert, LinearProgress } from '@mui/material'
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import { getCourseRequest, updateCourseRequest } from '../../../../redux/sagas/requests/course';
import { useSelector } from 'react-redux';

export default function UpdateCourse({ uuid, open, handleClose, callback }) {

    const { token } = useSelector((state) => state.user);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [updateCourse, setUpdateCourse] = useState("");

    useEffect(() => {
        if (uuid) {
            init();
        }
    }, [uuid]);

    useEffect(() => {
        if (updateCourse) {
            formik.setFieldValue('name', updateCourse.name);
        }
    }, [updateCourse]);

    const init = async () => {
        try {
            setIsLoading(true);
            const response = await getCourseRequest({
                token,
                uuid: uuid
            });
            if (response?.course) {
                setUpdateCourse(response?.course);
            }
        } catch (err) {
            alert("Something went wrong");

        } finally {
            setIsLoading(false);
        }
    }

    const formik = useFormik({
        initialValues: {
            name: ""
        },
        validationSchema: yup.object({
            name: yup.string().required('Name is required')
        }),
        onSubmit: async (values) => {
            setIsLoading(true);
            let course = await updateCourseRequest({
                token,
                name: values.name,
                uuid: updateCourse.uuid
            })
            if (course?.success) {
                setSuccessMessage("Successfully updated!");
                callback();
            } else {
                setErrorMessage("Couldn't update the course");
            }
            setIsLoading(false);
            values.name = "";
        }
    });
    function handleSnackBarClose() {
        setErrorMessage("");
        setSuccessMessage("");
    }
    return (
        <Box>
            <Snackbar
                open={(successMessage !== "" || errorMessage !== "")}
                autoHideDuration={6000}
                onClose={handleSnackBarClose}
            >
                <Alert severity={successMessage !== "" ? "success" : "error"}>{successMessage || errorMessage}</Alert>
            </Snackbar>
            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle sx={{ fontSize: "21px", fontWeight: "bold" }} id="responsive-dialog-title">
                    Update course
                </DialogTitle>
                <DialogContent>
                    <TextField
                        sx={{ width: "100%" }}
                        label="Course name"
                        variant="standard"
                        type="text"
                        name="name"
                        autoComplete='off'
                        disabled={isLoading}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                    {isLoading && <LinearProgress />}
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={handleClose} >
                        Cancel
                    </Button>
                    <Button disabled={isLoading} variant="contained" onClick={formik.handleSubmit} >
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
