import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Divider, Grid, LinearProgress, Paper, Typography } from "@mui/material";
import QuizIcon from '@mui/icons-material/Quiz';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SectionModal from "./SectionModal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getSections } from "../../../redux/reducers/section";
import moment from 'moment/moment';
import SectionDelete from "./SectionDelete";
import SectionQuestions from "./SectionQuestions";
import { Link } from "react-router-dom";

const DetailItem = ({ title, desc }) => {
    return (
        <Grid container sx={{ mb: "20px" }}>
            <Grid item md={2} xs={12} >
                {title}
            </Grid>
            <Grid item md={10} xs={12} >
                {desc}
            </Grid>
            <Grid item md={12}><Divider /></Grid>
        </Grid>
    );
}

const SectionTab = ({ testId, testUuid }) => {
    const [openSectionModal, setOpenSectionModal] = useState(false);
    const [create, setCreate] = useState(true);
    const { sections, loading } = useSelector((state) => state.section);
    const dispatch = useDispatch();
    const [editSectionId, setEditSectionId] = useState(null);
    const [openDeleteSectionModal, setOpenDeleteSectionModal] = useState(false);
    const [removeSectionId, setRemoveSectionId] = useState(null);

    useEffect(() => {
        dispatch(getSections(testId));
    }, []);

    const handleOpenSection = () => {
        setCreate(true);
        setEditSectionId(null);
        setOpenSectionModal(true);
    }

    const handleCloseSection = () => {
        setOpenSectionModal(false);
    }

    const refetchSections = () => {        
        dispatch(getSections(testId));
    }

    const handleEditSection = (uuid) => {        
        setCreate(false);
        setEditSectionId(uuid);
        setOpenSectionModal(true);
    }

    const handleDeleteClick = (uuid) => {
        setRemoveSectionId(uuid);
        setOpenDeleteSectionModal(true);
    }

    const handleDeleteClose = () => {
        setRemoveSectionId(null);
        setOpenDeleteSectionModal(false);
    }

    return (
        <Box>
            <SectionModal
                testId={testId}
                open={openSectionModal}
                onClose={handleCloseSection}
                create={create}
                refetchSections={refetchSections}
                editSectionId={editSectionId}
            />
            <SectionDelete open={openDeleteSectionModal} onClose={handleDeleteClose} uuid={removeSectionId} refetchSections={refetchSections}  />
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }} >
                <Button onClick={handleOpenSection} variant="outlined" >Create Section</Button>
            </Box>
            <Box>
                <Divider textAlign="left" sx={{ mb: 2 }} >Sections</Divider>
                {(sections && sections.length > 0) ? (
                    sections.map((section) => (
                        <Accordion key={section.uuid} >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                            >
                                <Typography>{section.name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails >
                                <DetailItem title={`Auto score`} desc={section.auto_score === 1 ? `Yes` : `No`} />
                                <DetailItem title={`Max score`} desc={section.max_score} />
                                <DetailItem title={`Negative mark`} desc={section.negative_mark} />
                                <DetailItem title={`Min score`} desc={section.min_score} />
                                <DetailItem title={`Max Time (In minutes)`} desc={section.max_time} />
                                <DetailItem title={`Created on`} desc={moment(section.created_at).format('DD/MM/YYYY hh:mm:A')} />
                                <DetailItem title={`Updated on`} desc={moment(section.updated_at).format('DD/MM/YYYY hh:mm:A')} />
                                <Grid container sx={{ mb: "20px" }}>
                                    <Button component={Link} to={ `/tests/view/${testUuid}/${section.uuid}/questions` } variant="outlined" startIcon={<QuizIcon />} sx={{ mr: 1 }} >Questions</Button>
                                    <Button onClick={()=> handleEditSection(section.uuid)} variant="outlined" startIcon={<EditIcon />} sx={{ mr: 1 }} >Edit</Button>
                                    <Button onClick={() => handleDeleteClick(section.uuid)} variant="outlined" startIcon={<DeleteIcon />} sx={{ mr: 1 }} >Remove</Button>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    ))
                ) : (
                    loading ? (
                        <LinearProgress />
                    ) : (
                        <Alert severity="info">No sections has been created</Alert>
                    )
                )}
            </Box>
        </Box>
    );
}
export default SectionTab;