import { useTheme } from "@emotion/react";
import { Alert, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, Grid, LinearProgress, Snackbar, TextField, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useFormik } from "formik";
import * as yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { createSection, getSection, updateSection } from "../../../redux/reducers/section";
import { useEffect } from "react";

const SectionModal = ({ testId, open, onClose, create, refetchSections, editSectionId }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [isLoading, setIsLoading] = useState(create === false);
    const dispatch = useDispatch();
    const { loading, message, error, section } = useSelector((state) => state.section)
    const [alert, setAlert] = useState(null);

    const validationSchema = yup.object({
        name: yup
            .string('Enter the name')
            .required('Name is required'),
        max_score: yup
            .number('Enter the maximum score')
            .typeError('Score must be a number')
            .required('Maximum score is required'),
        min_score: yup
            .number('Enter the minimum score')
            .typeError('Score must be a number')
            .required('Minimum score is required'),
        negative_mark: yup
            .number('Enter the negative mark')
            .typeError('Negative mark must be a number')
            .required('Negative mark is required'),
        // max_time: yup
        //     .number('Enter the Max time')
        //     .typeError('Max time must be a number')
        //     .required('Max time is required')
    });


    const formik = useFormik({
        initialValues: {
            name: '',
            auto_score: true,
            max_score: '100',
            min_score: '30',
            negative_mark: 0,
            // max_time: 0
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (create) {
                values.tests_id = testId;                
                dispatch(createSection(values));
            }else{
                values.uuid = section.uuid;
                // console.log("values", values);
                dispatch(updateSection(values));                
            }
        },
    });

    useEffect(()=> {
        if(create === false && editSectionId !== ""){
            dispatch(getSection(editSectionId));
        }
        if(create === true){
            formik.resetForm();
        }
    },[open]);

    useEffect(() => {        
        if (section) {
            formik.setFieldValue('name', section.name);
            formik.setFieldValue('auto_score', section.auto_score === 1 ? true : false);
            formik.setFieldValue('max_score', section.max_score);
            formik.setFieldValue('min_score', section.min_score);
            formik.setFieldValue('negative_mark', section.negative_mark);            
        }
    }, [section]);
    useEffect(() => {        
        if (message?.status === "success") {
            formik.resetForm();
            handleClose();
            refetchSections();
            setAlert({
                severity: 'success',
                message: message.message
            });
        }
        if (error) {            
            setAlert({
                severity: 'error',
                message: error.message
            });
        }        
    }, [message, error]);



    const handleClose = () => {
        if (loading === false)
            onClose();
    }

    const handleAlertClose = () => {
        setAlert(null);
    }

    return (
        <>
            <Snackbar open={alert !== null} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity={ alert?.severity } sx={{ width: '100%' }}>{ alert?.message }</Alert>
            </Snackbar>            
            <Dialog
                open={open}
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth="md"
                onClose={handleClose}
            >
                <DialogTitle sx={{ fontSize: "16px", fontWeight: "bold" }} >
                    { create === true ? `Create Section`:`Update Section`  }
                </DialogTitle>
                <DialogContent>
                    {loading ? (
                        <Box sx={{ textAlign: 'center', mt: 5, pb: 5 }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <form onSubmit={formik.handleSubmit} >
                            <Grid container>
                                <Grid item xl={6} xs={12} sm={6} md={6} sx={{ p: 1 }} >
                                    <TextField
                                        name="name"
                                        fullWidth={true}
                                        id="input-name"
                                        label="Name"
                                        variant="standard"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        autoComplete="off"
                                        error={formik.touched.name && Boolean(formik.errors.name)}
                                        helperText={formik.touched.name && formik.errors.name}
                                    />

                                </Grid>
                                <Grid item xl={6} xs={12} sm={6} md={6} sx={{ p: 1 }} >
                                    <FormGroup>
                                        <FormControlLabel
                                            onChange={formik.handleChange}
                                            name="auto_score"
                                            id="input-auto_score"
                                            control={<Checkbox
                                                checked={formik.values.auto_score}
                                            />} label="Auto score" />
                                    </FormGroup>
                                </Grid>
                                <Grid item xl={6} xs={12} sm={6} md={6} sx={{ p: 1 }} >
                                    <TextField
                                        name="max_score"
                                        fullWidth={true}
                                        id="input-max_score"
                                        label="Maximum Score"
                                        variant="standard"
                                        value={formik.values.max_score}
                                        onChange={formik.handleChange}
                                        error={formik.touched.max_score && Boolean(formik.errors.max_score)}
                                        helperText={formik.touched.max_score && formik.errors.max_score}
                                    />
                                </Grid>
                                <Grid item xl={6} xs={12} sm={6} md={6} sx={{ p: 1 }} >
                                    <TextField
                                        name="min_score"
                                        fullWidth={true}
                                        id="input-min_score"
                                        label="Minimum Score"
                                        variant="standard"
                                        value={formik.values.min_score}
                                        onChange={formik.handleChange}
                                        error={formik.touched.min_score && Boolean(formik.errors.min_score)}
                                        helperText={formik.touched.min_score && formik.errors.min_score}
                                    />
                                </Grid>
                                <Grid item xl={6} xs={12} sm={6} md={6} sx={{ p: 1 }} >
                                    <TextField
                                        name="negative_mark"
                                        fullWidth={true}
                                        id="input-neg_mark"
                                        label="Negative mark"
                                        variant="standard"
                                        value={formik.values.negative_mark}
                                        onChange={formik.handleChange}
                                        error={formik.touched.negative_mark && Boolean(formik.errors.negative_mark)}
                                        helperText={formik.touched.negative_mark && formik.errors.negative_mark}
                                    />
                                </Grid>
                                {/* <Grid item xl={6} xs={12} sm={6} md={6} sx={{ p: 1 }} >
                                    <TextField
                                        name="max_time"
                                        fullWidth={true}
                                        id="input-max_time"
                                        label="Max time"
                                        variant="standard"
                                        value={formik.values.max_time}
                                        onChange={formik.handleChange}
                                        error={formik.touched.max_time && Boolean(formik.errors.max_time)}
                                        helperText={formik.touched.max_time && formik.errors.max_time}
                                    />
                                </Grid> */}
                            </Grid>
                            <Grid sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }} >
                                <Button onClick={handleClose} variant="outlined" disabled={loading} sx={{ mr: 1 }} >Cancel</Button>
                                <Button type="submit" variant="contained" disabled={loading} >{ create === true? `Create`: `Update` }</Button>
                            </Grid>
                        </form>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
}

export default SectionModal;