import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";

const SubmitAlert = ({
    open,
    handleClose,
    handleSubmit,
    answerStatus,
    questions
}) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Typography sx={{ textAlign: 'center', m:2 }} variant="h4">Exam Summary</Typography>
            <Divider sx={{ m: 1 }} />
            <DialogContent>
                <Box id="alert-dialog-description">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>No of Questions</TableCell>
                                <TableCell align="right">Answered</TableCell>
                                <TableCell align="right">Not Answered</TableCell>
                                <TableCell align="right">Marked for review</TableCell>
                                <TableCell align="right">Answered & Marked for Review</TableCell>
                                <TableCell align="right">Not Visited</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >                                                        
                            <TableRow>
                                <TableCell align="right">{ questions.length }</TableCell>
                                <TableCell align="right">{ answerStatus.filter((s) => s === "save").length }</TableCell>
                                <TableCell align="right">{ answerStatus.filter((s) => s === "visited").length }</TableCell>
                                <TableCell align="right">{ answerStatus.filter((s) => s === "review").length }</TableCell>
                                <TableCell align="right">{ answerStatus.filter((s) => s === "save-review").length }</TableCell>
                                <TableCell align="right">{ questions.length - answerStatus.filter(Boolean).length }</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
                <Divider sx={{ m: 1, mt: 2 }} />
                <Typography variant="h5">Please review your work carefully before submitting for final marking, as no changes will be allowed once the submission is complete. Are you absolutely sure that you want to proceed with the submission?</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>No</Button>
                <Button onClick={handleSubmit} autoFocus>Yes</Button>
            </DialogActions>
        </Dialog>
    );
}
export default SubmitAlert;