import { call, put, select } from "redux-saga/effects";
import { getStudentResults, getTest, getTests, startPracticeTest, startTest, submitAnswers, submitPracticeAnswers } from "../requests/studentTest";
import { setPracticeMode, setStudentResults, setTest, setTestStatus, setTests, setTimeLeft } from "../../reducers/studentTest";
import { createAlert } from "../../reducers/util";
const getToken = (state) => state.user;
const getTestInfo = (state) => state.studentTest;


export function* handleStudentGetTests(action) {
  const { token } = yield select(getToken);
  try {
    const response = yield call(getTests, { token: token });
    yield put(setTests(response));
  } catch (e) {
    console.log("Errror getting", e);
  }
}

export function* handleStudentGetTest(action) {
  const { token } = yield select(getToken);
  const { testId } = action;
  try {
    const response = yield call(getTest, { testId: testId, token: token });
    yield put(setTest(response));
  } catch (e) {
    console.log("Errror getting", e);
  }
}

export function* handleTestStart(action) {
  const { token } = yield select(getToken);
  const { assignedId } = action;
  try {
    const response = yield call(startTest, { assignedId: assignedId, token: token });
    if (response && response.status === "success") {
      yield put(setPracticeMode(false, ""));
      yield put(setTimeLeft(response.timeLeft));
      yield put(setTestStatus(true));
    }
  } catch (e) {
    yield put(createAlert("error", e.message));
  }
}

export function* handlePracticeTestStart(action) {
  const { token } = yield select(getToken);
  const { assignedId } = action;
  try {
    const response = yield call(startPracticeTest, { assignedId: assignedId, token: token });
    if (response && response.status === "success") {
      yield put(setPracticeMode(true, response?.practice_id));
      yield put(setTimeLeft(response.timeLeft));
      yield put(setTestStatus(true));
    }
  } catch (e) {
    yield put(createAlert("error", e.message));
  }
}



export function* handleSubmitTestAnswers(action) {
  const { token } = yield select(getToken);
  const { isPractice } = yield select(getTestInfo);
  const { testAnswers, navigate } = action;
  try {
    if (isPractice) {
      const response = yield call(submitPracticeAnswers, { answers: testAnswers, token: token });
      if (response && response.status === "success") {
        navigate(`/test/${testAnswers.test_id}?practice=1`);
      }
    } else {
      const response = yield call(submitAnswers, { answers: testAnswers, token: token });
      if (response && response.status === "success") {
        navigate(`/test/${testAnswers.test_id}`);
      }
    }
  } catch (e) {
    yield put(createAlert("error", e.message));
  }
}

export function* handleGetStudentResults(action) {
  // getStudentResults
  const { token } = yield select(getToken);
  const { testId } = action;
  try {
    const response = yield call(getStudentResults, { testId, token: token });
    if (response && response.status === "success") {
      yield put(setStudentResults(response.results));
    }
  } catch (e) {
    yield put(createAlert("error", e.message));
  }
}