import { call, put, select } from "redux-saga/effects";
import { setSelectedUser, setUser, setUserList, updateStatus } from "../../reducers/user";
import { clearAlert, createAlert, fullScreenLoading } from "../../reducers/util";
import { createUserRequest, deleteUserRequest, getUser, getUserList, updateUser, updateUserPassword, verifyUserLogin } from "../requests/user";
const getToken = (state) => state.user;

export function* handleLoginUser(action) {  
  try {
    const { username, password, navigate } = action;
    const response = yield call(verifyUserLogin, { username, password });            
    const { status, token, name, role, id, is_mobile_verified, mobile } = response;
    if (status === "success") {
      const user = { token, name, role, id, is_mobile_verified, mobile };      
      localStorage.setItem(btoa("token"), btoa(token));
      localStorage.setItem(btoa("name"), btoa(name));
      localStorage.setItem(btoa("role"), btoa(role));
      localStorage.setItem(btoa("is_mobile_verified"), btoa(is_mobile_verified));
      localStorage.setItem(btoa("id"), btoa(id));
      localStorage.setItem(btoa("mobile"), btoa(mobile));      
      yield put(setUser(user));
      navigate("/");
    } else if (status === "failed") {
      const { message } = response;
      yield put(updateStatus(status, message));
    }
  } catch (error) {
    const { message, status } = error;
    if (status === "failed") {

      yield put(updateStatus(status, message));
    }
  }
}

export function* handleCreateUser(action) {
  yield put(fullScreenLoading(true));
  const { token } = yield select(getToken);
  const response = yield call(createUserRequest, { user: action.user, token: token });
  if (response && response.status === "success") {
    const status = "success";
    let { message, result } = response;
    yield put(createAlert(status, message));
    const { navigate } = action;
    navigate('/users/view/' + btoa(result));
    yield put(fullScreenLoading(false));
  } else {
    const status = "error";
    let { message } = response;
    message = message == "" ? "Something went wrong!" : message;
    yield put(createAlert(status, message));
    yield put(fullScreenLoading(false));
  }
}

export function* handleGetUsers(action) {
  const { token } = yield select(getToken);
  const response = yield call(getUserList, { token: token });
  yield put(fullScreenLoading(true));
  if (response && response.status === "success") {
    let { result } = response;
    yield put(setUserList(result));
    yield put(fullScreenLoading(false));
  } else {
    const status = "error";
    let { message } = response;
    message = message == "" ? "Something went wrong!" : message;
    yield put(createAlert(status, message));
    yield put(fullScreenLoading(false));
  }
}

export function* handleGetUserById(action) {  
  const { token } = yield select(getToken);    
  const { userId } = action;
  const response = yield call(getUser, { token: token, userId:atob(userId) });  
  yield put(fullScreenLoading(true));
  if (response && response.status === "success") {
    let { result } = response;
    yield put(setSelectedUser(result));    
    yield put(fullScreenLoading(false));
  } else {
    const status = "error";
    let { message } = response;
    message = message == "" ? "Something went wrong!" : message;
    yield put(createAlert(status, message));
     yield put(fullScreenLoading(false));
  }
}

export function* handleUpdateUser(action){  
  try {
    const { token } = yield select(getToken);          
    const { user, navigate } = action;
    yield put(fullScreenLoading(true));
    const response = yield call(updateUser, { token: token, user:user });  
    if (response && response.status === "success") {      
      const { message } = response;
      navigate('/users/view/' + btoa(user.userId));
      yield put(createAlert("success", message));
      yield put(fullScreenLoading(false));
    } else {
      const status = "error";
      let { message } = response;
      message = message == "" ? "Something went wrong!" : message;
      yield put(createAlert(status, message));
       yield put(fullScreenLoading(false));
    }    
  } catch (error) {
    const { message, status } = error;
    if (status === "failed") {
      yield put(updateStatus(status, message));
    }
  }
}

export function* handleUpdateUserPassword(action){  
  try {
    const { token } = yield select(getToken);          
    const { user, navigate } = action;
    yield put(fullScreenLoading(true));
    const response = yield call(updateUserPassword, { token: token, user:user });  
    if (response && response.status === "success") {      
      const { message } = response;
      navigate('/users/view/' + btoa(user.userId));
      yield put(createAlert("success", message));
      yield put(fullScreenLoading(false));
    } else {
      const status = "error";
      let { message } = response;
      message = message == "" ? "Something went wrong!" : message;
      yield put(createAlert(status, message));
       yield put(fullScreenLoading(false));
    }    
  } catch (error) {
    const { message, status } = error;
    if (status === "failed") {
      yield put(updateStatus(status, message));
    }
  }
}

export function* handleDeleteUser(action) {
  yield put(fullScreenLoading(true));
  const { token } = yield select(getToken);
  const response = yield call(deleteUserRequest, { userId: action.userId, token: token });
  if (response && response.status === "success") {
    const status = "success";
    let { message, result } = response;
    yield put(createAlert(status, message));
    const { navigate } = action;
    navigate('/users');
    yield put(fullScreenLoading(false));
  } else {
    const status = "error";
    let { message } = response;
    message = message == "" ? "Something went wrong!" : message;
    yield put(createAlert(status, message));
    yield put(fullScreenLoading(false));
  }
}