import { Box, Button, Container } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { env } from "../../../../config";

export const TestCard = ({ item }) => {
  const [uuid, setUuid] = useState(undefined);
  const { token } = useSelector((state) => state.user);

  useEffect(() => {
    if (item) {
      fetchTest();
    }
  }, [item]);

  const headers = {
    "Content-Type": "application/json",
    Authorization: token,
  };

  const fetchTest = async () => {
    try {
      const { data } = await axios.get(
        env.REACT_APP_API_HOST + `/api/access/test/get-uuid/${item?.test_id}`,
        { headers: headers }
      );
      if (data && data?.result && data?.result?.uuid) {
        setUuid(data?.result?.uuid);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box>
      <Container sx={{ textAlign: "center" }}>
        {uuid ? (
          <Button variant="outlined" component={Link} to={`/test/${uuid}`} target="_blank" >
            View Test
          </Button>
        ) : (
          <Box>Loading</Box>
        )}
      </Container>
    </Box>
  );
};
