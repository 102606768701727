/** Actions */
export const CREATE_SECTION = "CREATE_SECTION";
export const GET_SECTIONS = "GET_SECTIONS";
export const GET_SECTION = "GET_SECTION";
export const UPDATE_SECTION = "UPDATE_SECTION";
export const REMOVE_SECTION = "REMOVE_SECTION";


const SECTION_SUCCESS = "SECTION_SUCCESS";
const SECTION_FAILURE = "SECTION_FAILURE";
const CLEAR_MESSAGES = "CLEAR_MESSAGES";
const SET_SECTIONS = "SET_SECTIONS";
const SET_SECTION = "SET_SECTION";




export const createSection = (section) => ({
    type: CREATE_SECTION,
    section
});

export const updateSection = (section) => ({
    type: UPDATE_SECTION,
    section
});

export const removeSection = (uuid) => ({
    type: REMOVE_SECTION,
    uuid
});

export const sectionSuccess = (message) => ({
    type: SECTION_SUCCESS,
    message
});

export const sectionError = (error) => ({
    type: SECTION_FAILURE,
    error
});

export const clearMessages = () => ({
    type: CLEAR_MESSAGES
});

export const getSections = (testId) => ({
    type: GET_SECTIONS,
    testId
});

export const setSections = (sections) => ({
    type: SET_SECTIONS,
    sections
});

export const getSection = (uuid) => ({
    type: GET_SECTION,
    uuid
});

export const setSection = (section) => ({
    type: SET_SECTION,
    section
});

const initialState = {
    loading: false,
    message: null,
    error: null,
    response: null, 
    sections: [],
    section: null
};

const sectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_SECTION:
            return { ...state, loading: true, error: null, message: null };
        case UPDATE_SECTION:
            return { ...state, loading: true, error: null, message: null };
        case REMOVE_SECTION:
            return { ...state, loading: true, error: null, message: null };
        case SECTION_SUCCESS:
            return { ...state, loading: false, message: action.message };
        case SECTION_FAILURE:
            return { ...state, loading: false, error: action.error };
        case CLEAR_MESSAGES:
            return { ...state, loading: false, error: null, message: null };
        case GET_SECTIONS: 
            return { ...state, loading: true };
        case SET_SECTIONS: 
            return { ...state, loading: false, sections: action.sections };
        case GET_SECTION:
            return { ...state, loading: true, section: null };
        case SET_SECTION:
            return { ...state, loading: false, section: action.section };
        default:
            return state;
    }
}

export default sectionReducer;