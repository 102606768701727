import MainCard from '../../components/MainCard';
import MainLayout from '../../layout/MainLayout';
import StudentForm from './forms/StudentForm';

const AddNewStudent = () => (
    <MainLayout>
        <MainCard title="Add New Student">
            <StudentForm />            
        </MainCard>
    </MainLayout>
);

export default AddNewStudent;
