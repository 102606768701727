/** Actions */
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const REMOVE_CATEGORY = "REMOVE_CATEGORY";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORY = "GET_CATEGORY";
export const SET_CATEGORY = "SET_CATEGORY";

const CATEGORY_LOADING = "CATEGORY_LOADING";

const initialState = {                
    loading: false,
    loadingCategories: false,
    categories: [],
    parentCategories: [],
    category: null,
};

export const getCategories = (parent_id) => ({
    type: GET_CATEGORIES,
    parent_id
});

export const setCategories = (categories, parentCategories) => ({
    type: SET_CATEGORIES,
    categories,
    parentCategories
});

export const createCategory = (category, handleCloseCreateCategory) => ({
    type: CREATE_CATEGORY,
    category,
    handleCloseCreateCategory
});

export const updateCategory = (category, handleCloseUpdateCategory) => ({
    type: UPDATE_CATEGORY,
    category,
    handleCloseUpdateCategory
});

export const removeCategory = (uuid, navigate, link) => ({
    type: REMOVE_CATEGORY,
    uuid,
    navigate,
    link
});

export const categoryLoading = (loading) => ({
    type: CATEGORY_LOADING,
    loading
})

export const getCategory = (uuid) => ({
    type: GET_CATEGORY,
    uuid
});

export const setCategory = (category) => ({
    type: SET_CATEGORY,
    category
});

const categoryReducer = (state = initialState, action) => {
    switch(action.type){
        case CREATE_CATEGORY:
            return { ...state, loading: true };
        case UPDATE_CATEGORY:
            return { ...state, loading: true };
        case CATEGORY_LOADING:
            return { ...state, loading: action.loading };
        case GET_CATEGORIES:
            return { ...state, loadingCategories: true  };
        case SET_CATEGORIES:
            return { ...state, loadingCategories: false, categories: action.categories, parentCategories: action.parentCategories  };
        case GET_CATEGORY:
            return { ...state, loadingCategories: true };
        case SET_CATEGORY:
                return { ...state, loadingCategories: false, category: action.category };
        default: 
            return state;
    }
}

export default categoryReducer;