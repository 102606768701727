import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Box, LinearProgress, Alert, FormControl, InputLabel, Typography } from '@mui/material'
import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as yup from 'yup';
import "./items.css";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createCourseItemRequest, updateCourseItemRequest } from '../../../../../redux/sagas/requests/course';
import YoutubePlayerComponent from '../../../../../components/YouTubePlayer';

export default function YoutubeItem({ open, handleClose, type, onSuccess, updateItem }) {
    const { token } = useSelector((state) => state.user);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [link, setLink] = useState("");
    const { courseId, cateId } = useParams();

    function getYoutubeVideoId(youtubeLink) {
        if (updateItem && updateItem.url === youtubeLink) return youtubeLink;
        const videoID = youtubeLink.match(/[?&]v=([^&]+)/)[1];
        console.log("videoID", videoID)
        return videoID;
        // const embedLink = `https://www.youtube.com/embed/${videoID}`;    
        // return embedLink;
    }

    function initState() {
        setLoading(false);
        setErrorMessage("");
        setLink("");
        formik.resetForm();
    }

    const handleCloseThis = () => {
        initState();
        handleClose();
    }

    const handleLinkChange = (event) => {
        const newValue = event.target.value.trim(); // Trim leading/trailing whitespace
      
        // Check for either valid YouTube video ID format or full YouTube URL format
        const isValidLink =
          /^[a-zA-Z0-9_-]{11}$/.test(newValue) || // Valid video ID
          /^((https?:)?\/\/)?((www\.)?youtube\.com\/)?((v\/|embed\/)|(?:[^\/\n\s]+\/\S+\/))([a-zA-Z0-9_-]{11})$/.test(newValue); // Valid YouTube URL
      
        if (isValidLink) {
          setLink(newValue);
        } else {
          // Optional: Provide user feedback (e.g., display an error message)
          console.error('Invalid YouTube link or video ID');
        }
      
        formik.handleChange(event); // Pass the event to Formik for other form-related handling
      };

    const formik = useFormik({
        initialValues: {
            title: updateItem?.name ?? "",
            link: updateItem?.url ?? "",
        },
        validationSchema: yup.object({
            title: yup.string().required('Title is required'),
            link: yup.string()
            .required('YouTube link or video ID is required')
            .test('isYoutubeLinkOrId', 'Invalid YouTube URL or video ID', (value) => {
              // Check for YouTube video ID format
              const videoIdRegex = /^[a-zA-Z0-9_-]{11}$/;
              if (videoIdRegex.test(value)) {
                return true;
              }
        
              // Check for full YouTube URL format
              const youtubeUrlRegex = /^((https?:)?\/\/)?((www\.)?youtube\.com\/)?((v\/|embed\/)|(?:[^\/\n\s]+\/\S+\/))([a-zA-Z0-9_-]{11})$/;
              return youtubeUrlRegex.test(value);
            })
        }),
        onSubmit: async (values) => {
            if (updateItem) { //update
                await updateCourseItemRequest({
                    token,
                    input: {
                        uuid: updateItem.uuid,
                        name: values.title,
                        url: values.link,
                    }
                });
            } else {
                await createCourseItemRequest({
                    token,
                    input: {
                        name: values.title,
                        course_id: courseId,
                        url: values.link,
                        type: type,
                        categoryUuid: cateId
                    }
                });
            }
            initState();
            onSuccess("Youtube content has successfully created");
        }
    });

    console.log("link", link);
    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth="md"
            onClose={handleCloseThis}
        >
            <DialogTitle sx={{ fontSize: "17px", fontWeight: "bold" }}>
                Youtube
                <IconButton
                    aria-label="close"
                    onClick={handleCloseThis}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                {errorMessage !== "" && <Alert severity='error'>{errorMessage}</Alert>}
                <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label="Content title"
                    type="text"
                    fullWidth
                    variant="standard"
                    placeholder='Introduction to trigonometry'
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                />
                <TextField
                    margin="dense"
                    id="link"
                    label="Youtube Link"
                    type="text"
                    fullWidth
                    variant="standard"
                    placeholder='https://www.youtube.com/watch?v=_liNEq'
                    value={formik.values.link}
                    onChange={handleLinkChange}
                    autoComplete='off'
                    error={formik.touched.link && Boolean(formik.errors.link)}
                    helperText={formik.touched.link && formik.errors.link}
                />
                {link !== "" && (
                    <Box sx={{ mt: 2 }} className="video-container" >
                        {/* <iframe width="240" src={link}> </iframe> */}
                        <YoutubePlayerComponent uniqueId="video" width="100%" link={link} />
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseThis} >Cancel</Button>
                <Button variant="contained" onClick={formik.handleSubmit} >Save</Button>
            </DialogActions>
        </Dialog>
    )
}
