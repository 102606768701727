import MainCard from '../../components/MainCard';
import MainLayout from '../../layout/MainLayout';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import TableData from './TableData';
import { useState } from 'react';
import BulkImportQuestions from '../../components/bulkImportQuestions/BulkImportQuestions'
import { useDispatch, useSelector } from 'react-redux';
import { bulkImportQuestion } from '../../redux/sagas/requests/questions';
import { Alert } from '@mui/material';
import { getQuestions } from '../../redux/reducers/questions';
import AssignmentIcon from '@mui/icons-material/Assignment';

const QuestionBank = () => {
    const [idImportQuestionOpen, setOpenImportQuestion] = useState(false);
    const { token } = useSelector((state) => state.user);    
    const [successMessage, setSuccessMessage]= useState('');
    const dispatch = useDispatch();

    const openImportQuestion = () => {
        setSuccessMessage('');
        setOpenImportQuestion(true);
    }

    const closeImportQuestion = () => {
        setOpenImportQuestion(false);
    }

    const ImportQuestions = async (questions, categories) => {                
        setOpenImportQuestion(false);
        await bulkImportQuestion({ token, importData: {questions, categories}});         
        let params = [];
        params.page =  1;
        params.pageSize = 10;
        params.searchQuery = '';
        dispatch(getQuestions(params));
        setSuccessMessage('Questions has been successfully imported');           
        setTimeout(() => {
            setSuccessMessage('');
        }, 4500);    
    }


    return (<MainLayout>
                <MainCard title="Question bank">
                    {successMessage !== '' &&(
                        <Alert severity='success' sx={{ mb:2 }} >{successMessage}</Alert>
                    )}                    
                    <BulkImportQuestions open={idImportQuestionOpen} handleClose={closeImportQuestion} callback={ImportQuestions} />
                    <Box sx={{ m:1, mb:4 }} display="flex" justifyContent="flex-end">
                        <Button onClick={openImportQuestion} sx={{ mr: 2 }} variant="outlined" size="small" startIcon={<AssignmentIcon />} > Import Docx</Button>                
                        <Button component={Link} to="/questions/create" variant="contained" size="small">Create Question</Button>                
                    </Box>
                    <TableData />
                </MainCard>
            </MainLayout>);
};

export default QuestionBank;
