import { useTheme } from "@emotion/react";
import { Alert, Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useMediaQuery } from "@mui/material";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getCategories } from "../../../redux/reducers/category";
import { getAllCategories, searchQuestions } from "../../../redux/reducers/questions";
import "./ImportQuestions.css";

const ImportQuestion = ({ open, handleClose, exclude, importQuestion }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { allCategories, searchLoading, filteredQuestions } = useSelector((state) => state.questions)
    const dispatch = useDispatch();
    const [filterSearch, setFilterSearch] = useState("");
    const [filterCategories, setFilterCategories] = useState([]);
    const [filterExcludeIds, setFilterExcludeIds] = useState([]);
    const [addedQuestions, setAddedQuestions] = useState([]);

    const ImportQuestionAction = () => {
        importQuestion(addedQuestions.map(quest => quest.uuid));
    }

    useEffect(() => {
        dispatch(getAllCategories());
        setFilterCategories([]);
        setFilterSearch('');
        setAddedQuestions([]);
        if(exclude){
            setFilterExcludeIds(exclude);
        }
    }, [open]);

    useEffect(() => { //change callback
        updateQuestions();
    }, [filterCategories, filterExcludeIds]);

    const handleSearchChange = (e) => {
        setFilterSearch(e.target.value);
    }

    const handleOnSearchKeyUp = (e) => {
        if (e.key === 'Enter') {
            updateQuestions();
        }
    }

    const handleCategoryChange = (e, values) => {
        setFilterCategories(values.map(val => val.id) || []);
    }

    const updateQuestions = () => {
        const filter = { searchQuery: filterSearch, categories: filterCategories, exclude: filterExcludeIds };
        dispatch(searchQuestions(filter));
    }

    const handleAddQuestions = (question) => {        
        setAddedQuestions(prevQuestions => [...prevQuestions, question]);        
        setFilterExcludeIds(ids => [...ids, question.id]);
    }

    const handleRemoveQuestions = (quest) => {
        setAddedQuestions(prevQuestions => prevQuestions.filter(question => question.id !== quest.id));
        setFilterExcludeIds(ids => ids.filter(id => id !== quest.id ));        
    }

    return (
        <Dialog
            open={open}
            fullScreen={fullScreen}
            maxWidth="xl"
            onClose={handleClose}
        >
            <DialogTitle>Import Questions</DialogTitle>
            <DialogContent sx={{ minWidth: "60vw" }} >
                <Divider />
                <Grid container sx={{ width: "100%" }}>
                    <Grid item xs={12} md={3} sx={{ p: 1 }} >
                        <TextField
                            sx={{ width: "100%" }}
                            id="standard-basic"
                            label="Search Question"
                            variant="standard"
                            value={filterSearch}
                            onChange={handleSearchChange}
                            onKeyUp={handleOnSearchKeyUp}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ p: 1 }} >
                        <Autocomplete
                            multiple
                            sx={{ width: "100%" }}
                            name="categories"
                            id="categories"
                            options={allCategories}
                            getOptionLabel={(option) => option.name}
                            onChange={handleCategoryChange}                            
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Categories"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <Divider />
                    </Grid>
                </Grid>
                <Grid container sx={{ width: "100%" }}>
                    <Grid item xs={12} md={6} sx={{ p: 1 }} >
                        <Typography sx={{mt: 1, mb: 2}} >All Questions ({ searchLoading?'...':filteredQuestions.length })</Typography>
                        <TableContainer component={Paper} sx={{ maxHeight: "350px", overflow: "auto" }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>                                        
                                        <TableCell>Description</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Score</TableCell>
                                        <TableCell>Difficulty</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {searchLoading && (
                                        <TableRow>
                                            <TableCell colSpan={6} sx={{ textAlign: 'center', pt: 2, pb: 3 }}>
                                                <CircularProgress size={25} />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {filteredQuestions?.length === 0 && (
                                        <TableRow>
                                            <TableCell colSpan={6}>
                                                <Alert severity="info">No question has been avaliable to add <Link to="/questions/create">Create now</Link></Alert>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {filteredQuestions.map(question => (
                                        <TableRow key={question.uuid} >
                                            <TableCell>
                                                <Tooltip title="Add Question" >
                                                    <IconButton onClick={()=> handleAddQuestions(question)} ><AddCircleOutlineOutlinedIcon /></IconButton>
                                                </Tooltip>
                                            </TableCell>                                            
                                            <TableCell className="title-td" >
                                                <span className="title-container" dangerouslySetInnerHTML={{ __html: question.description }} ></span>
                                            </TableCell>
                                            <TableCell>{question.type}</TableCell>
                                            <TableCell>{question.score}</TableCell>
                                            <TableCell>{question.difficulty}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ p: 1 }} >
                        <Typography sx={{mt: 1, mb: 2}} >Selected Questions ({ addedQuestions.length })</Typography>
                        <TableContainer component={Paper} sx={{ maxHeight: "350px", overflow: "auto" }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>                                        
                                        <TableCell>Description</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Score</TableCell>
                                        <TableCell>Difficulty</TableCell>                                        
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {addedQuestions.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={6}>
                                            <Alert severity="info">No Question has been added yet</Alert>
                                        </TableCell>
                                    </TableRow>
                                    )}               
                                    {addedQuestions?.map(question => (
                                        <TableRow key={question.uuid} >
                                            <TableCell>
                                                <Tooltip title="Remove Question" >
                                                    <IconButton onClick={()=> handleRemoveQuestions(question)} ><RemoveCircleOutlineIcon /></IconButton>
                                                </Tooltip>
                                            </TableCell>                                            
                                            <TableCell><span className="title-container" dangerouslySetInnerHTML={{ __html: question.description }} ></span></TableCell>
                                            <TableCell>{question.type}</TableCell>
                                            <TableCell>{question.score}</TableCell>
                                            <TableCell>{question.difficulty}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" sx={{ mr: 1 }} >Cancel</Button>
                <Button variant="contained" disabled={addedQuestions.length === 0} onClick={ImportQuestionAction} >Import Questions</Button>
            </DialogActions>
        </Dialog>
    );
}
export default ImportQuestion;