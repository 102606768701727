import axios from "axios";
import { env } from "../../../config";
import { decryptData } from "../../../utils/data-decryption";


export function getTests(request) {
  const { token } = request;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token
  }
  return new Promise((resolve, reject) => {
    axios.get(env.REACT_APP_API_HOST + '/api/access/studenttest/tests', { headers: headers })
      .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject({
          status: 'failed',
          message: error.response.data.error
        });
      });
  });
}

export function getTest(request) {
  const { testId, token } = request;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token
  }
  return new Promise((resolve, reject) => {
    axios.post(env.REACT_APP_API_HOST + '/api/access/studenttest/test', { uuid: testId }, { headers: headers })
      .then(function (response) {
        if (response.data && response.data.data && response.data.iv && response.data.key) {
          const data = decryptData(response.data);
          resolve(data)
        }
      })
      .catch(function (error) {
        reject({
          status: 'failed',
          message: error.response.data.error
        });
      });
  });
}

export function startTest(request) {
  const { assignedId, token } = request;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token
  }
  return new Promise((resolve, reject) => {
    axios.post(env.REACT_APP_API_HOST + '/api/access/studenttest/starttest', { uuid: assignedId }, { headers: headers })
      .then(function (response) {
        if (response.data) {
          resolve(response.data)
        }
      })
      .catch(function (error) {
        reject({
          status: 'failed',
          message: error.response.data.error
        });
      });
  });
}

export function startPracticeTest(request) {
  const { assignedId, token } = request;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token
  }
  return new Promise((resolve, reject) => {
    axios.post(env.REACT_APP_API_HOST + '/api/access/studenttest/starttest-practice', { uuid: assignedId }, { headers: headers })
      .then(function (response) {
        if (response.data) {
          resolve(response.data)
        }
      })
      .catch(function (error) {
        reject({
          status: 'failed',
          message: error.response.data.error
        });
      });
  });
}

export function submitAnswers(request) {
  const { answers, token } = request;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token
  }
  return new Promise((resolve, reject) => {
    axios.post(env.REACT_APP_API_HOST + '/api/access/studenttest/submitanswers', { assignmentId: answers.assignmentId, test_id: answers.test_id, answers: answers.answers }, { headers: headers })
      .then(response => {
        if (response.data) {
          resolve(response.data);
        }
      })
      .catch(err => {
        reject({ status: 'failed', message: err.message });
      });
  });
}


export function submitPracticeAnswers(request) {
  const { answers, token } = request;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token
  }
  return new Promise((resolve, reject) => {
    axios.post(env.REACT_APP_API_HOST + '/api/access/studenttest/submitanswers-practice', { assignmentId: answers.assignmentId, test_id: answers.test_id, answers: answers.answers }, { headers: headers })
      .then(response => {
        if (response.data) {
          resolve(response.data);
        }
      })
      .catch(err => {
        reject({ status: 'failed', message: err.message });
      });
  });
}

export function getStudentResults(request) {
  const { token, testId } = request;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token
  }
  return new Promise((resolve, reject) => {
    axios.post(env.REACT_APP_API_HOST + '/api/access/studenttest/get-results', { test_id: testId }, { headers: headers })
      .then(function (response) {
        if (response && response.data && response.data.status) {
          resolve({
            status: response.data.status,
            message: response.data.message,
            results: response.data.results
          });
        } else {
          reject({
            status: 'failed',
            message: 'Something went wrong!'
          });
        }
      })
      .catch(function (error) {
        reject({
          status: 'failed',
          message: 'Something went wrong!'
        });
      });
  });
}