/** Actions */
export const CREATE_STUDENT = "CREATE_STUDENT";
export const GET_STUDENT_LIST = "GET_STUDENT_LIST";
export const SET_STUDENTS = "SET_STUDENTS";
export const GET_STUDENT_BY_ID = "GET_STUDENT_BY_ID";
export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const STUDENT_UPDATE_PASSWORD = "STUDENT_UPDATE_PASSWORD";
export const DELETE_STUDENT = "DELETE_STUDENT";


const SET_SELECTED_STUDENT = "SET_SELECTED_STUDENT";

const initialState = {                
    students: [],
    selectedUser: undefined
};

export const createStudent = (user, navigate) => ({
    type: CREATE_STUDENT,
    user, 
    navigate
});

export const getStudentList = () => ({
    type: GET_STUDENT_LIST
});

export const setStudents = (students) => ({
    type: SET_STUDENTS,
    students
});

export const getStudentById = (userId) => ({
    type: GET_STUDENT_BY_ID,
    userId
});

export const setSelectedStudent = (user) => ({
    type: SET_SELECTED_STUDENT,
    selectedUser: user,
});

export const updateStudent = (user, navigate) => ({
    type: UPDATE_STUDENT,
    user,
    navigate
});

export const updateStudentPassword = (user, navigate) => ({
    type: STUDENT_UPDATE_PASSWORD,
    user,
    navigate
})

export const deleteStudent = (userId, navigate) => ({
    type: DELETE_STUDENT,
    userId,
    navigate
});

export default (state = initialState, action) => {
    switch(action.type){
        case SET_STUDENTS:
            const { students } = action;
            return { ...state, students };
        
        case SET_SELECTED_STUDENT:
                const { selectedUser } = action;            
                return { ...state, selectedUser };
        default:
            return state;
    }
}