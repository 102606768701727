/** Actions */
const TOGGLE_DRAWER = "TOGGLE_DRAWER";
const CHANGE_ACTIVEMENU = "CHANGE_ACTIVEMENU";
const FULL_SCREEN_LOADING = "FULL_SCREEN_LOADING";
const CREATE_ALERT = "CREATE_ALERT";
const STUDENT_SET_BACKLINK = "STUDENT_SET_BACKLINK";
const SET_STUDENT_WINDOW_LOADING = "SET_STUDENT_WINDOW_LOADING";
export const CLEAR_ALERT = "CLEAR_ALERT";
const CREATE_SNACKBAR_ALERT = 'CREATE_SNACKBAR_ALERT';
const CLEAR_SNACKBAR_ALERT = 'CLEAR_SNACKBAR_ALERT';

export const toggleDrawer = () => ({
    type: TOGGLE_DRAWER,
});

export const activeItem = (openMenu) => ({
    type: CHANGE_ACTIVEMENU, 
    openMenu
});

export const fullScreenLoading = (fullScreenLoading) => ({
    type: FULL_SCREEN_LOADING, 
    fullScreenLoading
});

export const createAlert = (alertStatus, alertMessage) => ({
    type: CREATE_ALERT,
    alertType: alertStatus,
    alertMessage: alertMessage,
});

export const clearAlert = (alertId) => ({
    type: CLEAR_ALERT,
    alertId
});

export const setGoBackLink = (studentBackLink) => ({
    type: STUDENT_SET_BACKLINK,
    studentBackLink
});

export const setStudentWindowLoading = (loading) => ({
    type: SET_STUDENT_WINDOW_LOADING,
    studentWindowLoading: loading
});

export const createSnackBarAlert = (message) => ({
    type: CREATE_SNACKBAR_ALERT,
    message
});

export const clearSnackBarAlert = () => ({
    type: CLEAR_SNACKBAR_ALERT
});

const initialState = {
    drawerOpen: true,
    openMenu: ['dashboard'],
    fullScreenLoading: false,
    alerts: [],
    studentBackLink: null,
    studentWindowLoading: false,
    snackBarAlert: null
};

const utilReducer = (state = initialState, action) => {
    switch(action.type){
        case TOGGLE_DRAWER:
            return { ...state, drawerOpen: !state.drawerOpen };
        case CHANGE_ACTIVEMENU:
            const { openMenu } = action;
            return { ...state, openMenu };
        case FULL_SCREEN_LOADING:            
            const { fullScreenLoading } = action;
            return { ...state, fullScreenLoading }; 
        case CREATE_ALERT:
            const { alertType, alertMessage } = action;              
            var alerts = state.alerts;
            var alertId = "alert-" + Math.random().toString(16).slice(2);
            alerts.push({ alertType, alertMessage, alertId });                         
            return { ...state, alerts };
        case CLEAR_ALERT:             
            var { alertId } = action;
            var newAlerts = [];
            for(var i in state.alerts){
                if(state.alerts[i].alertId != alertId){
                    newAlerts.push(state.alerts[i]);
                }                
            }            
            return { ...state, alerts: newAlerts };
        case STUDENT_SET_BACKLINK:
            const { studentBackLink } = action;
            return { ...state, studentBackLink };
        case SET_STUDENT_WINDOW_LOADING:
            const { studentWindowLoading } = action;
            return { ...state, studentWindowLoading }; 

        case CREATE_SNACKBAR_ALERT:
            return { ...state, snackBarAlert: action.message }; 

        case CLEAR_SNACKBAR_ALERT:
                return { ...state, snackBarAlert: null }; 
        default:
            return state;
    }
}

export default utilReducer;