import { useEffect, useState, useRef, forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";

// material-ui
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Link,
  IconButton,
  InputAdornment,
  Typography,
  TextField,
  Alert,
  Container,
  CssBaseline,
} from "@mui/material";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import {
  strengthColor,
  strengthIndicator,
} from "../../../utils/password-strength";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { env } from "../../../config";
import ReCAPTCHA from "react-google-recaptcha";
import InputMask from "react-input-mask";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import axios from "axios";

export const IndianPhoneInput = forwardRef(({ value, onChange, name }, ref) => {
  return (
    <InputMask
      mask="+919999999999"
      alwaysShowMask
      value={value}
      onChange={onChange}
      ref={ref}
    >
      {(inputProps) => (
        <TextField
          fullWidth
          label="Phone Number"
          variant="outlined"
          name={name}
          {...inputProps}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PhoneAndroidIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    </InputMask>
  );
});

const AuthRegister = () => {
  const [level, setLevel] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [showOtpScreen, setShowOtpScreen] = useState(false);
  const [showAllSuccess, setShowAllSuccess] = useState(false);
  const recaptchaRef = useRef(null);
  const phoneInputRef = useRef();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = (value) => {
    const temp = strengthIndicator(value);
    setLevel(strengthColor(temp));
  };

  useEffect(() => {
    changePassword("");
  }, []);

  const changeCatchaValue = (value) => {
    formik.setFieldValue("captchaKey", value ? value : "");
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      mobile: "+91",
      email: "",
      password: "",
      captchaKey: "",
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().max(255).required("First Name is required"),
      last_name: Yup.string().max(255).required("Last Name is required"),
      mobile: Yup.string()
        .max(15)
        .matches(
          /^\+91\d{10}$/,
          "Mobile number must start with +91 and be 10 digits after that"
        )
        .required("Mobile is required"),
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
      password: Yup.string().max(255).required("Password is required"),
      captchaKey: Yup.string().required("Please complete the reCaptcha"),
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        setError("");
        const { data } = await axios.post(
          env.REACT_APP_API_HOST + "/api/auth/register",
          values
        );
        if (data?.status === "success") {
          setShowOtpScreen(true);
        } else {
          setError(data?.message ?? "Something went wrong");
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
        }
      } catch (err) {
        console.error(err);
        setStatus({ success: false });
        setErrors(err.message ?? "Something went wrong");
        setSubmitting(false);
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
      }
    },
  });

  const Otpformik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .matches(/^[0-9]{6}$/, "Enter a valid 6-digit OTP")
        .required("OTP is required"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await axios.post(
          env.REACT_APP_API_HOST + "/api/auth/verify-otp",
          {
            otp: values.otp,
            mobile: formik.values.mobile,
          }
        );
        if (response.data.status === "success") {
          setShowAllSuccess(true);
        } else {
          setError(error.message ?? "Something went wrong");
          console.error("OTP validation failed:", response.data.message);
        }
      } catch (error) {
        setError(error.response.data.message ?? "Something went wrong");
        console.error("Error during OTP validation:", error.message);
      }
    },
  });

  if (showAllSuccess) {
    return (
      <Box>
        <Alert severity="info" sx={{ mb: 2 }}>
          Congratulations! Your account has been successfully verified.
        </Alert>
        <Link component={RouterLink} to="/login">
          Click here to Login
        </Link>
      </Box>
    );
  }

  console.log("errors", formik.errors);

  return (
    <>
      {showOtpScreen ? (
        <Box>
          <Alert severity="info">
            Congratulations on successfully creating your account! An OTP has
            been sent to your registered mobile number. Please enter the OTP to
            verify your account.
          </Alert>
          {error && (
            <Box sx={{ mt: 3, mb: 1 }}>
              <Alert severity="error">{error}</Alert>
            </Box>
          )}
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                Enter OTP
              </Typography>
              <Box
                component="form"
                onSubmit={Otpformik.handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  fullWidth
                  id="otp"
                  name="otp"
                  label="OTP"
                  type="text"
                  variant="outlined"
                  margin="normal"
                  autoComplete="off"
                  value={Otpformik.values.otp}
                  onChange={Otpformik.handleChange}
                  onBlur={Otpformik.handleBlur}
                  error={Otpformik.touched.otp && Boolean(Otpformik.errors.otp)}
                  helperText={Otpformik.touched.otp && Otpformik.errors.otp}
                  sx={{ textAlign: "center", fontSize: "18px" }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={Otpformik.submitForm}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
      ) : (
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="first_name"
                name="first_name"
                label="First Name"
                autoComplete="off"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.first_name && Boolean(formik.errors.first_name)
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="last_name"
                name="last_name"
                label="Last Name"
                autoComplete="off"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
                helperText={formik.touched.last_name && formik.errors.last_name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email Address"
                autoComplete="off"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <IndianPhoneInput
                value={formik.values.mobile}
                onChange={formik.handleChange}
                ref={phoneInputRef}
                name="mobile"
                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                helperText={formik.touched.mobile && formik.errors.mobile}
              />
              {formik.touched.mobile && (
                <Typography sx={{ ml: 2, color: "red", fontSize: "12px" }}>
                  {formik.errors.mobile}
                </Typography>
              )}

              {/* <TextField
                fullWidth
                id="mobile"
                name="mobile"
                label="Phone Number"
                autoComplete="off"
                value={formik.values.mobile}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                helperText={formik.touched.mobile && formik.errors.mobile}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneAndroidIcon />
                    </InputAdornment>
                  ),
                  inputComponent: PhoneInputMask, // Assuming you use a phone number input mask
                }}
              /> */}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="password"
                name="password"
                label="Password"
                autoComplete="off"
                type={showPassword ? "text" : "password"}
                value={formik.values.password}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                  changePassword(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? (
                          <EyeOutlined />
                        ) : (
                          <EyeInvisibleOutlined />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
              <FormControl fullWidth sx={{ mt: 2 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Box
                      sx={{
                        bgcolor: level?.color,
                        width: 85,
                        height: 8,
                        borderRadius: "7px",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" fontSize="0.75rem">
                      {level?.label}
                    </Typography>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <ReCAPTCHA
                // ref={recaptchaRef}
                sitekey={env.GOOGLE_SITE_KEY}
                onChange={changeCatchaValue}
              />
              {Boolean(formik.errors.captchaKey) && (
                <Typography color="error">
                  {formik.errors.captchaKey}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                By Signing up, you agree to our &nbsp;
                <Link variant="subtitle2" component={RouterLink} to="#">
                  Terms of Service
                </Link>
                &nbsp; and &nbsp;
                <Link variant="subtitle2" component={RouterLink} to="#">
                  Privacy Policy
                </Link>
              </Typography>
            </Grid>
            {formik.errors.submit && (
              <Grid item xs={12}>
                <FormHelperText error>{formik.errors.submit}</FormHelperText>
              </Grid>
            )}
            {error && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                disableElevation
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
              >
                Create Account
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};

export default AuthRegister;
