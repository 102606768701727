import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Box, LinearProgress, Alert, FormControl, InputLabel, Typography } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as yup from 'yup';
import "./items.css";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { env } from '../../../../../config';
import { createCourseItemRequest, updateCourseItemRequest } from '../../../../../redux/sagas/requests/course';

export default function CategoryItem({ open, handleClose, type, onSuccess, updateItem }) {    
    const { token } = useSelector((state) => state.user);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { courseId, cateId } = useParams();

    function initState() {        
        setLoading(false);
        setErrorMessage("");
        formik.resetForm();        
    }

    const handleCloseThis = () => {
        initState();
        handleClose();
    }


    const formik = useFormik({
        initialValues: {
            name: updateItem?.name ?? "",         
        },
        validationSchema: yup.object({
            name: yup.string().required('Name is required'),            
        }),
        onSubmit: async (values) => {            
            if(updateItem){ //update
                await updateCourseItemRequest({
                    token,
                    input: {
                        uuid: updateItem.uuid,
                        name: values.name,                                          
                    }
                });
            }else {                
                await createCourseItemRequest({
                    token,
                    input: {
                        name: values.name,
                        course_id: courseId,                        
                        type: type,
                        categoryUuid: cateId
                    }
                });
            }            
            initState();
            onSuccess("Category has been successfully created");
        }
    });

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth="xs"
            onClose={handleCloseThis}
        >
            <DialogTitle sx={{ fontSize: "17px", fontWeight: "bold" }}>
                Category
                <IconButton
                    aria-label="close"
                    onClick={handleCloseThis}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                {errorMessage !== "" && <Alert severity='error'>{errorMessage}</Alert>}
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Category name"
                    type="text"
                    fullWidth
                    variant="standard"
                    placeholder='Physics'
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />             
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseThis} >Cancel</Button>
                <Button variant="contained" onClick={formik.handleSubmit} >Save</Button>
            </DialogActions>
        </Dialog>
    )
}
