import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setGoBackLink } from "../../../redux/reducers/util";
import LinearProgress from "@mui/material/LinearProgress";
import Layout from "../../layout/Layout";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  getStudentCourse,
  updateStudentProgress,
} from "../../../redux/sagas/requests/course";
import CheckIcon from "@mui/icons-material/Check";
import { useParams } from "react-router-dom";
import "./courseview.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TestCard } from "./components/testCard";
import YoutubePlayerComponent from "../../../components/YouTubePlayer";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function isVisited(visited, uuid) {
  if (visited.includes(uuid)) return true;
  return false;
}

function RenderCourseNav({ courseItems, onChange, current, visited }) {
  if (!courseItems) return false;
  let list = [];
  courseItems.map((item, index) => {
    if (item.type === "ITEM_CATEGORY") {
      const childItems = item.childItems;
      const childRender = RenderCourseNav({
        courseItems: childItems,
        onChange,
        current,
        visited,
      });
      list.push(
        <Accordion key={item.uuid} sx={{ mb: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{item.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>{childRender}</AccordionDetails>
        </Accordion>
      );
    } else {
      list.push(
        <Box
          onClick={() => onChange(item.uuid)}
          key={item.uuid}
          className={`course-navigationbtn ${current?.uuid === item.uuid ? "active" : ""
            }`}
        >
          {isVisited(visited, item.uuid) ? (
            <CheckIcon sx={{ fontSize: "15px", marginRight: "10px" }} />
          ) : (
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          )}
          {item.name}
        </Box>
      );
    }
  });
  return list;
}

function CourseNavigation({ courseItems, onChange, current, visited }) {
  if (!courseItems) return false;
  const renderCourseN = RenderCourseNav({
    courseItems: courseItems,
    onChange,
    current,
    visited,
  });
  return <MenuList sx={{ pl: 2 }}>{renderCourseN}</MenuList>;
}

export default function CourseView() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:1023px)");
  const [openCourseItemsMenu, setOpenCourseItemsMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const { token } = useSelector((state) => state.user);
  const { csid } = useParams("csid");
  const [course, setCourse] = useState(null);
  const [courseItems, setCourseItems] = useState([]);
  const [courseViewItems, setCourseViewItems] = useState([]);
  const [courseStudent, setCourseStudent] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visited, setVisited] = useState([]);

  useEffect(() => {
    dispatch(setGoBackLink(`/courses`));
    init();
  }, []);

  useEffect(() => {
    if (
      courseViewItems.length > 0 &&
      !visited.includes(courseViewItems[currentIndex].uuid)
    ) {
      const item_uuid = courseViewItems[currentIndex].uuid;
      setVisited([...visited, item_uuid]);
    }
  }, [currentIndex, courseViewItems]);

  useEffect(() => {
    if (courseViewItems.length > 0) {
      const progre = Math.round(
        (visited.length / courseViewItems.length) * 100
      );
      setProgress(progre);
      //save to db
      updateProgress({
        uuid: csid,
        token,
        progress: progre,
        progress_json: JSON.stringify({ visited: visited }),
      });
    }
  }, [visited, courseViewItems]);

  async function updateProgress(newProgress) {
    await updateStudentProgress(newProgress);
  }

  function filterItemsRecursive(items) {
    return items.reduce((acc, item) => {
      if (item.type !== "ITEM_CATEGORY") {
        acc.push(item);
      }

      if (item.childItems && item.childItems.length > 0) {
        const filteredChildItems = filterItemsRecursive(item.childItems);
        acc = acc.concat(filteredChildItems);
      }

      return acc;
    }, []);
  }

  async function init() {
    setLoading(true);
    const response = await getStudentCourse({
      token,
      uuid: csid,
    });
    setLoading(false);
    if (response?.success) {
      setCourseItems(response.courseItems);
      const nList = await filterItemsRecursive(response.courseItems);
      setCourseViewItems(nList);
      setCourseStudent(response.courseStudent);
      setCourse(response.course);
      setCurrentIndex(0);
      if (response.courseStudent?.progress_json) {
        const visitedHistory = JSON.parse(
          response.courseStudent?.progress_json
        );
        if (visitedHistory?.visited) {
          setVisited(visitedHistory?.visited);
        }
      }
    } else {
      alert("Something Went wrong");
    }
  }

  const handlePreviousAction = () => {
    if (currentIndex - 1 >= 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNextAction = () => {
    if (currentIndex + 1 < courseViewItems.length) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const updateCurrentIndexByUUid = (uuid) => {
    const cIndex = courseViewItems.findIndex((item) => item.uuid === uuid);
    setCurrentIndex(cIndex);
    setOpenCourseItemsMenu(false);
  };

  const courseItem = courseViewItems[currentIndex];
  return (
    <Layout>
      {loading && <LinearProgress />}
      <Dialog
        open={isMobile && openCourseItemsMenu}
        onClose={() => {
          setOpenCourseItemsMenu(false);
        }}
        fullWidth={true}
      >
        {courseItems.length > 0 && (
          <DialogContent>
            <CourseNavigation
              current={courseItem}
              onChange={updateCurrentIndexByUUid}
              courseItems={courseItems}
              visited={visited}
            />
          </DialogContent>
        )}
      </Dialog>
      {courseItems.length > 0 && (
        <Grid container sx={{ p: 5, pt: 1, pb: 2 }}>
          <Grid item xl={12} md={12} sm={12} xs={12}>
            <LinearProgressWithLabel value={progress} />
          </Grid>
        </Grid>
      )}
      {courseItems.length === 0 ? (
        <Box sx={{ p: 5, textAlign: "center" }}>
          <Alert
            sx={{ maxWidth: "440px", display: "inline-block" }}
            severity="info"
          >
            The course currently lacks content. Kindly reach out to the
            administration or course coordinator to request the addition of
            relevant materials.
          </Alert>
        </Box>
      ) : (
        <Grid container sx={{ pl: 5, pr: 5 }}>
          <Grid item xl={3} md={4} sm={12} xs={12} sx={{ pr: 1 }}>
            {isMobile ? (
              <Box sx={{ textAlign: "center" }}>
                <Button
                  sx={{ mb: 1 }}
                  onClick={() => {
                    setOpenCourseItemsMenu(true);
                  }}
                >
                  Course Items
                </Button>
              </Box>
            ) : (
              <Paper sx={{ height: "580px", p: 1 }}>
                <CourseNavigation
                  current={courseItem}
                  onChange={updateCurrentIndexByUUid}
                  courseItems={courseItems}
                  visited={visited}
                />
              </Paper>
            )}
          </Grid>
          <Grid item xl={9} md={8} sm={12} xs={12}>
            <Paper sx={{ p: 1 }}>
              <Typography variant="h4">{courseItem.name}</Typography>
              <Box
                sx={{
                  mt: 2,
                  mb: 2,
                  height: isMobile ? "420px" : "70vh",
                  overflow: "auto",
                  p: 1,
                }}
              >
                {courseItem.type === "ITEM_IMAGE" && (
                  <img src={courseItem.url} />
                )}
                {courseItem.type === "ITEM_TEXT" && (
                  <div
                    dangerouslySetInnerHTML={{ __html: courseItem.text }}
                  ></div>
                )}
                {courseItem.type === "ITEM_VIDEOS" && (
                  <video width={isMobile ? "100%" : "90%"} controls="controls" preload="metadata" controlsList="nodownload">
                    <source src={courseItem.url} type="video/mp4" />
                  </video>
                )}
                {courseItem?.type === "ITEM_PDF" && (
                  <iframe
                    className="courseitem-content-iframe"
                    src={courseItem.url}
                  />
                )}
                {courseItem?.type === "ITEM_YOUTUBE" && (
                  <YoutubePlayerComponent uniqueId={courseItem.uuid} width="100%" height={isMobile ? "250px" : "450px"} link={courseItem.url} />
                )}
                {courseItem.type === "ITEM_TEST" && <TestCard item={courseItem} />}
              </Box>
            </Paper>
            <footer style={{ position: "fixed", bottom: 15 }}>
              <Box sx={{ display: "flex" }}>
                <Button
                  disabled={currentIndex === 0}
                  onClick={handlePreviousAction}
                  variant="outlined"
                  startIcon={<ArrowLeftIcon />}
                  sx={{ mr: 2 }}
                >
                  Previous
                </Button>
                <Button
                  disabled={currentIndex === courseViewItems?.length - 1}
                  onClick={handleNextAction}
                  variant="outlined"
                  endIcon={<ArrowRightIcon />}
                >
                  Next
                </Button>
              </Box>
            </footer>
          </Grid>
        </Grid>
      )}
    </Layout>
  );
}
