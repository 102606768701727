import { call, put, select } from "redux-saga/effects";
import { setSelectedStudent, setStudents } from "../../reducers/student";
import { createAlert, fullScreenLoading } from "../../reducers/util";
import { createStudentRequest, deleteStudentRequest, getStudent, getStudentList, updateStudent, updateStudentPassword } from "../requests/student";
const getToken = (state) => state.user;


export function* handleCreateStudent(action) {
    yield put(fullScreenLoading(true));
    const { token } = yield select(getToken);        
    const response = yield call(createStudentRequest, { user: action.user, token: token });        
    if (response && response.status === "success") {
      const status = "success";
      let { message, result } = response;
      yield put(createAlert(status, message));
      const { navigate } = action;
      navigate('/students/view/' + btoa(result));
      yield put(fullScreenLoading(false));
    } else {
      const status = "error";
      let { message } = response;
      message = message == "" ? "Something went wrong!" : message;
      yield put(createAlert(status, message));
      yield put(fullScreenLoading(false));
    }
}

export function* handleListStudent(action) {    
  const { token } = yield select(getToken);        
  const response = yield call(getStudentList, { user: action.user, token: token });        
  if (response && response.status === "success") {            
    let { result } = response;    
    yield put(setStudents(result));
  } else {
    const status = "error";
    let { message } = response;
    message = message == "" ? "Something went wrong!" : message;
    yield put(createAlert(status, message));    
  }
}

export function* handleGetStudentById(action) {  
  const { token } = yield select(getToken);    
  const { userId } = action;
  const response = yield call(getStudent, { token: token, userId:atob(userId) });  
  yield put(fullScreenLoading(true));
  if (response && response.status === "success") {
    let { result } = response;    
    yield put(setSelectedStudent(result));    
    yield put(fullScreenLoading(false));
  } else {
    const status = "error";
    let { message } = response;
    message = message == "" ? "Something went wrong!" : message;
    yield put(createAlert(status, message));
     yield put(fullScreenLoading(false));
  }
}

export function* handleUpdateStudent(action){  
  try {
    const { token } = yield select(getToken);          
    const { user, navigate } = action;
    yield put(fullScreenLoading(true));
    const response = yield call(updateStudent, { token: token, user:user });  
    if (response && response.status === "success") {      
      const { message } = response;
      navigate('/students/view/' + btoa(user.userId));
      yield put(createAlert("success", message));
      yield put(fullScreenLoading(false));
    } else {
      const status = "error";
      let { message } = response;
      message = message == "" ? "Something went wrong!" : message;
      yield put(createAlert(status, message));
       yield put(fullScreenLoading(false));
    }    
  } catch (error) {
    const { message, status } = error;
    if (status === "failed") {
      yield put(createAlert('error', message));
    }
  }
}

export function* handleUpdateStudentPassword(action){  
  try {
    const { token } = yield select(getToken);          
    const { user, navigate } = action;
    yield put(fullScreenLoading(true));
    const response = yield call(updateStudentPassword, { token: token, user:user });  
    if (response && response.status === "success") {      
      const { message } = response;
      navigate('/students/view/' + btoa(user.userId));
      yield put(createAlert("success", message));
      yield put(fullScreenLoading(false));
    } else {
      const status = "error";
      let { message } = response;
      message = message == "" ? "Something went wrong!" : message;
      yield put(createAlert(status, message));
       yield put(fullScreenLoading(false));
    }    
  } catch (error) {
    const { message, status } = error;
    if (status === "failed") {
      yield put(createAlert("error", message));
      yield put(fullScreenLoading(false));
    }
  }
}

export function* handleDeleteStudent(action) {
  yield put(fullScreenLoading(true));
  const { token } = yield select(getToken);
  const response = yield call(deleteStudentRequest, { userId: action.userId, token: token });
  if (response && response.status === "success") {
    const status = "success";
    let { message, result } = response;
    yield put(createAlert(status, message));
    const { navigate } = action;
    navigate('/students');
    yield put(fullScreenLoading(false));
  } else {
    const status = "error";
    let { message } = response;
    message = message == "" ? "Something went wrong!" : message;
    yield put(createAlert(status, message));
    yield put(fullScreenLoading(false));
  }
}