import { Dialog, DialogContent, DialogTitle, TextField, Box, Alert, FormControl, InputLabel, Typography, Card, CardContent, Grid, Select, MenuItem, Autocomplete, InputAdornment, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import "./items.css";
import { useSelector } from 'react-redux';
import ClearIcon from '@mui/icons-material/Clear';
import axios from 'axios';
import { env } from '../../../../../config';
import SearchIcon from '@mui/icons-material/Search';
import ItemList from '../itemlist/ItemList';
import LibraryItem from '../LIbraryItem/LIbraryItem';
import { useParams } from 'react-router-dom';
import { createCourseItemRequest } from '../../../../../redux/sagas/requests/course';

export default function ImportLibrary({ open, handleClose, type, onSuccess, updateItem }) {
  const { token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const { courseId, cateId } = useParams();

  const handleClear = () => {
    setSearchQuery('');
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: token,
  };


  useEffect(() => {
    fetchLibraryCategories();
  }, []);

  const fetchLibraryCategories = async () => {
    try {
      setLoading(true)
      const { data } = await axios.get(`${env.REACT_APP_API_HOST + "/api/access/library/all-library-categories"}`, { headers: headers });
      if (data?.categories) {
        setCategories(data?.categories);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false)
    }
  }

  const handleCloseThis = () => {
    handleClose();
  }

  const handleCategoryChange = (event, newValue) => {
    setSelectedCategory(newValue);
  };

  const handleSearchKeyUp = (e) => {
    if (e.key === 'Enter') {
      searchContentLibrary();
    }
  }

  useEffect(() => {
    searchContentLibrary();
  }, [selectedCategory]);

  const searchContentLibrary = async () => {
    try {
      setLoading(true);
      let queryUrl = `${env.REACT_APP_API_HOST}/api/access/library/search-library?`;
      let params = [];

      if (selectedCategory?.id) {
        params.push(`category=${selectedCategory.id}`);
      }

      if (searchQuery) {
        params.push(`query=${searchQuery}`);
      }

      queryUrl += params.join('&');
      const { data } = await axios.get(queryUrl, { headers: headers });
      if (data?.success && data?.results) {
        setSearchResults(data?.results);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  const handleSaveItem = async (item) => {
    await createCourseItemRequest({
      token,
      input: {
        name: item.name,
        course_id: courseId,
        url: item.url,
        type: item.type,
        categoryUuid: cateId,
      },
    });
    onSuccess('Library item has been successfully imported');
  };


  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="xl"
      onClose={handleCloseThis}
    >
      <DialogTitle sx={{ fontSize: "17px", fontWeight: "bold" }}>
        Import from library
        <IconButton
          aria-label="close"
          onClick={handleCloseThis}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {errorMessage !== "" && <Alert severity='error'>{errorMessage}</Alert>}
        <Box>
          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                variant="outlined"
                autoComplete="off"
                placeholder="Type to search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyUp={handleSearchKeyUp}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear search"
                        onClick={handleClear}
                        edge="end"
                        disabled={!searchQuery}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} />
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                options={categories}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Select Category" variant="outlined" />}
                value={selectedCategory}
                onChange={handleCategoryChange}
              />
            </Grid>
          </Grid>
          {loading ? (
            <Box sx={{ mt: 8, display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>) : (
            <Box sx={{ marginTop: 2 }}>
              <Grid container spacing={2} >
                {searchResults?.map((result, index) => (
                  <Grid item xs={12} sm={6} lg={4} key={index}>
                    <LibraryItem item={result} handleSaveItem={handleSaveItem} />
                  </Grid>
                ))}
                {searchResults?.length === 0 && (
                  <Grid item xs={12} >
                    <Alert severity="info">No items have been found.</Alert>
                  </Grid>)}
              </Grid>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}
