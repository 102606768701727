/** Actions */
export const CREATE_TEST = "CREATE_TEST";
export const GET_TEST_BY_ID = "GET_TEST_BY_ID";
export const GET_TEST_LIST = "GET_TEST_LIST";
export const CREATE_QUESTION = "CREATE_QUESTION";
export const GET_QUESTIONS = "GET_QUESTIONS";
export const UNLINK_QUESTION = "UNLINK_QUESTION";
export const GET_QUESTION = "GET_QUESTION";
export const UPDATE_QUESTION = "UPDATE_QUESTION";
export const UPDATE_TEST = "UPDATE_TEST";
export const ADD_STUDENTS = "ADD_STUDENTS";
export const GET_STUDENTS_FOR_TEST = "GET_STUDENTS_FOR_TEST";
export const GET_UN_ASSIGNED_STUDENTS = "GET_UN_ASSIGNED_STUDENTS";
export const REMOVE_STUDENTS = "REMOVE_STUDENTS";
export const IMPORT_QUESTIONS = "IMPORT_QUESTIONS";
export const GET_IMPORTED_QUESTIONS = "GET_IMPORTED_QUESTIONS";
export const REMOVE_TEST_QUESTION = "REMOVE_TEST_QUESTION";

const SET_TEST = "SET_TEST";
const SET_TESTS = "SET_TESTS";
const SET_QUESTIONS = "SET_QUESTIONS";
const SET_QUESTION = "SET_QUESTION";
const SET_STUDENTS_LOADING = "SET_STUDENTS_LOADING";
const SET_STUDENTS_FOR_TEST = "SET_STUDENTS_FOR_TEST";
const SET_UN_ASSIGNED_STUDENTS = "SET_UN_ASSIGNED_STUDENTS";
const IMPORT_QUESTIONS_SUCCESS = "IMPORT_QUESTIONS_SUCCESS";
const IMPORT_QUESTIONS_FAILED = "IMPORT_QUESTIONS_FAILED";
const CLEAR_MESSAGES = "CLEAR_MESSAGES";
const SET_IMPORTED_QUESTIONS = "SET_IMPORTED_QUESTIONS";

const initialState = {                
    tests: [],
    test: undefined,
    questions: undefined,
    question: undefined,
    students: undefined,
    isStudentLoading: false,
    unAssignedStudents: undefined,
    importQuestionLoading: false,
    importQuestionSuccess: null,
    importQuestionFailed: null,
    testQuestions: []
};

export const createTest = (test, navigate) => ({
    type: CREATE_TEST,
    test, 
    navigate
});

export const getTestById = (uuid) => ({
    type: GET_TEST_BY_ID, 
    uuid
});

export const setTest = (test) => ({
    type: SET_TEST, 
    test
});

export const getTests = () => ({
    type: GET_TEST_LIST
});

export const setTests = (tests) => ({
    type: SET_TESTS,
    tests
});

export const createQuestion = (question, navigate) => ({
    type: CREATE_QUESTION,
    question,
    navigate
})

export const getQuestions = (testId) => ({
    type: GET_QUESTIONS,
    testId
});

export const setQuestions = (questions) => ({
    type: SET_QUESTIONS,
    questions
});

export const unLinkQuestion = (testId, questID) => ({
    type: UNLINK_QUESTION,
    testId,
    questID
});

export const getQuestion = (questionId) => ({
    type: GET_QUESTION,
    questionId
});

export const setQuestion = (question) => ({
    type: SET_QUESTION,
    question
});

export const updateQuestion = (question, navigate) => ({
    type: UPDATE_QUESTION,
    question,
    navigate
});

export const updateTest = (test, navigate) => ({
    type: UPDATE_TEST,
    test,
    navigate
});

export const addStudentsToTest = (students) => ({
    type: ADD_STUDENTS,
    students
});

export const getStudents = (testId) => ({
    type: GET_STUDENTS_FOR_TEST,
    testId
});

export const setStudentLoading = (status) => ({
    type:SET_STUDENTS_LOADING,
    status
});

export const setStudents = (students) => ({
    type: SET_STUDENTS_FOR_TEST,
    students
});

export const getUnAssignedStudents = (testId) => ({
    type: GET_UN_ASSIGNED_STUDENTS,
    testId
});

export const setUnAssignedStudents = (students) => ({
    type: SET_UN_ASSIGNED_STUDENTS,
    students
});

export const removeStudent = (uuid, testId) => ({
    type: REMOVE_STUDENTS,
    uuid,
    testId
});

export const importQuestions = (importInput) => ({
    type: IMPORT_QUESTIONS,
    importInput
});

export const importSuccess = (message) => ({
    type: IMPORT_QUESTIONS_SUCCESS,
    message
});

export const importFailed = (message) => ({
    type: IMPORT_QUESTIONS_FAILED,
    message
});

export const clearMessages = () => ({
    type: CLEAR_MESSAGES
});

export const getImportedQuestions = (test_id, section_id) => ({
    type: GET_IMPORTED_QUESTIONS,
    test_id, 
    section_id
});

export const setImportedQuestions = (questions) => ({
    type: SET_IMPORTED_QUESTIONS,
    questions
});

export const removeTestQuestion = (input) => ({
    type: REMOVE_TEST_QUESTION,
    input
});


const testReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_TEST_BY_ID:            
            return { ...state, test: undefined };
        case SET_TEST:
            const { test } = action;
            return { ...state, test };
        case SET_TESTS:
            const { tests } = action;
            return  { ...state, tests: tests };
        case GET_QUESTIONS:
            return  { ...state, questions: undefined };
        case SET_QUESTIONS:
            const { questions } = action;
            return  { ...state, questions: questions };
        case GET_QUESTION:
            return { ...state, question: undefined };
        case SET_QUESTION:
            return { ...state, question: action.question };
        case SET_STUDENTS_LOADING:
            return { ...state, isStudentLoading: action.status };
        case GET_STUDENTS_FOR_TEST:
            return { ...state, isStudentLoading: true, students:undefined};
        case SET_STUDENTS_FOR_TEST:
            return { ...state, isStudentLoading:false, students: action.students };        
        case GET_UN_ASSIGNED_STUDENTS:
            return { ...state, unAssignedStudents: undefined };
        case SET_UN_ASSIGNED_STUDENTS:
            return { ...state, unAssignedStudents: action.students };
        case IMPORT_QUESTIONS:
            return { ...state, importQuestionLoading: true, importQuestionSuccess: null, importQuestionFailed: null };
        case IMPORT_QUESTIONS_SUCCESS:
            return { ...state, importQuestionLoading: false, importQuestionSuccess: action.message  }
        case IMPORT_QUESTIONS_FAILED:
            return { ...state, importQuestionLoading: false, importQuestionFailed: action.message  }
        case CLEAR_MESSAGES:
            return { ...state, importQuestionSuccess: null, importQuestionFailed: null  };
        case GET_IMPORTED_QUESTIONS:
            return { ...state, importQuestionLoading: true, testQuestions: [] };
        case SET_IMPORTED_QUESTIONS:
            return { ...state, importQuestionLoading: false, testQuestions: action.questions };
        case REMOVE_TEST_QUESTION:
            return { ...state, importQuestionLoading: true, importQuestionSuccess: null, importQuestionFailed: null };
        default:
            return state;
    }
}

export default testReducer;