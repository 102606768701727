import Dashboard from "../studentpages/dashboard";
import Test from "../studentpages/test";
import StartTest from "../studentpages/test/startTest";
import Tests from "../studentpages/tests";
import TestResults from "../studentpages/results/TestResults";
import Courses from "../studentpages/courses";
import CourseView from "../studentpages/courses/details/CourseView";
import Practice from "../studentpages/practice";
import PracticeTestResults from "../studentpages/results/PracticeTestResults";

export const studentRoutes = [
  {
    path: '/dashboard',
    element: <Dashboard />
  },
  {
    path: '/tests',
    element: <Tests />
  },
  {
    path: '/test/:testId',
    element: <Test />
  },
  {
    path: '/practice',
    element: <Practice />
  },

  {
    path: '/starttest/:testId',
    element: <StartTest />
  },
  {
    path: '/test/:testId/results',
    element: <TestResults />
  },
  {
    path: '/practice/:testId/:practiceId/results',
    element: <PracticeTestResults />
  },
  {
    path: '/courses',
    element: <Courses />
  },
  {
    path: '/course/:csid',
    element: <CourseView />
  }
];