import { Alert, Box, IconButton, LinearProgress, ListItemIcon, ListItemText, Menu, MenuItem, Snackbar } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Person2Icon from '@mui/icons-material/Person2';
import { useState } from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';
import "./layout.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearAlert } from '../../redux/reducers/util';

const Layout = (props) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { studentBackLink, studentWindowLoading } = useSelector((state) => state.util);
    const { alerts } = useSelector((state) => state.util);    
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAlertClose = (id) => {        
        dispatch(clearAlert(id))        
    }

    let AlertList = [];
    
    for(var i=0; i<alerts.length; i++){
        const { alertType, alertMessage, alertId } = alerts[i];
        AlertList.push(<Snackbar key={alertId} open={true} autoHideDuration={1500} onClose={() => handleAlertClose(alertId) }>
                            <Alert severity={ alertType } sx={{ width: '100%' }}>{ alertMessage }</Alert>
                        </Snackbar>);
    }

    return (
        <Box>            
            <Box sx={{ position:"fixed", zIndex: "1", top: 0, width: "100vw", height: "58px", backgroundColor: '#EEEEEE', display: 'flex', justifyContent: 'space-between', color: 'black' }} >
                { AlertList }
                <IconButton component={ Link } to={studentBackLink} sx={{ m: 1, pl: 2, visibility: studentBackLink?'visible':'hidden' }} >
                    <ArrowBackIosIcon />
                </IconButton>
                <IconButton
                    sx={{ m: 1, pl: 2 }}
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <Person2Icon />
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <AccountCircleIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText className="custom-font">My Account</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <HelpIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Help</ListItemText>
                    </MenuItem>
                    <MenuItem component={Link} to="/logout" >
                        <ListItemIcon>
                            <LogoutIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Logout</ListItemText>
                    </MenuItem>
                </Menu>                
            </Box>
            <Box sx={{ mt:7, overflowY: 'auto', overflowX: 'hidden', maxHeight: "calc(100vh - 58px)", maxWidth: "100vw" }} >                
                {studentWindowLoading && <LinearProgress />}                                
                { props.children }                
            </Box>
        </Box>
    );
}
export default Layout;