import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function TestCard(props) {
  const colorArray = ["#FBC252", "#144272", "#FEA1BF", "#850000", "#D0B8A8", "#A555EC", "#6D67E4", "#FF7000", "#68B984", "#434242", "#009EFF", "#D23369", "#3B185F", "#975C8D", "#C539B4", "#7743DB", "#8D72E1", "#2146C7", "#BCCEF8", "#FF8787"];
  const [bgColor, setBgColor] = useState("#58287F")
  const navigate = useNavigate();
  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * colorArray.length);
    const randomColor = colorArray[randomIndex];
    setBgColor(randomColor);
  }, [])
  const handleCardNavigation = () => {
    navigate(`/test/${props.testId}?practice=1`)
  };
  return (
    <Card onClick={handleCardNavigation} className="test_component" sx={{ maxWidth: 500, margin: 'auto', marginTop: 2 }}>
      <Box className="top_design" sx={{ backgroundColor: bgColor }} ></Box>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {props.testName}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Assigned on: {props.assignedOn ? props.assignedOn : "NA"}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Last updated on: {props.lastUpdated ? props.lastUpdated : "NA"}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Maximum score: {props.maxScore ? props.maxScore : "NA"}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Minimum score: {props.minScore ? props.minScore : "NA"}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Negative marks: {props.negativeMarks ? props.negativeMarks : "NA"}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Maximum time: {props.maxTime ? props.maxTime : "NA"} Minutes
        </Typography>
      </CardContent>
    </Card>
  );
}

export default TestCard;
