import { call, put, select } from "redux-saga/effects";
import { createCategoryRequest, getCategoryRequest, listCategoryRequest, removeCategoryRequest, updateCategoryRequest } from "../requests/category";
import { categoryLoading, setCategories, setCategory } from "../../reducers/category";
import { createAlert } from "../../reducers/util";
const getToken = (state) => state.user;


export function* handleCreateCategory(action) {        
    const { category, handleCloseCreateCategory } = action;
    const { token } = yield select(getToken);        
    const response = yield call(createCategoryRequest, { category, token: token });        
    yield put(categoryLoading(false));
    handleCloseCreateCategory(true);
    if(response.status === 'success'){        
        yield put(createAlert("success", "Category has been created"));        
    }else{
        yield put(createAlert("error", "Something went wrong"));        
    }
}

export function* handleGetCategories(action) {        
    const { parent_id } = action;
    const { token } = yield select(getToken);        
    const response = yield call(listCategoryRequest, { parent_id, token: token });         
    if(response.status === 'success'){        
        yield put(setCategories(response.categories, response.parentCategories))        
    }else{
        yield put(createAlert("error", "Something went wrong"));        
    }
}

export function* handleGetCategory(action) {        
    const { uuid } = action;
    const { token } = yield select(getToken);            
    const response = yield call(getCategoryRequest, { uuid, token: token });             
    if(response.status === 'success'){                
        yield put(setCategory(response.result))        
    }else{
        yield put(createAlert("error", "Something went wrong"));        
    }
}

export function* handleUpdateCategory(action) {        
    const { category, handleCloseUpdateCategory } = action;
    const { token } = yield select(getToken);        
    const response = yield call(updateCategoryRequest, { category, token: token });        
    yield put(categoryLoading(false));
    handleCloseUpdateCategory(true);
    if(response.status === 'success'){        
        yield put(createAlert("success", "Category has been updated"));        
    }else{
        yield put(createAlert("error", "Something went wrong"));        
    }
}

export function* handleRemoveCategory(action) {        
    const { uuid, navigate, link } = action;
    const { token } = yield select(getToken);        
    const response = yield call(removeCategoryRequest, { uuid , token: token });        
    yield put(categoryLoading(false));    
    navigate(link);
    if(response.status === 'success'){        
        yield put(createAlert("success", "Category has been removed"));        
    }else{
        yield put(createAlert("error", "Something went wrong"));        
    }
}