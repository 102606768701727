import { Alert, Snackbar } from '@mui/material'
import React from 'react'

export default function AlertMessages({ error, success, clear }) {

    if (error !== "") {
        return (            
            <Snackbar
                open={error !== ""}
                autoHideDuration={6000}
                onClose={clear}
            >
                <Alert severity="error">{ error}</Alert>
            </Snackbar>
        )
    }

    if (success !== "") {
        return (            
            <Snackbar
                open={success !== ""}
                autoHideDuration={6000}
                onClose={clear}
            >
                <Alert severity="success">{ success}</Alert>
            </Snackbar>
        )
    }
}
