import { useEffect, useState, useRef, forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";

// material-ui
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Link,
  IconButton,
  InputAdornment,
  Typography,
  TextField,
  Alert,
  Container,
  CssBaseline,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import { env } from "../../../config";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import axios from "axios";

const AuthVerify = () => {
  const session = useSelector((state) => state.user);
  const [error, setError] = useState("");
  const [showOtpScreen, setShowOtpScreen] = useState(false);
  const [showAllSuccess, setShowAllSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const Otpformik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .matches(/^[0-9]{6}$/, "Enter a valid 6-digit OTP")
        .required("OTP is required"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await axios.post(
          env.REACT_APP_API_HOST + "/api/auth/verify-otp",
          {
            otp: values.otp,
            mobile: session?.mobile,
          }
        );
        if (response.data.status === "success") {
          localStorage.clear();
          setShowAllSuccess(true);
        } else {
          setError(error.message ?? "Something went wrong");
          console.error("OTP validation failed:", response.data.message);
        }
      } catch (error) {
        setError(error.response.data.message ?? "Something went wrong");
        console.error("Error during OTP validation:", error.message);
      }
    },
  });

  const sendOTP = async () => {
    try {
      setLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: session.token,
      };
      const { data } = await axios.post(
        env.REACT_APP_API_HOST + "/api/auth/send-otp-mobile",
        {
          mobile: session.mobile,
        },
        { headers: headers }
      );
      if (data.success) {
        setShowOtpScreen(true);
      } else {
        alert("Something went wrong");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  if (!session?.token) {
    return <Navigate to="/login" />;
  }

  if (showAllSuccess) {
    return (
      <Box>
        <Alert severity="info" sx={{ mb: 2 }}>
          Congratulations! Your account has been successfully verified.
        </Alert>
        <Link component={RouterLink} to="/logout">
          Click here to Login
        </Link>
      </Box>
    );
  }

  if (showOtpScreen) {
    return (
      <Box>
        <Alert severity="info">
          An OTP has been sent to your registered mobile number. Please enter
          the OTP to verify your account.
        </Alert>
        {error && (
          <Box sx={{ mt: 3, mb: 1 }}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Enter OTP
            </Typography>
            <Box
              component="form"
              onSubmit={Otpformik.handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                fullWidth
                id="otp"
                name="otp"
                label="OTP"
                type="text"
                variant="outlined"
                margin="normal"
                autoComplete="off"
                value={Otpformik.values.otp}
                onChange={Otpformik.handleChange}
                onBlur={Otpformik.handleBlur}
                error={Otpformik.touched.otp && Boolean(Otpformik.errors.otp)}
                helperText={Otpformik.touched.otp && Otpformik.errors.otp}
                sx={{ textAlign: "center", fontSize: "18px" }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={Otpformik.submitForm}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    );
  }
  return (
    <Box>
      <Alert severity="info">
        We will send an OTP to your registered mobile number. Click the button
        below to initiate the OTP delivery.
      </Alert>
      {error && (
        <Box sx={{ mt: 3, mb: 1 }}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box noValidate sx={{ mt: 1 }}>
            <TextField
              fullWidth
              label="Mobile Number"
              type="text"
              variant="outlined"
              value={session?.mobile}
              InputProps={{
                readOnly: true,
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => {
                if (!loading) {
                  sendOTP();
                }
              }}
              startIcon={
                loading && <CircularProgress sx={{ color: "#fff" }} size={14} />
              }
            >
              Send OTP
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AuthVerify;
