const localEnv = {
    REACT_APP_API_HOST: 'http://localhost:4800',
    REACT_APP_PERMISSIONS: {
        '0': 'Superadmin',
        '10': 'Admin',
        '20': 'Top Faculty',
        '30': 'Faculty',
        '100': 'Student',
    },
    GOOGLE_SITE_KEY: "6LdKBm0pAAAAABJ3D9ETqi3dUwA4NTMU4yOxgdtk",    
};

const productionEnv = {
    REACT_APP_API_HOST: 'https://api.xmbell.com',
    REACT_APP_PERMISSIONS: {
        '0': 'Superadmin',
        '10': 'Admin',
        '20': 'Top Faculty',
        '30': 'Faculty',
        '100': 'Student',
    },
    GOOGLE_SITE_KEY: "6LdKBm0pAAAAABJ3D9ETqi3dUwA4NTMU4yOxgdtk",
};

let environment = {};

if (process.env.NODE_ENV === 'production') {
    environment = productionEnv;
} else {
    environment = localEnv;
}

module.exports = {
    env: environment,
};
