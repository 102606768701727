import DashboardDefault from "../pages/dashboard";
import Students from "../pages/students";
import AddNewStudent from "../pages/students/AddNewStudent";
import Users from "../pages/users";
import AddNewUser from "../pages/users/AddNewUser";
import EditUser from "../pages/users/EditUser";
import UserDetails from "../pages/users/UserDetails";
import StudentDetails from "../pages/students/StudentDetails";
import EditStudent from "../pages/students/EditStudent";
import Tests from "../pages/tests";
import NewTest from "../pages/tests/NewTest";
import EditTest from "../pages/tests/EditTest";
import ViewTest from "../pages/tests/ViewTest";
import Categories from "../pages/categories";
import QuestionBank from "../pages/questions";
import CreateQuestion from "../pages/questions/forms/CreateQuestion";
import QuestionView from "../pages/questions/QuestionView";
import SectionQuestions from "../pages/tests/components/SectionQuestions";
import { Courses } from "../pages/courses";
import CourseView from "../pages/courses/view/CourseView";
import Packages from "../pages/packages";
import { Library } from "../pages/library";

export const adminRoutes = (role) => {
  let routes = [];
  if (role <= 30) {
    routes = [
      ...routes,
      {
        path: "/dashboard",
        element: <DashboardDefault />,
      },
      {
        path: "/students",
        element: <Students />,
      },
      {
        path: "/students/new",
        element: <AddNewStudent />,
      },
      {
        path: "/students/view/:userId",
        element: <StudentDetails />,
      },
      {
        path: "/students/edit/:userId",
        element: <EditStudent />,
      },
      {
        path: "/tests",
        element: <Tests />,
      },
      {
        path: "/tests/new",
        element: <NewTest />,
      },
      {
        path: "/tests/edit/:testId",
        element: <EditTest />,
      },
      {
        path: "/tests/view/:testId",
        element: <ViewTest />,
      },
      {
        path: "/categories",
        element: <Categories />,
      },
      {
        path: "/categories/:cateId",
        element: <Categories />,
      },
      {
        path: "/questions",
        element: <QuestionBank />,
      },
      {
        path: "/questions/create",
        element: <CreateQuestion />,
      },
      {
        path: "/questions/edit/:questionId",
        element: <CreateQuestion />,
      },
      {
        path: "/questions/view/:questionId",
        element: <QuestionView />,
      },
      {
        path: "/tests/view/:testId/:sectionId/questions",
        element: <SectionQuestions />,
      },
      {
        path: "/courses",
        element: <Courses />,
      },
      {
        path: "/courses/view/:courseId/:cateId?",
        element: <CourseView />,
      },
      {
        path: "/library/:cateId?",
        element: <Library />,
      },
    ];
  }
  if (role <= 10) {
    routes = [
      ...routes,
      {
        path: "/users",
        element: <Users />,
      },
      {
        path: "/users/new",
        element: <AddNewUser />,
      },
      {
        path: "/users/view/:userId",
        element: <UserDetails />,
      },
      {
        path: "/users/edit/:userId",
        element: <EditUser />,
      },
      {
        path: "/packages",
        element: <Packages />,
      },
    ];
  }  
  return routes;
};

export const adminRoutess = [];
