import axios from "axios";
import { env } from "../../../config";

export function createCategoryRequest(request) {            
    const { category, token } = request;    
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/category/create', category, { headers: headers })
            .then(function (response) {
                if(response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                        result: response.data.result
                    });
                }else{
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });    
                }                
            })
            .catch(function (error) {
                reject({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function listCategoryRequest(request) {            
    const { parent_id, token } = request;    
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/category/list', { parent_id: parent_id }, { headers: headers })
            .then(function (response) {
                if(response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                        categories: response.data.categories,
                        parentCategories: response.data.parentCategories,
                    });
                }else{
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });    
                }                
            })
            .catch(function (error) {
                reject({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function getCategoryRequest(request) {            
    const { uuid, token } = request;    
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/category/get', { uuid: uuid }, { headers: headers })
            .then(function (response) {
                if(response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                        result: response.data.result
                    });
                }else{
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });    
                }                
            })
            .catch(function (error) {
                reject({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function updateCategoryRequest(request) {            
    const { category, token } = request;    
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/category/update', category, { headers: headers })
            .then(function (response) {
                if(response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                        result: response.data.result
                    });
                }else{
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });    
                }                
            })
            .catch(function (error) {
                reject({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function removeCategoryRequest(request) {            
    const { uuid, token } = request;    
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/category/remove', { uuid: uuid }, { headers: headers })
            .then(function (response) {
                if(response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,                        
                    });
                }else{
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });    
                }                
            })
            .catch(function (error) {
                reject({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}