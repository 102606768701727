import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import Layout from "../layout/Layout";
import "./style.css";
import ClassIcon from '@mui/icons-material/Class';
import { AccountCircleRounded, AssignmentTurnedIn, ContactSupport, ExitToApp, Forum, Help, LibraryBooks, TrendingUp } from "@mui/icons-material";
import SchoolIcon from '@mui/icons-material/School';
import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setGoBackLink } from "../../redux/reducers/util";
import { useEffect } from "react";


const ComingSoon = ({ open, handleClose }) => {
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Important Message</DialogTitle>
        <DialogContent>
          <p>We apologize for the inconvenience, but this feature is currently under development and will be available soon. Please check back later for updates.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const Dashboard = () => {
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => {
    setShowAlert(false);
  }
  const handleAlertOpen = () => {
    setShowAlert(true);
  }
  useEffect(() => {
    dispatch(setGoBackLink(null));
  }, []);

  return (
    <Layout>
      <ComingSoon open={showAlert} handleClose={handleClose} />
      <Grid container sx={{ p: 1, pt: 5 }}>
        <Grid sx={{ textAlign: 'center', mb: 5 }} item xs={6} sm={4} md={3} xl={3} >
          <Box component={Link} to="/courses" className="dashboard_item" sx={{ backgroundColor: "#B3005E", color: "#FFF" }} >
            <ClassIcon className="icon" />
            <p>Courses</p>
          </Box>
        </Grid>
        <Grid sx={{ textAlign: 'center', mb: 5 }} item xs={6} sm={4} md={3} xl={3} >
          <Box component={Link} to="/tests" className="dashboard_item" sx={{ backgroundColor: "#393053", color: "#FFF" }} >
            <AssignmentTurnedIn className="icon" />
            <p>Tests</p>
          </Box>
        </Grid>
        <Grid sx={{ textAlign: 'center', mb: 5 }} item xs={6} sm={4} md={3} xl={3} >
          <Box component={Link} to="/practice" className="dashboard_item" sx={{ backgroundColor: "#FF885B", color: "#FFF" }} >
            <SchoolIcon className="icon" />
            <p>Practice</p>
          </Box>
        </Grid>
        <Grid sx={{ textAlign: 'center', mb: 5 }} item xs={6} sm={4} md={3} xl={3} >
          <Box onClick={handleAlertOpen} className="dashboard_item" sx={{ backgroundColor: "#A459D1", color: "#FFF" }} >
            <TrendingUp className="icon" />
            <p>Progress</p>
          </Box>
        </Grid>
        <Grid onClick={handleAlertOpen} sx={{ textAlign: 'center', mb: 5 }} item xs={6} sm={4} md={3} xl={3} >
          <Box className="dashboard_item" sx={{ backgroundColor: "#3F979B", color: "#FFF" }} >
            <LibraryBooks className="icon" />
            <p>Resources</p>
          </Box>
        </Grid>
        <Grid onClick={handleAlertOpen} sx={{ textAlign: 'center', mb: 5 }} item xs={6} sm={4} md={3} xl={3} >
          <Box className="dashboard_item" sx={{ backgroundColor: "#CD5888", color: "#FFF" }} >
            <Forum className="icon" />
            <p>Discussion</p>
          </Box>
        </Grid>
        <Grid onClick={handleAlertOpen} sx={{ textAlign: 'center', mb: 5 }} item xs={6} sm={4} md={3} xl={3} >
          <Box className="dashboard_item" sx={{ backgroundColor: "#B99B6B", color: "#FFF" }} >
            <AccountCircleRounded className="icon" />
            <p>Profile</p>
          </Box>
        </Grid>
        <Grid onClick={handleAlertOpen} sx={{ textAlign: 'center', mb: 5 }} item xs={6} sm={4} md={3} xl={3} >
          <Box className="dashboard_item" sx={{ backgroundColor: "#00425A", color: "#FFF" }} >
            <Help className="icon" />
            <p>Help</p>
          </Box>
        </Grid>
        <Grid onClick={handleAlertOpen} sx={{ textAlign: 'center', mb: 5 }} item xs={6} sm={4} md={3} xl={3} >
          <Box className="dashboard_item" sx={{ backgroundColor: "#F55050", color: "#FFF" }} >
            <ContactSupport className="icon" />
            <p>Contact Us</p>
          </Box>
        </Grid>
        <Grid sx={{ textAlign: 'center', mb: 5 }} item xs={6} sm={4} md={3} xl={3} >
          <Box component={Link} to="/logout" className="dashboard_item" sx={{ backgroundColor: "#205295", color: "#FFF" }} >
            <ExitToApp className="icon" />
            <p>Logout</p>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};
export default Dashboard;