import React, { useState, useEffect } from "react";
import { Link as RouterLink, Navigate, useNavigate } from "react-router-dom";

import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  LinearProgress,
  Alert,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import AnimateButton from "../../../components/@extended/AnimateButton";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../../redux/reducers/user";
import { useFormik } from "formik";
import * as yup from "yup";

const AuthLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, status, message, token } = useSelector(
    (state) => state.user
  );

  const [checked, setChecked] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validationSchema = yup.object({
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    password: Yup.string().max(255).required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      submit: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        setStatus({ success: true });
        dispatch(loginUser(values.email, values.password, navigate));
      } catch (err) {
        setStatus({ success: false });
        setErrors({ submit: err.message });
      }
    },
  });

  return (
    <>
      {token && <Navigate to="/dashboard" replace={true} />}
      <form onSubmit={formik.handleSubmit}>
        {status === "failed" && (
          <Grid item xs={12}>
            <Box sx={{ pb: "20px" }}>
              <Alert severity="error">{message}</Alert>
            </Box>
          </Grid>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <InputLabel htmlFor="email-login">Email Address</InputLabel>
              <OutlinedInput
                id="email-login"
                type="email"
                value={formik.values.email}
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="Enter email address"
                fullWidth
                error={Boolean(formik.touched.email && formik.errors.email)}
              />
              {formik.touched.email && formik.errors.email && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-email-login"
                >
                  {formik.errors.email}
                </FormHelperText>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <InputLabel htmlFor="password-login">Password</InputLabel>
              <OutlinedInput
                fullWidth
                error={Boolean(
                  formik.touched.password && formik.errors.password
                )}
                id="-password-login"
                type={showPassword ? "text" : "password"}
                value={formik.values.password}
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? (
                        <EyeOutlined />
                      ) : (
                        <EyeInvisibleOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="Enter password"
              />
              {formik.touched.password && formik.errors.password && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-password-login"
                >
                  {formik.errors.password}
                </FormHelperText>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} sx={{ mt: -1 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={(event) => setChecked(event.target.checked)}
                    name="checked"
                    color="primary"
                    size="small"
                  />
                }
                label={<Typography variant="h6">Keep me sign in</Typography>}
              />
              <Link
                variant="h6"
                component={RouterLink}
                to="/forgot-password"
                color="text.primary"
              >
                Forgot Password?
              </Link>
            </Stack>
          </Grid>
          {formik.errors.submit && (
            <Grid item xs={12}>
              <FormHelperText error>{formik.errors.submit}</FormHelperText>
            </Grid>
          )}
          <Grid item xs={12}>
            <AnimateButton>
              <Button
                disabled={loading}
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
              >
                Login
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </form>
    </>
  );

  // return (
  //     <>
  //         {token && (
  //             <Navigate to="/dashboard" replace={true} />
  //         )}
  //         <Formik
  //             initialValues={{
  //                 email: '',
  //                 password: '',
  //                 submit: null
  //             }}
  //             validationSchema={Yup.object().shape({
  //                 email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
  //                 password: Yup.string().max(255).required('Password is required')
  //             })}
  //             onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
  //                 console.log("Error");
  //                 try {
  //                     // setStatus({ success: true });
  //                     dispatch(loginUser(values.email, values.password, navigate));
  //                 } catch (err) {
  //                     // setStatus({ success: false });
  //                     // setErrors({ submit: err.message });
  //                 }
  //             }}
  //         >
  //             {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
  //                 <form onSubmit={handleSubmit}>
  //                     {
  //                         status === "failed" && (
  //                             <Grid item xs={12}>
  //                                 <Box sx={{ pb: "20px" }}>
  //                                     <Alert severity="error">{message}</Alert>
  //                                 </Box>
  //                             </Grid>
  //                         )
  //                     }
  //                     <Grid container spacing={3}>
  //                         <Grid item xs={12}>
  //                             <Stack spacing={1}>
  //                                 <InputLabel htmlFor="email-login">Email Address</InputLabel>
  //                                 <OutlinedInput
  //                                     disabled={loading}
  //                                     id="email-login"
  //                                     type="email"
  //                                     value={values.email}
  //                                     name="email"
  //                                     onBlur={handleBlur}
  //                                     onChange={handleChange}
  //                                     placeholder="Enter email address"
  //                                     fullWidth
  //                                     error={Boolean(touched.email && errors.email)}
  //                                 />
  //                                 {touched.email && errors.email && (
  //                                     <FormHelperText error id="standard-weight-helper-text-email-login">
  //                                         {errors.email}
  //                                     </FormHelperText>
  //                                 )}
  //                             </Stack>
  //                         </Grid>
  //                         <Grid item xs={12}>
  //                             <Stack spacing={1}>
  //                                 <InputLabel htmlFor="password-login">Password</InputLabel>
  //                                 <OutlinedInput
  //                                     disabled={loading}
  //                                     fullWidth
  //                                     error={Boolean(touched.password && errors.password)}
  //                                     id="-password-login"
  //                                     type={showPassword ? 'text' : 'password'}
  //                                     value={values.password}
  //                                     name="password"
  //                                     onBlur={handleBlur}
  //                                     onChange={handleChange}
  //                                     endAdornment={
  //                                         <InputAdornment position="end">
  //                                             <IconButton
  //                                                 disabled={loading}
  //                                                 aria-label="toggle password visibility"
  //                                                 onClick={handleClickShowPassword}
  //                                                 onMouseDown={handleMouseDownPassword}
  //                                                 edge="end"
  //                                                 size="large"
  //                                             >
  //                                                 {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
  //                                             </IconButton>
  //                                         </InputAdornment>
  //                                     }
  //                                     placeholder="Enter password"
  //                                 />
  //                                 {touched.password && errors.password && (
  //                                     <FormHelperText error id="standard-weight-helper-text-password-login">
  //                                         {errors.password}
  //                                     </FormHelperText>
  //                                 )}
  //                             </Stack>
  //                         </Grid>

  //                         <Grid item xs={12} sx={{ mt: -1 }}>
  //                             <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
  //                                 <FormControlLabel
  //                                     control={
  //                                         <Checkbox
  //                                             disabled={loading}
  //                                             checked={checked}
  //                                             onChange={(event) => setChecked(event.target.checked)}
  //                                             name="checked"
  //                                             color="primary"
  //                                             size="small"
  //                                         />
  //                                     }
  //                                     label={<Typography variant="h6">Keep me sign in</Typography>}
  //                                 />
  //                                 {/* <Link variant="h6" component={RouterLink} to="" color="text.primary">
  //                                     Forgot Password?
  //                                 </Link> */}
  //                             </Stack>
  //                         </Grid>
  //                         {errors.submit && (
  //                             <Grid item xs={12}>
  //                                 <FormHelperText error>{errors.submit}</FormHelperText>
  //                             </Grid>
  //                         )}
  //                         <Grid item xs={12}>
  //                             <AnimateButton>
  //                                 <Button
  //                                     disabled={loading}
  //                                     disableElevation
  //                                     fullWidth
  //                                     size="large"
  //                                     type="submit"
  //                                     variant="contained"
  //                                     color="primary"
  //                                 >
  //                                     Login
  //                                 </Button>
  //                             </AnimateButton>
  //                         </Grid>

  //                         {/* <Grid item xs={12}>
  //                             <Divider>
  //                                 <Typography variant="caption"> Login with</Typography>
  //                             </Divider>
  //                         </Grid>
  //                         <Grid item xs={12}>
  //                             <FirebaseSocial />
  //                         </Grid> */}
  //                     </Grid>

  //                 </form>
  //             )}
  //         </Formik>
  //     </>
  // );
};

export default AuthLogin;
