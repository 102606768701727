/** Actions */
export const LOGIN_USER = "LOGIN_USER";
export const CREATE_USER = "CREATE_USER";
export const GET_USER_LIST = "GET_USER_LIST";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_PASSWORD_ADMIN = "UPDATE_PASSWORD_ADMIN";
export const DELETE_USER = "DELETE_USER";

const UPDATE_STATUS = "UPDATE_STATUS";
const SET_USER = "SET_USER";
const SET_USER_LIST = "SET_USER_LIST";
const SET_SELECTED_USER = "SET_SELECTED_USER";

const initialState = {
  token: localStorage.getItem(btoa("token"))
    ? atob(localStorage.getItem(btoa("token")))
    : null,
  name: localStorage.getItem(btoa("name"))
    ? atob(localStorage.getItem(btoa("name")))
    : null,
  role: localStorage.getItem(btoa("role"))
    ? atob(localStorage.getItem(btoa("role")))
    : null,
  id: localStorage.getItem(btoa("id"))
    ? atob(localStorage.getItem(btoa("id")))
    : null,
  is_mobile_verified: localStorage.getItem(btoa("is_mobile_verified"))
    ? atob(localStorage.getItem(btoa("is_mobile_verified")))
    : null,
  mobile: localStorage.getItem(btoa("mobile"))
    ? atob(localStorage.getItem(btoa("mobile")))
    : null,
  loading: false,
  status: undefined,
  message: "",
  usersList: [],
  selectedUser: undefined,
};

export const loginUser = (username, password) => ({
  type: LOGIN_USER,
  username,
  password,
});

export const setUser = (user) => ({
  type: SET_USER,
  token: user.token,
  name: user.name,
  role: user.role,
  is_mobile_verified: user.is_mobile_verified,
  mobile: user.mobile,
  id: user.id,
});

export const updateStatus = (status, message) => ({
  type: UPDATE_STATUS,
  status,
  message,
});

export const createUser = (user, navigate) => ({
  type: CREATE_USER,
  user,
  navigate,
});

export const getUserList = () => ({
  type: GET_USER_LIST,
});

export const setUserList = (userList) => ({
  type: SET_USER_LIST,
  userList,
});

export const getUserById = (userId) => ({
  type: GET_USER_BY_ID,
  userId,
});

export const setSelectedUser = (user) => ({
  type: SET_SELECTED_USER,
  selectedUser: user,
});

export const updateUser = (user, navigate) => ({
  type: UPDATE_USER,
  user,
  navigate,
});

export const updatePassword = (user, navigate) => ({
  type: UPDATE_PASSWORD_ADMIN,
  user,
  navigate,
});

export const deleteUser = (userId, navigate) => ({
  type: DELETE_USER,
  userId,
  navigate,
});

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, status: undefined, message: "" };
    case SET_USER:
      const { token, name, role, id, is_mobile_verified, mobile } = action;
      return {
        ...state,
        token,
        name,
        role,
        id,
        loading: false,
        is_mobile_verified,
        mobile,
      };
    case UPDATE_STATUS:
      const { status, message } = action;
      return { ...state, status, message, loading: false };
    case SET_USER_LIST:
      const { userList } = action;
      return { ...state, userList };
    case SET_SELECTED_USER:
      const { selectedUser } = action;
      return { ...state, selectedUser };
    default:
      return state;
  }
};

export default userReducer;
