import React, { useEffect, useState } from "react";
import MainLayout from "../../../layout/MainLayout";
import MainCard from "../../../components/MainCard";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ArticleIcon from "@mui/icons-material/Article";
import "./courseview.css";
import ImageItem from "./components/courseitems/ImageItem";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import TextItem from "./components/courseitems/TextItem";
import PdfItem from "./components/courseitems/PdfItem";
import VideoItem from "./components/courseitems/VideoItem";
import YouTubeIcon from "@mui/icons-material/YouTube";
import YoutubeItem from "./components/courseitems/YoutubeItem";
import ItemList from "./components/itemlist/ItemList";
import CategoryIcon from "@mui/icons-material/Category";
import {
  ITEM_IMAGE,
  ITEM_PDF,
  ITEM_TEXT,
  ITEM_VIDEOS,
  ITEM_YOUTUBE,
  ITEM_CATEGORY,
  ITEM_TEST,
  ITEM_LIBRARY,
} from "../constants";
import { Link, useParams } from "react-router-dom";
import {
  courseItemPositionRequest,
  getCourseItemCategoriesRequest,
  getCourseItemsRequest,
  updateCourseItemRequest,
} from "../../../redux/sagas/requests/course";
import { useSelector } from "react-redux";
import AssignStudents from "./components/AssignStudents";
import CategoryItem from "./components/courseitems/CategoryItem";
import QuizIcon from "@mui/icons-material/Quiz";
import TestItem from "./components/courseitems/TestItem";
import YoutubePlayerComponent from "../../../components/YouTubePlayer";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ImportLibrary from "./components/courseitems/ImportLibrary";

export default function CourseView() {
  const [currentTab, setCurrentTab] = useState(0);
  const [openItem, setOpenItem] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [courseItems, setCourseItems] = useState([]);
  const { token } = useSelector((state) => state.user);
  const [listLoading, setListLoading] = useState(false);
  const [updateItem, setUpdateItem] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const [categories, setCategories] = useState([]);

  const { courseId, cateId } = useParams();

  useEffect(() => {
    updateCourseItems();
    const courseMenuTab = localStorage.getItem("courseMenuTab");
    if (courseMenuTab !== undefined) {
      setCurrentTab(Number(courseMenuTab));
    }
  }, [courseId, cateId]);

  async function updateCourseItems() {
    setListLoading(true);
    setCategories([]);
    const items = await getCourseItemsRequest({
      token,
      courseId,
      cateId,
    });

    if (cateId) {
      const response = await getCourseItemCategoriesRequest({
        token,
        uuid: cateId,
      });
      if (response.success && response.categories?.length > 0) {
        setCategories(response.categories.reverse());
      }
    }

    setCourseItems(items.courseitems);
    setListLoading(false);
  }

  function handleSnackbarClose() {
    setSuccessMessage("");
  }

  function handleTabChange(_, index) {
    setCurrentTab(index);
    localStorage.setItem("courseMenuTab", index);
  }
  const handleSetOpenItem = (item) => {
    setUpdateItem(null);
    setOpenItem(item);
  };
  const handleItemClose = () => {
    setOpenItem("");
  };
  const successCallback = (message) => {
    setOpenItem("");
    setSuccessMessage(message);
    updateCourseItems();
  };

  const handleEditContent = (item) => {
    setUpdateItem(item);
    setOpenItem(item.type);
  };

  const handleSetDeleteItem = (item) => {
    setDeleteItem(item);
  };

  const handleDeleteDialogClose = () => {
    setDeleteItem(null);
  };

  const handleDeleteConfirm = async () => {
    setListLoading(true);
    await updateCourseItemRequest({
      token,
      input: {
        uuid: deleteItem.uuid,
        active: false,
      },
    });
    updateCourseItems();
    setDeleteItem(null);
  };

  const handleUpdatePostion = async (uuid, position) => {
    setListLoading(true);
    await courseItemPositionRequest({
      token,
      input: {
        uuid,
        position,
      },
    });
    updateCourseItems();
  };

  return (
    <MainLayout>
      <MainCard title="Courses">
        <Snackbar
          open={successMessage !== ""}
          onClose={handleSnackbarClose}
          autoHideDuration={3500}
        >
          <Alert servity="success">{successMessage}</Alert>
        </Snackbar>
        {deleteItem !== null && (
          <Dialog
            open={deleteItem !== null}
            onClose={handleDeleteDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" sx={{ fontWeight: "bold" }}>
              {"Confirm Course Item Deletion"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`Are you sure you want to remove the course item named '${deleteItem?.name ?? ""
                  }'?`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteDialogClose}>No</Button>
              <Button variant="contained" onClick={handleDeleteConfirm}>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {openItem === ITEM_CATEGORY && (
          <CategoryItem
            type={ITEM_CATEGORY}
            open={openItem === ITEM_CATEGORY}
            handleClose={handleItemClose}
            onSuccess={successCallback}
            updateItem={updateItem}
          />
        )}
        {openItem === ITEM_IMAGE && (
          <ImageItem
            type={ITEM_IMAGE}
            open={openItem === ITEM_IMAGE}
            handleClose={handleItemClose}
            onSuccess={successCallback}
            updateItem={updateItem}
          />
        )}
        {openItem === ITEM_TEXT && (
          <TextItem
            type={ITEM_TEXT}
            open={openItem === ITEM_TEXT}
            handleClose={handleItemClose}
            onSuccess={successCallback}
            updateItem={updateItem}
          />
        )}
        {openItem === ITEM_PDF && (
          <PdfItem
            type={ITEM_PDF}
            open={openItem === ITEM_PDF}
            handleClose={handleItemClose}
            onSuccess={successCallback}
            updateItem={updateItem}
          />
        )}
        {openItem === ITEM_VIDEOS && (
          <VideoItem
            type={ITEM_VIDEOS}
            open={openItem === ITEM_VIDEOS}
            handleClose={handleItemClose}
            onSuccess={successCallback}
            updateItem={updateItem}
          />
        )}
        {openItem === ITEM_YOUTUBE && (
          <YoutubeItem
            type={ITEM_YOUTUBE}
            open={openItem === ITEM_YOUTUBE}
            handleClose={handleItemClose}
            onSuccess={successCallback}
            updateItem={updateItem}
          />
        )}

        {openItem === ITEM_TEST && (
          <TestItem
            type={ITEM_TEST}
            open={openItem === ITEM_TEST}
            handleClose={handleItemClose}
            onSuccess={successCallback}
            updateItem={updateItem}
          />
        )}

        {openItem === ITEM_LIBRARY && (
          <ImportLibrary
            type={ITEM_LIBRARY}
            open={openItem === ITEM_LIBRARY}
            handleClose={handleItemClose}
            onSuccess={successCallback}
            updateItem={updateItem}
          />
        )}


        <Box sx={{ mt: 1, mb: 2 }}>
          {cateId !== undefined && (
            <Breadcrumbs>
              <Link
                className="cate-breadcrumb-link"
                underline="hover"
                color="inherit"
                to={`/courses/view/${courseId}`}
              >
                Root
              </Link>
              {categories.map((category, index) => {
                if (categories.length !== index + 1) {
                  return (
                    <Link
                      key={index}
                      className="cate-breadcrumb-link"
                      underline="hover"
                      color="inherit"
                      to={`/courses/view/${courseId}/${category.uuid}`}
                    >
                      {category.name}
                    </Link>
                  );
                }
                return (
                  <Typography
                    className="cate-breadcrumb-text"
                    key={index}
                    color="text.primary"
                  >
                    {category.name}
                  </Typography>
                );
              })}
            </Breadcrumbs>
          )}
        </Box>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          aria-label="Course Items"
        >
          <Tab index={0} label="Course Items" />
          <Tab index={2} label="Students" />
        </Tabs>
        <Box sx={{ p: 2 }} hidden={currentTab !== 0}>
          <Box className="curatoritem-container">
            <span
              onClick={() => handleSetOpenItem(ITEM_CATEGORY)}
              className="curatoritem-menu"
            >
              <span className="curatoritem-menu-c">
                <CategoryIcon className="curatoritem-menu-txt" />
                Category
              </span>
            </span>
            <span
              onClick={() => handleSetOpenItem(ITEM_TEXT)}
              className="curatoritem-menu"
            >
              <span className="curatoritem-menu-c">
                <TextFieldsIcon className="curatoritem-menu-txt" />
                Text
              </span>
            </span>
            <span
              onClick={() => handleSetOpenItem(ITEM_IMAGE)}
              className="curatoritem-menu"
            >
              <span className="curatoritem-menu-c">
                <PhotoCameraBackIcon className="curatoritem-menu-txt" />
                Image
              </span>
            </span>
            <span
              onClick={() => handleSetOpenItem(ITEM_VIDEOS)}
              className="curatoritem-menu"
            >
              <span className="curatoritem-menu-c">
                <PlayCircleOutlineIcon className="curatoritem-menu-txt" />
                Videos
              </span>
            </span>
            <span
              onClick={() => handleSetOpenItem(ITEM_YOUTUBE)}
              className="curatoritem-menu"
            >
              <span className="curatoritem-menu-c">
                <YouTubeIcon className="curatoritem-menu-txt" />
                Youtube
              </span>
            </span>
            <span
              onClick={() => handleSetOpenItem(ITEM_PDF)}
              className="curatoritem-menu"
            >
              <span className="curatoritem-menu-c">
                <ArticleIcon className="curatoritem-menu-txt" />
                PDF
              </span>
            </span>
            <span
              onClick={() => handleSetOpenItem(ITEM_TEST)}
              className="curatoritem-menu"
            >
              <span className="curatoritem-menu-c">
                <QuizIcon className="curatoritem-menu-txt" />
                Test
              </span>
            </span>
            <span
              onClick={() => handleSetOpenItem(ITEM_LIBRARY)}
              className="curatoritem-menu"
            >
              <span className="curatoritem-menu-c">
                <LibraryBooksIcon className="curatoritem-menu-txt" />
                Import form Library
              </span>
            </span>
            {/* <span onClick={() => handleSetOpenItem(ITEM_LINK)} className='curatoritem-menu' >
              <span className='curatoritem-menu-c' >
                <LinkIcon className='curatoritem-menu-txt' />
                Link
              </span>
            </span> */}
          </Box>
          <Box className="curatoritem-listcontainer">
            {listLoading && <LinearProgress />}
            <ItemList
              courseItems={courseItems}
              handleUpdate={handleEditContent}
              handleDelete={handleSetDeleteItem}
              changePosition={handleUpdatePostion}
            />
            {courseItems?.length === 0 && (
              <Alert severity="info">No items have been added yet.</Alert>)}
          </Box>
        </Box>
        <Box hidden={currentTab !== 1}>
          <AssignStudents />
        </Box>
      </MainCard>
    </MainLayout>
  );
}
