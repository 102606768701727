import { call, put, select } from "redux-saga/effects";
import { sectionError, sectionSuccess, setSection, setSections } from "../../reducers/section";
import SectionAPI from "../requests/section";
const getToken = (state) => state.user;


export function* handleCreatesection(action) {        
    const { section } = action;
    const { token } = yield select(getToken);            
    try{        
        const response = yield call(SectionAPI.create, { section, token: token });                                        
        yield put(sectionSuccess(response));
    }catch(err){                
        yield put(sectionError(err));        
    }
}

export function* handleGetSections(action) {        
    const { testId } = action;
    const { token } = yield select(getToken);            
    try{        
        const response = yield call(SectionAPI.list, { testId, token: token });                                                
        yield put(setSections(response));
    }catch(err){                
        yield put(sectionError(err));        
    }
}

export function* handleGetSection(action) {
    const { uuid } = action;
    const { token } = yield select(getToken);            
    try{                
        const response = yield call(SectionAPI.get, { uuid, token: token });                                                
        yield put(setSection(response));
    }catch(err){                
        yield put(sectionError(err));        
    }
}

export function* handleUpdateSection(action) {
    const { section } = action;
    const { token } = yield select(getToken);            
    try{        
        const response = yield call(SectionAPI.update, { section, token: token });                                                
        yield put(sectionSuccess(response));
    }catch(err){                
        yield put(sectionError(err));        
    }
}

export function* handleRemoveSection(action) {    
    const { uuid } = action;
    const { token } = yield select(getToken);            
    try{        
        const response = yield call(SectionAPI.remove, { uuid, token: token });                                                
        yield put(sectionSuccess(response));
    }catch(err){                
        yield put(sectionError(err));        
    }
}