import { Box, Button, CircularProgress, Divider, Modal, TextField } from "@mui/material";
import * as yup from 'yup';
import "./categories.css";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { createCategory, getCategories } from "../../redux/reducers/category";
import { useEffect } from "react";

const CreateCategory = ({ open, handleCloseCreateCategory, cateId }) => {        

    const dispatch = useDispatch();    
    const { loading } = useSelector((state) =>  state.category);    
    useEffect(()=>{
        if(formik){
            formik.resetForm();
        }
    },[open]);

    const validationSchema = yup.object({
        name: yup
            .string('Enter the category')
            .required('Category name is required'),
    });
    
    const formik = useFormik({
        initialValues: {
            name: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {                  
            if(cateId){
                values.parent_id = cateId;
            }
            dispatch(createCategory(values, handleCloseCreateCategory));                         
        },
    });    
    
    return (<Modal
        open={open}
        onClose={handleCloseCreateCategory}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
    >
        <Box className='modal-style'>
            <h2 className="parent-modal-title">{ cateId? "Create sub category": "Create Category" }</h2>
            <Divider sx={{ mt: 1 }} />
            <form onSubmit={formik.handleSubmit} >
                <Box>
                    <TextField 
                        name="name"
                        className="input-standard-basic" 
                        label="Name" 
                        variant="standard" 
                        autoComplete="off"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.category && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        disabled={loading}
                        />                
                </Box>
                <Box sx={{ mt:5, textAlign: 'right', justifyContent: "flex-end" }} >
                    <Button sx={{ mr:2 }} variant="outlined" onClick={handleCloseCreateCategory} >Close</Button>
                    <Button type="submit" variant="contained" disabled={loading} >Save</Button>
                </Box>
            </form>
        </Box>
    </Modal>
    );
}
export default CreateCategory;