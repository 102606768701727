import { useParams } from 'react-router-dom';
import MainCard from '../../components/MainCard';
import MainLayout from '../../layout/MainLayout';
import TestForm from './forms/TestForm';
import { useState } from 'react';
import { Skeleton } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTestById } from '../../redux/reducers/test';

const EditTest = () => {
  const { testId } = useParams();
  const [selectedTest, setSelectedTest] = useState()
  const { test } = useSelector((state) => state.test)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTestById(testId));
  }, []);

  useEffect(() => {
    if (test) {
      setSelectedTest(test);
    }
  }, [test]);
  return (
    <MainLayout>
      <MainCard title="Update Test">
        {
          selectedTest ?
            (<TestForm selectedTest={selectedTest} />) : (<Skeleton variant="rectangular" height="250px" />)
        }
      </MainCard>
    </MainLayout>
  )
};

export default EditTest;
