import { Divider, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MainCard from '../../components/MainCard';
import MainLayout from '../../layout/MainLayout';
import { getUserById } from '../../redux/reducers/user';
import { env } from '../../config';


const UserDetails = (props) => {

    const { userId } = useParams();
    const dispatch = useDispatch();
    const { selectedUser } = useSelector((state) => state.user)    
    useEffect(()=> {        
        dispatch(getUserById(userId));
    },[]);

    if(!selectedUser){
        return <>...</>;
    }

    return (<MainLayout>
                <MainCard >
                    <Grid container sx={{ mb:"20px" }}>
                        <Grid item md={2}>
                            Name
                        </Grid>
                        <Grid  item md={10}>
                            { selectedUser.first_name } { selectedUser.last_name } 
                        </Grid>
                        <Grid item md={12}><Divider /></Grid>
                    </Grid> 
                    <Grid container sx={{ mb:"20px" }}>
                        <Grid item md={2}>
                            Role
                        </Grid>
                        <Grid  item md={10}>
                        { env.REACT_APP_PERMISSIONS[selectedUser.role] }
                        </Grid>
                        <Grid item md={12}><Divider /></Grid>
                    </Grid>
                     
                    <Grid container sx={{ mb:"20px" }}>
                        <Grid item md={2}>
                            Email
                        </Grid>
                        <Grid  item md={10}>
                            { selectedUser.email }
                        </Grid>
                        <Grid item md={12}><Divider /></Grid>
                    </Grid>                    
                </MainCard>
            </MainLayout>);
}

export default UserDetails;
