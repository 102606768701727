import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Box, LinearProgress, Alert } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as yup from 'yup';
import "./items.css";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import { env } from '../../../config';
import { libraryVideoUploadRequest } from '../../../redux/sagas/requests/course';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function VideoItem({ open, handleClose, type, onSuccess, updateItem }) {
  const [fileItem, setFileItem] = useState("");
  const { token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [publicUrl, setPublicUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { cateId } = useParams();
  const [uploadProgress, setUploadProgress] = useState(0);

  const headers = {
    "Content-Type": "application/json",
    Authorization: token,
  };

  function initState() {
    setFileItem();
    setLoading(false);
    setPublicUrl("");
    setErrorMessage("");
    formik.resetForm();
  }

  useEffect(() => {
    if (updateItem) {
      setPublicUrl(updateItem.url);
      setFileItem(updateItem.url);
    }
  }, [updateItem]);

  const handleCloseThis = () => {
    initState();
    handleClose();
  }


  const formik = useFormik({
    initialValues: {
      title: updateItem?.name ?? "",
    },
    validationSchema: yup.object({
      title: yup.string().required('Title is required'),
    }),
    onSubmit: async (values) => {
      if (publicUrl === "") {
        setErrorMessage("Upload Video");
      } else {
        if (updateItem) { // Update existing item
          const { data } = await axios.post(
            env.REACT_APP_API_HOST + `/api/access/library/update/${updateItem?.uuid}`, {
            name: values.title,
            url: publicUrl,
          },
            { headers: headers }
          );
          initState();
          onSuccess("Video content has successfully created");
        } else { // Create new item
          const { data } = await axios.post(
            env.REACT_APP_API_HOST + "/api/access/library/create", {
            name: values.title,
            url: publicUrl,
            type: type,
            categoryUuid: cateId
          },
            { headers: headers }
          );
          initState();
          onSuccess("Video content has successfully created");
        }
      }
    }
  });

  // const handleUploadFile = async (event) => {
  //   try {
  //     const selectedFile = event.target.files[0];
  //     const isVideo = selectedFile.type.startsWith('video');
  //     if (isVideo) {
  //       const headers = {
  //         'Content-Type': 'application/json',
  //         'Authorization': token
  //       }
  //       const response = await axios.post(env.REACT_APP_API_HOST + '/api/file/generate-presigned-url', {
  //         fileName: selectedFile.name,
  //         fileType: selectedFile.type,
  //       }, { headers: headers });
  //       const { url } = response.data;
  //       const res = await axios.put(url, selectedFile, {
  //         headers: {
  //           'Content-Type': selectedFile.type,
  //         },
  //         onUploadProgress: (progressEvent) => {
  //           const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  //           console.log("percentCompleted", percentCompleted)
  //           // setUploadProgress(percentCompleted);
  //         },
  //       });
  //       console.log("res", res.ok)
  //     }
  //   } catch (err) {
  //     console.error(err)
  //   }
  // }

  const handleUploadFile = async (event) => {
    try {
      const selectedFile = event.target.files[0];
      const isVideo = selectedFile.type.startsWith('video');
      const CHUNK_SIZE = 5 * 1024 * 1024;

      if (isVideo) {
        const startTime = new Date();
        setLoading(true);
        setUploadProgress(0);
        const totalChunks = Math.ceil(selectedFile.size / CHUNK_SIZE);
        const fileName = selectedFile?.name;
        const requestBody = { fileName };
        const res = await fetch(`${env.REACT_APP_API_HOST}/api/file/handle-video-upload-init`, {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const { uploadId, key } = await res.json();
        // console.log("uploadId ", uploadId);
        // Send file chunks
        const uploadPromises = [];
        let uploadedChunks = 0;
        let start = 0, end;
        for (let i = 0; i < totalChunks; i++) {
          end = start + CHUNK_SIZE;
          const chunk = selectedFile.slice(start, end);
          const formData = new FormData();
          formData.append('index', i);
          formData.append('totalChunks', totalChunks);
          formData.append('key', key);
          formData.append('file', chunk);
          const uploadPromise = fetch(`${env.REACT_APP_API_HOST}/api/file/handle-video-upload?uploadId=${uploadId}`, {
            method: "POST",
            body: formData,
          }).then(() => {
            uploadedChunks++;
            const progress = Math.floor((uploadedChunks / totalChunks) * 100);
            // console.log("progress", progress)
            // updateProgressBar(progress);
            setUploadProgress(progress);
          });
          uploadPromises.push(uploadPromise);
          start = end;
        }
        await Promise.all(uploadPromises);
        // Complete multipart upload
        const completeRes = await fetch(`${env.REACT_APP_API_HOST}/api/file/handle-video-upload-complete?key=${key}&uploadId=${uploadId}`, { method: 'POST' });
        const { success, data } = await completeRes.json();
        // console.log("file link: ", data);
        if (!success) {
          throw new Error('Error completing upload');
        }

        // End the timer and calculate the time elapsed
        const endTime = new Date();
        const timeElapsed = (endTime - startTime) / 1000;
        console.log('Time elapsed:', timeElapsed, 'seconds');
        setLoading(false);
        setFileItem(selectedFile);
        setPublicUrl(data);
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleUploadFileold = async (event) => {
    const selectedFile = event.target.files[0];
    const isVideo = selectedFile.type.startsWith('video');
    if (isVideo) {
      setErrorMessage("");
      setLoading(true);
      const formData = new FormData();
      formData.append("upload", selectedFile);
      const res = await libraryVideoUploadRequest({
        token,
        formData
      });
      if (res.success) {
        setFileItem(selectedFile);
        setPublicUrl(res.url);
      } else {
        setErrorMessage("Something went wrong!");
      }
      setLoading(false);
    }
  }

  const handleResetUpload = () => {
    setFileItem("");
    setPublicUrl("");
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="xs"
      onClose={handleCloseThis}
    >
      <DialogTitle sx={{ fontSize: "17px", fontWeight: "bold" }}>
        Video Content
        <IconButton
          aria-label="close"
          onClick={handleCloseThis}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {errorMessage !== "" && <Alert severity='error'>{errorMessage}</Alert>}
        <TextField
          autoFocus
          margin="dense"
          id="title"
          label="Content title"
          type="text"
          fullWidth
          variant="standard"
          placeholder='Introduction to trigonometry'
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />
        {loading && (
          <>
            <LinearProgress sx={{ mt: 5 }} />
            {uploadProgress}% uploaded
          </>
        )}

        {(!loading && publicUrl === "") && (
          <Button sx={{ mt: 5 }} fullWidth component="label" variant="contained" startIcon={<CloudUploadIcon />}>
            <VisuallyHiddenInput onChange={handleUploadFile} name='attachedFile' type="file" accept='video/*' /> Upload
          </Button>
        )}
        {
          publicUrl &&
          <div>
            <Box className="video-container" >
              <video className="video-item" controls controlsList="nodownload" >
                <source src={publicUrl} />
                Your browser does not support the video.
              </video>
            </Box>
            <Button onClick={handleResetUpload} sx={{ mt: 1 }} >Reset</Button>
          </div>

        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseThis} >Cancel</Button>
        <Button disabled={fileItem === "" || formik.values.title === ""} variant="contained" onClick={formik.handleSubmit} >Save</Button>
      </DialogActions>
    </Dialog>
  )
}
