import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { env } from "../../../../../config";

export const TestItem = ({ item }) => {
  const [uuid, setUuid] = useState(undefined);
  const { token } = useSelector((state) => state.user);

  useEffect(() => {
    if (item) {
      fetchTest();
    }
  }, [item]);

  const headers = {
    "Content-Type": "application/json",
    Authorization: token,
  };

  const fetchTest = async () => {
    try {
      const { data } = await axios.post(
        env.REACT_APP_API_HOST + "/api/access/test/get",
        { uuid: item?.test_id + "" },
        { headers: headers }
      );
      if (data && data?.result && data?.result?.uuid) {
        setUuid(data?.result?.uuid);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box sx={{ mt: 2, p: 2 }}>
      {uuid ? (
        <Button
          component={Link}
          to={`/tests/view/${uuid}`}
          target="_blank"
          sx={{ width: "100%" }}
          variant="contained"
        >
          View Test
        </Button>
      ) : (
        <Typography variant="body1">Loading...</Typography>
      )}
    </Box>
  );
};
