import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { Grid, Typography } from '@mui/material';

// project imports
import MainCard from '../MainCard';

// ==============================|| BREADCRUMBS ||============================== //

const Breadcrumbs = ({ customBreadcrumblinks, breadcrumblinks, navigation, title, ...others }) => {
    const location = useLocation();
    const [main, setMain] = useState();
    const [item, setItem] = useState();

    const isUrlMatch = (rootUrl, url) => {
        let rootUrlArray = rootUrl.split('/');
        let urlArray = url.split('/');        
        if(rootUrlArray.length != urlArray.length)
            return false;
        for(let i=0; i < rootUrlArray.length; i ++){                        
            if(rootUrlArray[i] != urlArray[i] ){
                if(rootUrlArray[i] != "*")
                    return false;
            }
        }
        return true;        
    }

    // set active item state
    const getCollapse = (menu) => {
        if (menu.children) {
            menu.children.filter((collapse) => {                
                if (collapse.type && collapse.type === 'collapse') {
                    getCollapse(collapse);
                } else if (collapse.type && collapse.type === 'item') {                                        
                    if (isUrlMatch(collapse.url, location.pathname)) {                        
                        setMain(menu);
                        setItem(collapse);
                    }
                }
                return false;
            });
        }
    };

    useEffect(() => {
        navigation?.items?.map((menu) => {
            if (menu.type && menu.type === 'group') {
                getCollapse(menu);
            }
            return false;
        });
    });

    // only used for component demo breadcrumbs
    if (location.pathname === '/breadcrumbs') {
        location.pathname = '/dashboard/analytics';
    }

    let mainContent = [];
    let customLinks = [];
    let itemContent;
    let breadcrumbContent = <Typography />;
    let itemTitle = '';

    // collapse item
    if (main && main.type === 'collapse') {
        mainContent = (
            <Typography key={ `k11` } component={Link} to={document.location.pathname} variant="h6" sx={{ textDecoration: 'none' }} color="textSecondary">
                {main.title}
            </Typography>
        );
    }


    if (item && item.parentLinks !== undefined) {                
        item.parentLinks.map(function(object){            
            if(breadcrumblinks){
                breadcrumblinks.map(function(update){
                    if(object.id === update.id){                    
                        object.title = update.title;
                        object.url = update.url;
                    }
                });
            }
            mainContent.push(
            <Typography key={object.id} component={Link} to={object.url} variant="h6" sx={{ textDecoration: 'none' }} color="textSecondary">
                {object.title}
            </Typography>
            );
        });
    }

    if(customBreadcrumblinks && customBreadcrumblinks.length >0){        
        customBreadcrumblinks.map((object) => {
            if(object.url){
                customLinks.push(
                    <Typography key={object.id} component={Link} to={object.url} variant="h6" sx={{ textDecoration: 'none' }} color="textSecondary">
                        {object.title}
                    </Typography>
                );
            }else{
                customLinks.push(
                    <Typography key={object.id} variant="subtitle1" color="textPrimary">
                        {object.title}
                    </Typography>
                );
            }
        })        
    }        

    // items
    if (item && item.type === 'item') {
        itemTitle = item.title;
        itemContent = (
            <Typography key={ `k12` } variant="subtitle1" color="textPrimary">
                {itemTitle}
            </Typography>
        );

        // main
        if (item.breadcrumbs !== false) {
            breadcrumbContent = (
                <MainCard border={false} sx={{ mb: 3, bgcolor: 'transparent' }} {...others} content={false}>
                    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                        <Grid item>
                            <MuiBreadcrumbs aria-label="breadcrumb">
                                <Typography component={Link} to="/dashboard" color="textSecondary" variant="h6" sx={{ textDecoration: 'none' }}>
                                    Home
                                </Typography>
                                {customLinks.length>0? (
                                    customLinks
                                ):(
                                    [
                                        mainContent,
                                        itemContent
                                    ]
                                )}                                                               
                            </MuiBreadcrumbs>
                        </Grid>
                        {title && (
                            <Grid item sx={{ mt: 2 }}>
                                <Typography variant="h5">{item.title}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </MainCard>
            );
        }
    }

    return breadcrumbContent;
};

Breadcrumbs.propTypes = {
    navigation: PropTypes.object,
    title: PropTypes.bool
};

export default Breadcrumbs;
