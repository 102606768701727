import { useEffect } from "react";
import Layout from "../layout/Layout"
import { useDispatch, useSelector } from "react-redux";
import { setGoBackLink } from "../../redux/reducers/util";
import { Box, Grid, Paper } from "@mui/material";
import "./style.css";
import TestCard from "./TestCard";
import { getTests } from "../../redux/reducers/studentTest";
import moment from "moment";
import { Link } from "react-router-dom";

const Tests = () => {
  const { tests } = useSelector((state) => state.studentTest);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setGoBackLink("/dashboard"));
    dispatch(getTests());
  }, []);

  return (
    <Layout>
      <Grid container sx={{ p: 5 }} >
        {tests.map((test, index) => (
          <Grid key={index} item xs={12} sm={12} md={4} xl={3} >
            <TestCard
              testId={test.uuid}
              testName={test.name}
              lastUpdated={moment(test.updated_at).format('DD/MM/YYYY hh:mm:A')}
              assignedOn={moment(test.assigned_at).format('DD/MM/YYYY hh:mm:A')}
              maxScore={test.max_score}
              minScore={test.min_score}
              negativeMarks={test.negative_mark}
              maxTime={test.max_time}
            />
          </Grid>
        ))}
      </Grid>
    </Layout>
  )
}

export default Tests;