import { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { setGoBackLink } from "../../redux/reducers/util";
import { Alert, Box, Button, Card, CircularProgress, Divider, Grid, LinearProgress, Paper, Typography } from "@mui/material";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getTest, setTestStatus, startPracticeTest, startTest } from "../../redux/reducers/studentTest";
import moment from "moment/moment";
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import AssessmentIcon from '@mui/icons-material/Assessment';
import axios from "axios";
import { env } from "../../config";

const Test = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const practiceMode = searchParams.get('practice') ? true : false;
  const { testId } = useParams();
  const { test: testResponse, startTestStatus, practiceId } = useSelector((state) => state.studentTest);
  const [isStartTestLoading, setIsStartTestLoading] = useState(false);
  const { token } = useSelector((state) => state.user);
  const headers = {
    "Content-Type": "application/json",
    Authorization: token,
  };
  const [practiceResults, setPracticeResults] = useState([]);

  useEffect(() => {
    if (practiceMode) {
      dispatch(setGoBackLink(`/practice`));
      fetchPracticeResults();
    } else {
      dispatch(setGoBackLink(`/tests`));
    }
    dispatch(getTest(testId));
  }, []);

  const fetchPracticeResults = async () => {
    try {
      const { data } = await axios.post(env.REACT_APP_API_HOST + '/api/access/studenttest/get-results-practice', { test_id: testId }, { headers: headers })
      setPracticeResults(data?.results)
    } catch (error) {
      console.error(error)
    }
  }


  useEffect(() => {
    if (startTestStatus === true) {
      dispatch(setTestStatus(false));
      if (practiceMode) {
        if (practiceId) {
          navigate(`/starttest/${testId}?practiceId=${practiceId}`);
        }
      } else {
        navigate(`/starttest/${testId}`);
      }

    }
  }, [startTestStatus]);

  if (!testResponse) {
    return (<Layout><LinearProgress /></Layout>);
  }
  const { assignment, test, questions, sections } = testResponse;

  const handleStartTest = () => {
    setIsStartTestLoading(true);
    if (practiceMode) {
      dispatch(startPracticeTest(assignment.uuid));
    } else {
      dispatch(startTest(assignment.uuid));
    }
  }

  return (
    <Layout>
      <Grid container sx={{ p: 5, pt: 6, pb: 6 }}>
        <Grid item xs={12} sm={12} md={12} xl={12} sx={{ p: 1, mb: 3 }} >
          <Typography variant="h4">{test.name}</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={6} md={3} xl={2} sx={{ p: 1 }} >
          <Paper sx={{ p: 2, textAlign: "center" }} >
            <Typography variant="p" color="text.secondary" >Assigned On</Typography>
            <Typography variant="h5">{moment(assignment.assigned_at).format('DD/MM/YYYY hh:mm:A')}</Typography>
          </Paper>
        </Grid>
        {test.section_wise ? (
          <>
            <Grid item xs={12} sm={6} md={3} xl={2} sx={{ p: 1 }} >
              <Paper sx={{ p: 2, textAlign: "center" }} >
                <Typography variant="p" color="text.secondary" >Section Wise</Typography>
                <Typography variant="h5">Yes</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3} xl={2} sx={{ p: 1 }} >
              <Paper sx={{ p: 2, textAlign: "center" }} >
                <Typography variant="p" color="text.secondary" >No of Sections</Typography>
                <Typography variant="h5">{sections?.length}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3} xl={2} sx={{ p: 1 }} >
              <Paper sx={{ p: 2, textAlign: "center" }} >
                <Typography variant="p" color="text.secondary" >No of Questions</Typography>
                <Typography variant="h5">{questions?.length}</Typography>
              </Paper>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={6} md={3} xl={2} sx={{ p: 1 }} >
              <Paper sx={{ p: 2, textAlign: "center" }} >
                <Typography variant="p" color="text.secondary" >Maximum Score</Typography>
                <Typography variant="h5">{test.max_score}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3} xl={2} sx={{ p: 1 }} >
              <Paper sx={{ p: 2, textAlign: "center" }} >
                <Typography variant="p" color="text.secondary" >Minimum Score</Typography>
                <Typography variant="h5">{test.min_score}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3} xl={2} sx={{ p: 1 }} >
              <Paper sx={{ p: 2, textAlign: "center" }} >
                <Typography variant="p" color="text.secondary" >Negative Marks</Typography>
                <Typography variant="h5">{test.negative_mark}</Typography>
              </Paper>
            </Grid>
          </>
        )
        }
        <Grid item xs={12} sm={6} md={3} xl={2} sx={{ p: 1 }} >
          <Paper sx={{ p: 2, textAlign: "center" }} >
            <Typography variant="p" color="text.secondary" >Maximum Time</Typography>
            <Typography variant="h5">{test.max_time} Minutes</Typography>
          </Paper>
        </Grid>
        {practiceMode ? (
          <Grid item xs={12} sm={12} md={12} xl={12} sx={{ p: 1, mt: 3 }} >
            <Button disabled={isStartTestLoading} variant="outlined" onClick={handleStartTest} >
              {isStartTestLoading ?
                (<CircularProgress size={12} sx={{ color: "#000", mr: 1 }} />) :
                (<PlayCircleFilledIcon sx={{ mr: 1 }} />)
              }
              Start Practice
            </Button>
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={12} xl={12} sx={{ p: 1, mt: 3 }} >
            {(assignment.status === "assigned" || assignment.status === "started") && (
              <Button disabled={isStartTestLoading} variant="outlined" onClick={handleStartTest} >
                {isStartTestLoading ?
                  (<CircularProgress size={12} sx={{ color: "#000", mr: 1 }} />) :
                  (<PlayCircleFilledIcon sx={{ mr: 1 }} />)
                }
                {assignment.status === "assigned" && <>Start Test</>}
                {assignment.status === "started" && <>Continue</>}
              </Button>
            )}
          </Grid>
        )}
        {
          !practiceMode && assignment.status === "completed" &&
          <>
            <Grid item xs={12} sm={12} md={12} xl={12} sx={{ p: 1, mt: 0 }} >
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12} sx={{ p: 1, mt: 0, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
              <Alert severity="info" >Please click the button below to view your results.</Alert>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12} sx={{ p: 1, mt: 0, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
              <Button component={Link} to={`/test/${test.uuid}/results`} variant="contained" startIcon={<AssessmentIcon />} >View Results</Button>
            </Grid>
          </>
        }
        {practiceMode && practiceResults?.uniqueTests?.length > 0 && (
          <Grid item xs={12} sm={12} md={12} xl={12} sx={{ mt: 3 }} >
            <Divider textAlign="left">Results</Divider>
            {practiceResults?.uniqueTests?.map(uniqueTest => (
              <Box sx={{ mt: 1, p: 2, display: "flex", justifyContent: "left" }} >
                <Box component={Link} to={`/practice/${test.uuid}/${uniqueTest?.practice_id}/results`} sx={{ textDecoration: "none", border: "1px solid #dadada", backgroundColor: "#e1f2e6", p: 2, minWidth: "450px", cursor: "pointer", display: "flex", justifyContent: "space-between" }} >
                  <Typography sx={{ fontSize: "19px", color: "#656565" }} >{practiceResults?.test?.name}</Typography>
                  <Typography sx={{ fontSize: "16px", color: "#656565" }} >{uniqueTest?.completed_at && new Date(uniqueTest?.completed_at)?.toLocaleDateString()}</Typography>
                </Box>
              </Box>
            ))
            }
          </Grid>)}
      </Grid >
    </Layout >
  );
}
export default Test;