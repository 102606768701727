import { useEffect, useState } from "react";
import MainCard from "../../../components/MainCard";
import MainLayout from "../../../layout/MainLayout"
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { clearMessages, getImportedQuestions, getTestById, importQuestions, removeTestQuestion } from "../../../redux/reducers/test";
import { Alert, Box, Button, CircularProgress, Divider, Snackbar } from "@mui/material";
import ImportQuestion from "./ImportQuestion";
import QuestionList from "./QuestionList";
import { getSection } from "../../../redux/reducers/section";
import BulkImportQuestions from "../../../components/bulkImportQuestions/BulkImportQuestions";
import { bulkImportQuestion } from "../../../redux/sagas/requests/questions";
import AssignmentIcon from '@mui/icons-material/Assignment';

const SectionQuestions = () => {
    const [breadcrumbLinks, setBreadcrumbLinks] = useState([]);
    const { testId, sectionId } = useParams();
    const { test, importQuestionLoading, importQuestionSuccess, importQuestionFailed, testQuestions } = useSelector((state) => state.test);
    const { section } = useSelector((state) => state.section);
    const dispatch = useDispatch();
    const [openImportQuestion, setOpenImportQuestion] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(null);
    const [isOpenImportDocx, setIsOpenImportDocx] = useState(false);
    const { token } = useSelector((state) => state.user);   

    useEffect(() => {
        dispatch(getTestById(testId));
        dispatch(getSection(sectionId));
    }, [testId, sectionId]);

    const handleOpenImportDocx = () => {
        setIsOpenImportDocx(true);
    }

    const handleCloseImportDocx = () => {
        setIsOpenImportDocx(false);
    }
    
    const ImportDocx = async (questions, categories) => {                
        setIsOpenImportDocx(false);
        await bulkImportQuestion({ token, importData: {
            questions, 
            categories,
            testId: test.id,
            sectionId: section?.id
        }});              
        fetchQuestion();           
        setSnackbarMessage({
            type: "success",
            message: 'Questions has been successfully imported'
        });
    }

    useEffect(() => {

        if (importQuestionSuccess) {
            setSnackbarMessage({
                type: "success",
                message: importQuestionSuccess
            });
            fetchQuestion();
        } else if (importQuestionFailed) {
            setSnackbarMessage({
                type: "error",
                message: importQuestionFailed
            });
        }

    }, [importQuestionSuccess, importQuestionFailed]);

    useEffect(() => {
        if (test) {
            setBreadcrumbLinks([{
                id: 'parent-test',
                title: test.name,
                url: `/tests/view/${test.uuid}`
            }]);
        }
    }, [test]);

    useEffect(() => {
        fetchQuestion();
    }, []);

    const handleOpenImportQuestion = () => {
        setOpenImportQuestion(true);
    }

    const handleCloseImportQuestion = () => {
        setOpenImportQuestion(false);
    }

    const handleImportQuestion = (ids) => {
        setOpenImportQuestion(false);
        let importInput = {
            test_id: testId,
            section_id: sectionId,
            question_ids: ids
        }
        dispatch(importQuestions(importInput));
    }

    const handleMessageClose = () => {
        dispatch(clearMessages());
        setSnackbarMessage(null);
    }

    const fetchQuestion = () => {
        dispatch(getImportedQuestions(testId, sectionId));
    }

    const handleRemoveQuestion = (uuid) => {
        dispatch(removeTestQuestion({
            "test_id": testId,
            "section_id": sectionId,
            "question_id": uuid
        }));        
    }

    return (
        <MainLayout breadcrumblinks={breadcrumbLinks} >
            <MainCard title={section ? section.name : `...`} >
                {snackbarMessage !== null && (
                    <Snackbar open={snackbarMessage !== null} autoHideDuration={6000} onClose={handleMessageClose}>
                        <Alert onClose={handleMessageClose} severity={snackbarMessage?.type} sx={{ width: '100%' }}>{snackbarMessage?.message}</Alert>
                    </Snackbar>
                )}
                <BulkImportQuestions open={isOpenImportDocx} handleClose={handleCloseImportDocx} callback={ImportDocx} />                        
                <ImportQuestion
                    open={openImportQuestion}
                    handleClose={handleCloseImportQuestion}
                    importQuestion={handleImportQuestion}
                    exclude={testQuestions.map( question => question.id)}
                />
                <Box sx={{ p: 1, mb: 5 }} >
                    <Button variant="outlined" onClick={handleOpenImportDocx} title="Import Questions" sx={{ mr: 2}}  startIcon={<AssignmentIcon />}  >Import Docx</Button>
                    <Button variant="contained" onClick={handleOpenImportQuestion}>Import Questions</Button>
                </Box>
                <Divider sx={{ mt: 2 }} >Questions { testQuestions?.length>0?`(${testQuestions?.length})`:`` }</Divider>
                {importQuestionLoading && (
                    <Box sx={{ textAlign: 'center', mt: 4 }} >
                        <CircularProgress size={25} />
                    </Box>
                )}
                {testQuestions.length > 0 ? (
                    <QuestionList questions={testQuestions} handleRemove={handleRemoveQuestion} />
                ) : (
                    <Box sx={{ mt: 4 }} >
                        <Alert severity="info">No questions has been added!</Alert>
                    </Box>
                )}

            </MainCard>
        </MainLayout>
    );
}
export default SectionQuestions;