import React from "react";
import "./libraryitem.css";
import { Button } from "@mui/material";
import {
  ITEM_IMAGE,
  ITEM_PDF,
  ITEM_TEXT,
  ITEM_VIDEOS,
  ITEM_YOUTUBE,
} from "../../../constants";
import { useParams } from "react-router-dom";
import YoutubePlayerComponent from "../../../../../components/YouTubePlayer";
import { createCourseItemRequest } from "../../../../../redux/sagas/requests/course";
import { useSelector } from "react-redux";

export default function LibraryItem({ item, handleSaveItem }) {
  return (
    <div className="courseitem">
      <h3 className="courseitem-title">{item?.type.replace("ITEM_", "")}</h3>
      <div className="courseitem-content">
        <h5 className="courseitem-content-title">{item?.name}</h5>
        {item?.type === ITEM_TEXT && (
          <div
            className="courseitem-content-text"
            dangerouslySetInnerHTML={{ __html: item.text }}
          ></div>
        )}
        {item?.type === ITEM_IMAGE && (
          <img className="courseitem-content-image" src={item.url} />
        )}
        {item?.type === ITEM_VIDEOS && (
          <video
            className="courseitem-content-video"
            controls
            controlsList="nodownload"
          >
            <source src={item.url} />
            Your browser does not support the video.
          </video>
        )}
        {item?.type === ITEM_PDF && (
          <iframe width="100%" height="240px" src={item.url} />
        )}
        {item?.type === ITEM_YOUTUBE && (
          <YoutubePlayerComponent
            uniqueId={item.uuid}
            width="100%"
            height="240px"
            link={item?.url}
          />
        )}
      </div>
      <div className="courseitem-actions">
        <Button onClick={() => handleSaveItem(item)} variant="contained">
          Select
        </Button>
      </div>
    </div>
  );
}
