import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import MainCard from '../../components/MainCard';
import MainLayout from '../../layout/MainLayout';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearMessages, getImportedQuestions, getStudents, getTestById, getUnAssignedStudents, importQuestions, removeTestQuestion } from '../../redux/reducers/test';
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, LinearProgress, List, ListItem, ListItemText, Snackbar, Tab, Tabs } from '@mui/material';
import moment from 'moment/moment';
import AddIcon from '@mui/icons-material/Add';
import StudentCard from './student/StudentCard';
import SectionTab from './components/SectionTab';
import ImportQuestion from './components/ImportQuestion';
import QuestionList from './components/QuestionList';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { bulkImportQuestion } from '../../redux/sagas/requests/questions';
import BulkImportQuestions from '../../components/bulkImportQuestions/BulkImportQuestions';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { removeTest } from '../../redux/sagas/requests/test';
import { createAlert, createSnackBarAlert } from '../../redux/reducers/util';
import Results from './results';

const DeleteDialog = ({ open, handleClose, confirmed }) => {

  const handleConfirmed = () => { confirmed(); }
  const handlecloseDialog = () => { handleClose(); }
  return (<Dialog
    fullScreen={false}
    open={open}
    onClose={handlecloseDialog}
    aria-labelledby="responsive-dialog-title"
  >
    <DialogTitle id="responsive-dialog-title" sx={{ color: 'red', fontSize: '15px' }} >Are you sure you want to delete this test?</DialogTitle>
    <DialogContent>
      <Alert severity="error">
        Warning: Deleting this test will have the following consequences:
        <List sx={{ listStyle: "decimal", pl: 4 }}>
          <ListItem sx={{ display: "list-item" }}>
            <ListItemText primary="All test assignments for students will be removed." />
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <ListItemText primary="Test results for this test will be permanently deleted." />
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <ListItemText primary="Associated sections linked to this test will be affected." />
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <ListItemText primary="All questions imported for this test will be removed from the system." />
          </ListItem>
        </List>
        This action cannot be undone.
      </Alert>
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={handleConfirmed} variant='contained' color='error' >
        Delete Test
      </Button>
      <Button autoFocus onClick={handlecloseDialog} >
        No
      </Button>
    </DialogActions>
  </Dialog>);
}

const ViewTest = (props) => {
  const dispatch = useDispatch();
  const { testId } = useParams();
  const { test } = useSelector((state) => state.test)
  const [activeTab, setActiveTab] = useState();
  const { students } = useSelector((state) => state.test)
  const { importQuestionLoading, importQuestionSuccess, importQuestionFailed, testQuestions } = useSelector((state) => state.test);
  const [openImportQuestion, setOpenImportQuestion] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [breadcrumbLinks, setBreadcrumbLinks] = useState([]);
  const [isOpenImportDocx, setIsOpenImportDocx] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    if (testId) {
      dispatch(getTestById(testId));
      // dispatch(getQuestions(testId));        
      dispatch(getStudents(testId));
      dispatch(getUnAssignedStudents(testId));
    }
    const testMenuTab = localStorage.getItem('testMenuTab');
    if (testMenuTab) {
      setActiveTab(testMenuTab);
    } else {
      setActiveTab("details");
    }
  }, []);

  useEffect(() => {
    if (activeTab) {
      localStorage.setItem('testMenuTab', activeTab);
    }
  }, [activeTab]);

  useEffect(() => {

    if (importQuestionSuccess) {
      setSnackbarMessage({
        type: "success",
        message: importQuestionSuccess
      });
      fetchQuestion();
    } else if (importQuestionFailed) {
      setSnackbarMessage({
        type: "error",
        message: importQuestionFailed
      });
    }

  }, [importQuestionSuccess, importQuestionFailed]);

  useEffect(() => {
    if (test && !test?.section_wise) {
      fetchQuestion();
    }
    if (test) {
      setBreadcrumbLinks([{
        id: 'parent-test',
        title: test.name,
        url: `/tests/view/${test.uuid}`
      }]);
    }
  }, [test]);

  const handleOpenImportQuestion = () => {
    setOpenImportQuestion(true);
  }

  const handleCloseImportQuestion = () => {
    setOpenImportQuestion(false);
  }

  const handleImportQuestion = (ids) => {
    setOpenImportQuestion(false);
    let importInput = {
      test_id: test?.uuid,
      question_ids: ids
    }
    dispatch(importQuestions(importInput));
  }

  const handleMessageClose = () => {
    dispatch(clearMessages());
    setSnackbarMessage(null);
  }

  const fetchQuestion = () => {
    if (test?.uuid) {
      dispatch(getImportedQuestions(test?.uuid));
    }
  }

  const handleRemoveQuestion = (uuid) => {
    dispatch(removeTestQuestion({
      "test_id": test.uuid,
      "question_id": uuid
    }));
  }

  const handleTabChange = (e, value) => {
    setActiveTab(value);
  };

  function TabPanel(props) {
    const { children, value, activeTab } = props;

    if (value !== activeTab) {
      return <div hidden={true} ></div>;
    }
    return (
      <Box sx={{ pt: "30px" }} >
        {children}
      </Box>
    );
  }

  if (!test || test.length === 0 || !activeTab) {
    return <>...</>;
  }

  const handleOpenImportDocx = () => {
    setIsOpenImportDocx(true);
  }
  const handleCloseImportDocx = () => {
    setIsOpenImportDocx(false);
  }

  const ImportDocx = async (questions, categories) => {
    setIsOpenImportDocx(false);
    await bulkImportQuestion({
      token, importData: {
        questions,
        categories,
        testId: test.id
      }
    });
    fetchQuestion();
    setSnackbarMessage({
      type: "success",
      message: 'Questions has been successfully imported'
    });
  }

  const handleDeleteConfirmOpen = () => {
    setIsOpenDeleteConfirm(true);
  }

  const handleDeleteConfirmClose = () => {
    setIsOpenDeleteConfirm(false);
  }

  const handleDeleteTest = async () => {
    setIsOpenDeleteConfirm(false);
    setIsLoading(true);
    await removeTest({
      token: token,
      test_id: test.uuid
    });
    dispatch(createSnackBarAlert({
      type: "success",
      message: "Test has been successfully deleted"
    }));
    navigate('/tests');
  }

  return (
    <MainLayout breadcrumblinks={breadcrumbLinks} >
      {isLoading && (
        <LinearProgress sx={{ mb: 1 }} />
      )}
      <MainCard >
        {!test.section_wise && (
          <div>
            {snackbarMessage !== null && (
              <Snackbar open={snackbarMessage !== null} autoHideDuration={6000} onClose={handleMessageClose}>
                <Alert onClose={handleMessageClose} severity={snackbarMessage?.type} sx={{ width: '100%' }}>{snackbarMessage?.message}</Alert>
              </Snackbar>
            )}
            <BulkImportQuestions open={isOpenImportDocx} handleClose={handleCloseImportDocx} callback={ImportDocx} />
            <ImportQuestion
              open={openImportQuestion}
              handleClose={handleCloseImportQuestion}
              importQuestion={handleImportQuestion}
              exclude={testQuestions.map(question => question.id)}
            />
          </div>
        )}
        <DeleteDialog open={isOpenDeleteConfirm} handleClose={handleDeleteConfirmClose} confirmed={handleDeleteTest} />
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={handleTabChange} >
            <Tab value="details" label="Details" disabled={isLoading} />
            <Tab value="questions" label="Questions" disabled={isLoading} />
            <Tab value="students" label="Students" disabled={isLoading} />
            <Tab value="results" label="Results" disabled={isLoading} />
          </Tabs>
        </Box>
        <TabPanel value="details" activeTab={activeTab} >
          <Grid container sx={{ mb: "20px" }}>
            <Grid item md={2}>
              Name
            </Grid>
            <Grid item md={10}>
              {test.name}
            </Grid>
            <Grid item md={12}><Divider /></Grid>
          </Grid>
          <Grid container sx={{ mb: "20px" }}>
            <Grid item md={2}>
              Show Questions Section wise?
            </Grid>
            <Grid item md={10}>
              {test.section_wise === 0 ? 'No' : 'Yes'}
            </Grid>
            <Grid item md={12}><Divider /></Grid>
          </Grid>
          <Grid container sx={{ mb: "20px" }}>
            <Grid item md={2}>
              Maximum time (In minutes)
            </Grid>
            <Grid item md={10}>
              {test.max_time}
            </Grid>
            <Grid item md={12}><Divider /></Grid>
          </Grid>
          <Grid container sx={{ mb: "20px" }}>
            <Grid item md={2}>
              Allow Practice Mode
            </Grid>
            <Grid item md={10}>
              {test.allow_practice_mode ? "Yes" : "No"}
            </Grid>
            <Grid item md={12}><Divider /></Grid>
          </Grid>
          {test.section_wise === 0 &&
            <>
              <Grid container sx={{ mb: "20px" }}>
                <Grid item md={2}>
                  AutoScore
                </Grid>
                <Grid item md={10}>
                  {test?.auto_score ? (<>Yes</>) : (<>No</>)}
                </Grid>
                <Grid item md={12}><Divider /></Grid>
              </Grid>
              <Grid container sx={{ mb: "20px" }}>
                <Grid item md={2}>
                  Maximum Score
                </Grid>
                <Grid item md={10}>
                  {test.max_score}
                </Grid>
                <Grid item md={12}><Divider /></Grid>
              </Grid>
              <Grid container sx={{ mb: "20px" }}>
                <Grid item md={2}>
                  Minimum Score
                </Grid>
                <Grid item md={10}>
                  {test.min_score}
                </Grid>
                <Grid item md={12}><Divider /></Grid>
              </Grid>
              <Grid container sx={{ mb: "20px" }}>
                <Grid item md={2}>
                  Negative Mark
                </Grid>
                <Grid item md={10}>
                  {test.negative_mark}
                </Grid>
                <Grid item md={12}><Divider /></Grid>
              </Grid>
            </>
          }
          <Grid container sx={{ mb: "20px" }}>
            <Grid item md={2}>
              Created
            </Grid>
            <Grid item md={10}>
              {moment(test.created_at).format('DD/MM/YYYY hh:mm:A')}
            </Grid>
            <Grid item md={12}><Divider /></Grid>
          </Grid>
          <Grid container sx={{ mb: "20px" }}>
            <Grid item md={2}>
              Last Updated
            </Grid>
            <Grid item md={10}>
              {moment(test.updated_at).format('DD/MM/YYYY hh:mm:A')}
            </Grid>
            <Grid item md={12}><Divider /></Grid>
          </Grid>
          <Grid container sx={{ mb: "20px" }}>
            <Box sx={{ display: "flex", gap: "20px" }} >
              <Button
                variant="contained"
                disabled={isLoading}
                component={Link}
                to={"/tests/edit/" + test.uuid}
              >Edit Test</Button>
              <Button
                variant="contained"
                color='error'
                startIcon={<DeleteForeverIcon />}
                onClick={handleDeleteConfirmOpen}
                disabled={isLoading}
              >Delete Test</Button>
            </Box>
          </Grid>
        </TabPanel>
        <TabPanel value="questions" activeTab={activeTab}>
          {test.section_wise === 1 ? (
            <SectionTab testId={test.id} testUuid={test.uuid} />
          ) : (
            <>

              <Box sx={{ p: 1, mb: 5, textAlign: 'center' }} >
                {/* <Alert sx={{ mb: 2 }} severity='info' >To create a new question, simply click the 'Create Question' button and fill out the form with your question details.</Alert> */}
                <Button variant="outlined" onClick={handleOpenImportDocx} title="Import Questions" sx={{ mb: 1, mr: 2 }} startIcon={<AssignmentIcon />}  >Import Docx</Button>
                <Button variant="outlined" onClick={handleOpenImportQuestion} title="Import Questions" sx={{ mb: 1 }}> <AddIcon /> Import Questions</Button>
              </Box>
              <Divider sx={{ mt: 2 }} >Questions {testQuestions?.length > 0 ? `(${testQuestions?.length})` : ``}</Divider>
              {importQuestionLoading && (
                <Box sx={{ textAlign: 'center', mt: 4 }} >
                  <CircularProgress size={25} />
                </Box>
              )}
              {testQuestions.length > 0 ? (
                <QuestionList questions={testQuestions} handleRemove={handleRemoveQuestion} />
              ) : (
                <Box sx={{ mt: 4 }} >
                  <Alert severity="info">No questions has been added!</Alert>
                </Box>
              )}
            </>
          )}
        </TabPanel>
        <TabPanel value="students" activeTab={activeTab}>
          <StudentCard students={students} />
        </TabPanel>
        <TabPanel value="results" activeTab={activeTab}>
          <Results testId={testId} />
        </TabPanel>
      </MainCard>
    </MainLayout>);
}

export default ViewTest;
