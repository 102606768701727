import MainCard from '../../components/MainCard';
import MainLayout from '../../layout/MainLayout';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import TestTable from './TestTable';
// import StudentTable from './StudentTable';


const Tests = () => (
    <MainLayout>
        <MainCard title="Tests">
            <Box sx={{ m:1, mb:4 }} display="flex" justifyContent="flex-end">
                <Button component={Link} to="/tests/new" variant="contained" size="small">Add Test</Button>                
            </Box>            
            <TestTable />
        </MainCard>
    </MainLayout>
);

export default Tests;
