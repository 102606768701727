import axios from "axios";
import { env } from "../../../config";

export function createTestRequest(request) {
    const { test, token } = request;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/test/create', test, { headers: headers })
            .then(function (response) {
                if (response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                        uuid: response.data.uuid
                    });
                } else {
                    reject({
                        status: 'error',
                        message: 'Something went wrong!'
                    });
                }
            })
            .catch(function (error) {
                reject({
                    status: 'error',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function updateTestRequest(request) {
    const { test, token } = request;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/test/update', test, { headers: headers })
            .then(function (response) {
                if (response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                        uuid: response.data.uuid
                    });
                } else {
                    reject({
                        status: 'error',
                        message: 'Something went wrong!'
                    });
                }
            })
            .catch(function (error) {
                reject({
                    status: 'error',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function getTestRequest(request) {
    const { uuid, token } = request;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/test/get', { uuid: uuid }, { headers: headers })
            .then(function (response) {
                if (response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                        result: response.data.result
                    });
                } else {
                    reject({
                        status: 'error',
                        message: 'Something went wrong!'
                    });
                }
            })
            .catch(function (error) {
                reject({
                    status: 'error',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function getTestsRequest(request) {
    const { uuid, token } = request;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.get(env.REACT_APP_API_HOST + '/api/access/test/list', { headers: headers })
            .then(function (response) {
                if (response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                        results: response.data.results
                    });
                } else {
                    reject({
                        status: 'error',
                        message: 'Something went wrong!'
                    });
                }
            })
            .catch(function (error) {
                reject({
                    status: 'error',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function createQuestionRequest(request) {
    const { question, token } = request;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    let questionField = {                
        description: question.description,
        explanation: question.explanation,
        type: question.questionType,
        difficulty: question.difficulty,
        score: question.score,
        categories: question.categories
    }
    
    if (question.questionType === "objective") {
        questionField.options = [];
        for (const option of question.options) {
            questionField.options.push({
                "text": option.text,
                "correct": option.correct
            });
        }
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/test/addquestion', questionField, { headers: headers })
            .then(function (response) {
                if (response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                    });
                } else {
                    reject({
                        status: 'error',
                        message: 'Something went wrong!'
                    });
                }
            })
            .catch(function (error) {
                reject({
                    status: 'error',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function getQuestionsRequest(request) {
    const { uuid, token } = request;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/test/getquestions', { uuid: uuid } , { headers: headers })
            .then(function (response) {
                if (response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                        results: response.data.results
                    });
                } else {
                    reject({
                        status: 'error',
                        message: 'Something went wrong!'
                    });
                }
            })
            .catch(function (error) {
                reject({
                    status: 'error',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function requestUnlinkQuestion(request) {
    const { tests_id, questions_id, token } = request;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/test/removequestionlink', { tests_id: tests_id, questions_id: questions_id } , { headers: headers })
            .then(function (response) {                
                if (response && response.data && response.data.status) {                    
                    resolve({
                        status: response.data.status,
                        message: response.data.message,    
                    });
                } else {
                    reject({
                        status: 'error',
                        message: 'Something went wrong!'
                    });
                }
            })
            .catch(function (error) {                
                reject({
                    status: 'error',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function getQuestionRequest(request) {
    const { uuid, token } = request;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/test/getquestion', { uuid: uuid }, { headers: headers })
            .then(function (response) {
                if (response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                        results: response.data.results
                    });
                } else {
                    reject({
                        status: 'error',
                        message: 'Something went wrong!'
                    });
                }
            })
            .catch(function (error) {
                reject({
                    status: 'error',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function updateQuestionRequest(request) {
    const { question, token } = request;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    let questionField = {
        uuid: question.uuid,        
        description: question.description,
        explanation: question.explanation,
        type: question.questionType,
        difficulty: question.difficulty,
        score: question.score,
        categories: question.categories
    }
    if (question.questionType === "objective") {
        questionField.options = [];
        for (const option of question.options) {
            questionField.options.push({
                "text": option.text,
                "correct": option.correct
            });
        }
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/test/updatequestion', questionField, { headers: headers })
            .then(function (response) {
                if (response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                    });
                } else {
                    reject({
                        status: 'error',
                        message: 'Something went wrong!'
                    });
                }
            })
            .catch(function (error) {
                reject({
                    status: 'error',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function addStudentsToTestRequest(request) {
    const { students, token } = request;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/test/addstudents', students, { headers: headers })
            .then(function (response) {
                if (response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                    });
                } else {
                    reject({
                        status: 'error',
                        message: 'Something went wrong!'
                    });
                }
            })
            .catch(function (error) {
                reject({
                    status: 'error',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function getTestStudentsRequest(request) {
    const { testId, token } = request;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/test/getstudents', { test_id:testId }, { headers: headers })
            .then(function (response) {
                if (response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        results: response.data.results,
                    });
                } else {
                    reject({
                        status: 'error',
                        message: 'Something went wrong!'
                    });
                }
            })
            .catch(function (error) {
                reject({
                    status: 'error',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function getTestStudentsUnAssignedRequest(request) {
    const { testId, token } = request;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/test/getstudents-unassigned', { test_id:testId }, { headers: headers })
            .then(function (response) {
                if (response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        results: response.data.results,
                    });
                } else {
                    reject({
                        status: 'error',
                        message: 'Something went wrong!'
                    });
                }
            })
            .catch(function (error) {
                reject({
                    status: 'error',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function removeStudentRequest(request) {
    const { uuid, token } = request;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/test/removestudent', { tests_students: uuid }, { headers: headers })
            .then(function (response) {
                if (response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                    });
                } else {
                    reject({
                        status: 'error',
                        message: 'Something went wrong!'
                    });
                }
            })
            .catch(function (error) {
                reject({
                    status: 'error',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function importQuestionRequest(request) {
    const { importInput, token } = request;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/test/import-questions', importInput, { headers: headers })
            .then(function (response) {
                if (response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                    });
                } else {
                    reject({
                        status: 'error',
                        message: 'Something went wrong!'
                    });
                }
            })
            .catch(function (error) {
                reject({
                    status: 'error',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function getTestQuestionsRequest(request) {
    const { test_id, section_id, token } = request;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/test/test-questions', { test_id, section_id }, { headers: headers })
            .then(function (response) {
                if (response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                        results: response.data.results,
                    });
                } else {
                    reject({
                        status: 'error',
                        message: 'Something went wrong!'
                    });
                }
            })
            .catch(function (error) {
                reject({
                    status: 'error',
                    message: 'Something went wrong!'
                });
            });
    });
}


export function removeTestQuestionsRequest(request) {
    const { input, token } = request;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/test/remove-questions', input, { headers: headers })
            .then(function (response) {
                if (response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message
                    });
                } else {
                    reject({
                        status: 'error',
                        message: 'Something went wrong!'
                    });
                }
            })
            .catch(function (error) {
                reject({
                    status: 'error',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function removeTest(request) {
    const { test_id, token } = request;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/test/remove-test', { test_id: test_id }, { headers: headers })
            .then(function (response) {
                if (response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                    });
                } else {
                    reject({
                        status: 'error',
                        message: 'Something went wrong!'
                    });
                }
            })
            .catch(function (error) {
                reject({
                    status: 'error',
                    message: 'Something went wrong!'
                });
            });
    });
}