import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Alert, Box, Snackbar, Toolbar, useMediaQuery } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import navigation from '../../menu-items';
import Breadcrumbs from '../../components/@extended/Breadcrumbs';
import { toggleDrawer, createAlert, clearAlert, clearSnackBarAlert } from '../../redux/reducers/util';
import { Stack } from '@mui/system';

// types
// import { openDrawer } from 'store/reducers/menu';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = (props) => {
    const theme = useTheme();
    const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
    const dispatch = useDispatch();

    // const { drawerOpen, alerts } = useSelector((state) => state.util);    
    // console.log("props", props);
    const { snackBarAlert } = useSelector((state) => state.util);    
    const { drawerOpen } = props;    


    // drawer toggler
    const [open, setOpen] = useState(drawerOpen);
    const handleDrawerToggle = () => {
        //setOpen(!open);
        dispatch(toggleDrawer());
        
    };

    // set media wise responsive drawer
    useEffect(() => {
        setOpen(!matchDownLG);
        // dispatch(openDrawer({ drawerOpen: !matchDownLG }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownLG]);

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerOpen]);

    let AlertList = [];

    const handleSnackBarClose = () => {
        dispatch(clearSnackBarAlert());
    }

    
    // for(var i=0; i<alerts.length; i++){
    //     const { alertType, alertMessage, alertId } = alerts[i];
    //     AlertList.push(<Box sx={{ pb: '15px' }} key={alertId} >
    //                     <Alert severity={ alertType } onClose={() => { dispatch(clearAlert(alertId)) }} >{ alertMessage }</Alert>
    //                 </Box>);
    //     setTimeout(() => {
    //         dispatch(clearAlert(alertId));
    //     }, 4500);
    // }
    
    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <Header open={open} handleDrawerToggle={handleDrawerToggle} />
            <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
            <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
                <Toolbar />
                {snackBarAlert !== null && (                    
                    <Snackbar open={snackBarAlert !== null} autoHideDuration={3500} onClose={handleSnackBarClose} >
                        <Alert severity={snackBarAlert?.type} sx={{ width: '100%' }}>{snackBarAlert?.message}</Alert>
                    </Snackbar>
                )}
                <Breadcrumbs customBreadcrumblinks={props.customBreadcrumblinks} breadcrumblinks={props.breadcrumblinks} navigation={navigation} divider={false} />         
                {AlertList}               
                {props.children}
            </Box>
        </Box>
    );
};

function mapStateToProps(state) {    
    return {
        drawerOpen : state.util.drawerOpen,         
    }
}

export default connect(mapStateToProps)(MainLayout);
