import { Box, CircularProgress, Divider, Paper, Table, TableBody, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQuestions } from "../../redux/reducers/questions";
import * as React from 'react';
import { useState } from "react";
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage'
import { useTheme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import "./tableData.css";
import { Link } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number,
) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}


function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

const TableData = () => {
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(10)
    const [page, setPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQueryUpdate, setSearchQueryUpdate] = useState(0);
    const { loading, questions, questionsCount } = useSelector((state) => state.questions);

    useEffect(() => {
        let params = [];
        params.page = page + 1;
        params.pageSize = pageSize;
        params.searchQuery = searchQuery;
        dispatch(getQuestions(params));
    }, [, page, pageSize, searchQueryUpdate]);



    if (loading) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: "150px"
            }}>
                <CircularProgress />
            </Box>
        )
    }

    const handleSearchTextChange = (event) => {
        setSearchQuery(event.target.value);
    }

    const handleChangePage = (event, number) => {
        setPage(parseInt(number));
    }

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setPageSize(parseInt(event.target.value));
    }

    function isNumber(value) {
        return typeof value === 'number' && !isNaN(value);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            setSearchQueryUpdate(searchQueryUpdate + 1);
        }
    };

    const handleClearText = () => {
        setSearchQuery("");
        setSearchQueryUpdate(searchQueryUpdate + 1);
    }


    return (
        <Box>
            <TextField
                autoComplete="off"
                onChange={handleSearchTextChange}
                onKeyPress={handleKeyPress}
                value={searchQuery}
                sx={{ width: "270px" }} label="Search questions" variant="standard"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {searchQuery && (
                                <IconButton onClick={handleClearText}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <Divider sx={{ mt: 2, mb: 2 }} />
            <TableContainer component={Paper} >
                <Table>
                    <TableHead>
                        <TableRow>                            
                            <StyledTableCell align="left">Question</StyledTableCell>
                            <StyledTableCell align="right">Type</StyledTableCell>
                            <StyledTableCell align="right">Score</StyledTableCell>
                            <StyledTableCell align="right">Difficulty</StyledTableCell>
                            <StyledTableCell align="right">Actions</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {questions.length === 0 &&
                            <TableRow>
                                <TableCell align="center" colSpan={6}>
                                    <Typography variant="body1">No results found.</Typography>
                                </TableCell>
                            </TableRow>
                        }
                        {questions.map((question) => (
                            <StyledTableRow className="image-handle" key={question.uuid}>                                
                                <StyledTableCell align="left" className="overflow-cell">
                                    <Link to={`/questions/view/${question.uuid}`} >
                                        <span className="title-container" dangerouslySetInnerHTML={{ __html: question.description }} ></span>
                                    </Link>
                                </StyledTableCell>
                                <StyledTableCell align="right" className="capitalize" >{question.type }</StyledTableCell>
                                <StyledTableCell align="right">{question.score}</StyledTableCell>
                                <StyledTableCell align="right" className="capitalize" >{question.difficulty }</StyledTableCell>
                                <StyledTableCell align="right">                                    
                                    <IconButton component={Link} to={`/questions/edit/${question.uuid}`} color="primary" aria-label="edit">
                                        <EditIcon />
                                    </IconButton>                                    
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                    {isNumber(questionsCount) &&
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 40]}
                                    colSpan={6}
                                    count={questionsCount}
                                    rowsPerPage={pageSize}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    }
                </Table>
            </TableContainer>
        </Box>
    );
}

export default TableData; 