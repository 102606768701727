import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Box,
  LinearProgress,
  Alert,
  FormControl,
  InputLabel,
  Typography,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import "./items.css";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  createCourseItemRequest,
  updateCourseItemRequest,
} from "../../../../../redux/sagas/requests/course";
import axios from "axios";
import { env } from "../../../../../config";

export default function TestItem({
  open,
  handleClose,
  type,
  onSuccess,
  updateItem,
}) {
  const { token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [testItems, setTestItems] = useState([]);
  const { courseId, cateId } = useParams();
  const headers = {
    "Content-Type": "application/json",
    Authorization: token,
  };

  function initState() {
    setLoading(false);
    setErrorMessage("");
    formik.resetForm();
  }
  useEffect(() => {
    fetchTests();
  }, []);

  useEffect(() => {
    if (testItems?.length > 0 && updateItem) {
      formik.setFieldValue(
        "test",
        testItems.find((item) => item.id === updateItem.test_id)
      );
    }
  }, [testItems, updateItem]);

  const fetchTests = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        env.REACT_APP_API_HOST + "/api/access/test/list",
        { headers: headers }
      );
      if (!data || data?.status !== "success" || !data?.results) {
        throw "err";
      }
      setTestItems(data?.results);
    } catch (err) {
      setErrorMessage("Something went wrong");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseThis = () => {
    initState();
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      title: updateItem?.name ?? "",
      test: updateItem?.test_id ?? "",
    },
    validationSchema: yup.object({
      title: yup.string().required("Title is required"),
      test: yup.object().required("Test is required"),
    }),
    onSubmit: async (values) => {
      if (updateItem) {
        //update
        await updateCourseItemRequest({
          token,
          input: {
            uuid: updateItem.uuid,
            name: values.title,
            test_id: values?.test?.id,
          },
        });
        onSuccess("Test content been successfully updated.");
      } else {
        await createCourseItemRequest({
          token,
          input: {
            name: values.title,
            course_id: courseId,
            test_id: values?.test?.id,
            type: type,
            categoryUuid: cateId,
          },
        });
        onSuccess("Test content been successfully created.");
      }
      initState();      
    },
  });

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      onClose={handleCloseThis}
    >
      <DialogTitle sx={{ fontSize: "17px", fontWeight: "bold" }}>
        Test
        <IconButton
          aria-label="close"
          onClick={handleCloseThis}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {loading ? (
        <DialogContent dividers>
          <Box sx={{ textAlign: "center", mt: 4, mb: 4 }}>
            <CircularProgress size={25} />
          </Box>
        </DialogContent>
      ) : (
        <DialogContent dividers>
          {errorMessage !== "" && (
            <Alert severity="error">{errorMessage}</Alert>
          )}
          <TextField
            autoComplete="off"
            autoFocus
            margin="dense"
            id="title"
            label="Content title"
            type="text"
            fullWidth
            variant="standard"
            placeholder="Introduction to trigonometry"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
            sx={{ mb: 2 }}
          />
          <Autocomplete
            style={{ width: "100%" }}
            options={testItems}
            getOptionLabel={(option) => option.name || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Test"
                variant="outlined"
                error={formik.touched.test && Boolean(formik.errors.test)}
                helperText={formik.touched.test && formik.errors.test}
              />
            )}
            name="test"
            value={formik.values.test}
            onChange={(event, value) => formik.setFieldValue("test", value)}
            filterOptions={(options, state) =>
              options.filter((option) =>
                option.name
                  .toLowerCase()
                  .includes(state.inputValue.toLowerCase())
              )
            }
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
          />
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleCloseThis}>Cancel</Button>
        <Button variant="contained" onClick={formik.handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
