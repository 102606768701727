import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Paper, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import moment from 'moment/moment';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from "react";

const QuestionList = ({ questions, handleRemove }) => {
    const [openConfim, setOpenConfim] = useState(false);
    const [removeUuid, setRemoveUuid] = useState('');

    const handleDeleteClick = () => {        
        setOpenConfim(false);
        handleRemove(removeUuid);        
    }

    const handleOpenConfirm = (uuid) => {        
        setRemoveUuid(uuid);
        setOpenConfim(true);
    }
    const handleCloseConfirm = () => {
        setRemoveUuid('');
        setOpenConfim(false);
    }
    
    return (
        <Box>
            <Dialog
                open={openConfim}
                onClose={handleCloseConfirm}
            >
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText>Would you like to remove question?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm}>Disagree</Button>
                    <Button onClick={handleDeleteClick} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
            {questions.map(question => (
                <Accordion key={question.uuid} >
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                    >
                        <Typography><span dangerouslySetInnerHTML={{ __html: question.description }} ></span></Typography>
                    </AccordionSummary>
                    <AccordionDetails >
                        <Grid container sx={{ mb: "20px" }}>
                            <Grid item md={2} xs={12} >
                                Explanation
                            </Grid>
                            <Grid item md={10} xs={12} ><span dangerouslySetInnerHTML={{ __html: question.explanation }} ></span></Grid>
                            <Grid item md={12}><Divider /></Grid>
                        </Grid>
                        <Grid container sx={{ mb: "20px" }}>
                            <Grid item md={2} xs={12} >
                                Type
                            </Grid>
                            <Grid item md={10} xs={12} ><Typography sx={{ textTransform: 'capitalize' }} >{question.type}</Typography></Grid>
                            <Grid item md={12}><Divider /></Grid>
                        </Grid>
                        <Grid container sx={{ mb: "20px" }}>
                            <Grid item md={2} xs={12} >Difficulty</Grid>
                            <Grid item md={10} xs={12} ><Typography sx={{ textTransform: 'capitalize' }} >{question.difficulty}</Typography></Grid>
                            <Grid item md={12}><Divider /></Grid>
                        </Grid>
                        <Grid container sx={{ mb: "20px" }}>
                            <Grid item md={2} xs={12} >Score</Grid>
                            <Grid item md={10} xs={12} ><Typography sx={{ textTransform: 'capitalize' }} >{question.score}</Typography></Grid>
                            <Grid item md={12}><Divider /></Grid>
                        </Grid>
                        <Grid container sx={{ mb: "20px" }}>
                            <Grid item md={2} xs={12} >Create on</Grid>
                            <Grid item md={10} xs={12} >{moment(question.created_at).format('DD/MM/YYYY hh:mm:A')}</Grid>
                            <Grid item md={12}><Divider /></Grid>
                        </Grid>
                        <Grid container sx={{ mb: "20px" }}>
                            <Grid item md={2} xs={12} >Updated on</Grid>
                            <Grid item md={10} xs={12} >{moment(question.updated_at).format('DD/MM/YYYY hh:mm:A')}</Grid>
                            <Grid item md={12}><Divider /></Grid>
                        </Grid>
                        {question.type === "objective" && (
                            <Grid container sx={{ mb: "20px" }}>
                                <Grid item md={12} xs={12} >
                                    <Accordion key={question.uuid} >
                                        <AccordionSummary
                                            expandIcon={<ExpandMore />}
                                        >
                                            <Typography>Question Options</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails >
                                            {question?.options.map((option) => {
                                                return (
                                                    <Paper key={option.uuid} sx={{ p: 1, borderLeft: option.is_correct ? "5px solid green" : "5px solid red", mt: 1 }} dangerouslySetInnerHTML={{ __html: option.text }} ></Paper>
                                                );
                                            })}
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item md={12}><Divider /></Grid>
                            </Grid>
                        )}
                        <Grid container sx={{ mb: "20px" }}>
                            <Button onClick={() => handleOpenConfirm(question.uuid)} variant="outlined" startIcon={<DeleteIcon />} sx={{ mr: 1 }} >Remove</Button>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
}
export default QuestionList;