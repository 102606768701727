// material-ui
import { Button, FormControl, Typography } from '@mui/material';

// project import
import MainCard from '../../components/MainCard';
import MainLayout from '../../layout/MainLayout';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import UserForm from './forms/UserForm';

// ==============================|| SAMPLE PAGE ||============================== //

const AddNewUser = () => (
    <MainLayout>
        <MainCard title="Add New User">
            <UserForm />
        </MainCard>
    </MainLayout>
);

export default AddNewUser;
