import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from "@mui/material";


const DeleteDialog = ({ open, handleClose, handleDeleteAction, message, title }) => {
    const theme = useTheme();                
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{ title }</DialogTitle>
            <DialogContent>
                <DialogContentText>{ message }</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} >
                    No
                </Button>
                <Button autoFocus onClick={handleDeleteAction} >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteDialog;