import MainCard from '../../components/MainCard';
import MainLayout from '../../layout/MainLayout';
import UserTable from "./UserTable";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';


const Users = () => (
    <MainLayout>
        <MainCard title="User List">
            <Box sx={{ m:1, mb:4 }} display="flex" justifyContent="flex-end">
                <Button component={Link} to="/users/new" variant="contained" size="small">Add User</Button>                
            </Box>
            <UserTable />
        </MainCard>
    </MainLayout>
);

export default Users;
