import { call, put, select } from "redux-saga/effects";
import { createAlert, fullScreenLoading } from "../../reducers/util";
import { addStudentsToTestRequest, createQuestionRequest, createTestRequest, getQuestionRequest, getQuestionsRequest, getTestQuestionsRequest, getTestRequest, getTestStudentsRequest, getTestStudentsUnAssignedRequest, getTestsRequest, importQuestionRequest, removeStudentRequest, removeTestQuestionsRequest, requestUnlinkQuestion, updateQuestionRequest, updateTestRequest } from "../requests/test";
import { getQuestions, getStudents, getUnAssignedStudents, importFailed, importSuccess, setImportedQuestions, setQuestion, setQuestions, setStudents, setTest, setTests, setUnAssignedStudents } from "../../reducers/test";
import { ConstructionOutlined } from "@mui/icons-material";

const getToken = (state) => state.user;


export function* handleCreateTest(action) {
    try{
        yield put(fullScreenLoading(true));
        const { token } = yield select(getToken);            
        const response = yield call(createTestRequest, { test: action.test, token: token });                
        if (response && response.status === "success") {
          const status = "success";
          let { message, uuid } = response;
          yield put(createAlert(status, message));
          const { navigate } = action;
          navigate('/tests/view/' + uuid);      
          yield put(fullScreenLoading(false));
        } else {
          const status = "error";
          let { message } = response?response:{message:"Something went wrong!"};
          message = message == "" ? "Something went wrong!" : message;
          yield put(createAlert(status, message));
          yield put(fullScreenLoading(false));
        }
    }catch(err){
        yield put(createAlert("error", err.message));
        yield put(fullScreenLoading(false));        
    }    
}

export function* handleUpdateTest(action) {    
    try{
        yield put(fullScreenLoading(true));
        const { token } = yield select(getToken);            
        const response = yield call(updateTestRequest, { test: action.test, token: token });                
        if (response && response.status === "success") {        
            yield put(createAlert("success", response.message));
            const { navigate } = action;
            navigate(`/tests/view/${action.test.uuid}`);
            yield put(fullScreenLoading(false));
        } else {
            const { message = "Something went wrong!" } = response || {};            
            yield put(createAlert("error", message));
            yield put(fullScreenLoading(false));
        }
    }catch(err){
        yield put(createAlert("error", err.message));
        yield put(fullScreenLoading(false));        
    }    
}

export function* handleGetTest(action) {
    try{
        yield put(fullScreenLoading(true));
        const { token } = yield select(getToken);            
        const response = yield call(getTestRequest, { uuid: action.uuid, token: token });
        if (response && response.status === "success") {        
           yield put(fullScreenLoading(false));
           yield put(setTest(response.result));        
        } else {
          const status = "error";
          let { message } = response?response:{message:"Something went wrong!"};
          message = message == "" ? "Something went wrong!" : message;
          yield put(createAlert(status, message));
          yield put(fullScreenLoading(false));
        }
    }catch(err){
        yield put(createAlert("error", err.message));        
    }    
}

export function* handleGetTests(action){
    try{
        yield put(fullScreenLoading(true));
        const { token } = yield select(getToken);    
        yield put(fullScreenLoading(false));        
        const response = yield call(getTestsRequest, { token: token });        
        if (response && response.status === "success") {        
            yield put(fullScreenLoading(false));
            yield put(setTests(response.results));        
        } else {
            const status = "error";
            let { message } = response?response:{message:"Something went wrong!"};
            message = message == "" ? "Something went wrong!" : message;
            yield put(createAlert(status, message));
            yield put(fullScreenLoading(false));
        }        
    }catch(err){
        yield put(createAlert("error", err.message));
        yield put(fullScreenLoading(false));        
    } 
}

export function* handleCreateQuestion(action) {    
    try{        
        yield put(fullScreenLoading(true));
        const { token } = yield select(getToken);    
        const response =  yield call (createQuestionRequest, { question: action.question, token: token });                
        if (response && response.status === "success") {
          const status = "success";
          let { message } = response;
          yield put(createAlert(status, message));
          const { navigate } = action;
          navigate('/questions');      
          yield put(fullScreenLoading(false));
        } else {
          const status = "error";
          let { message } = response?response:{message:"Something went wrong!"};
          message = message === "" ? "Something went wrong!" : message;
          yield put(createAlert(status, message));
          yield put(fullScreenLoading(false));
        }
    }catch(err){
        yield put(createAlert("error", err.message));
        yield put(fullScreenLoading(false));        
    }    
}

export function* handleGetQuestions(action) {        
    try{
        yield put(fullScreenLoading(true));
        const { token } = yield select(getToken);    
        const { testId } = action;
        yield put(fullScreenLoading(false));        
        const response = yield call(getQuestionsRequest, { token: token, uuid:testId });        
        if (response && response.status === "success") {        
            yield put(fullScreenLoading(false));            
            yield put(setQuestions(response.results));        
        } else {
            const status = "error";
            let { message } = response?response:{message:"Something went wrong!"};
            message = message == "" ? "Something went wrong!" : message;
            yield put(createAlert(status, message));
            yield put(fullScreenLoading(false));
        }        
    }catch(err){
        yield put(createAlert("error", err.message));
        yield put(fullScreenLoading(false));        
    } 
}

export function* handleUnlinkQuestion(action) {    
    try{
        yield put(fullScreenLoading(true));
        const { token } = yield select(getToken);    
        const { testId, questID } = action;                        
        const response = yield call(requestUnlinkQuestion, { token: token, tests_id: testId, questions_id: questID });                        
        if (response && response.status === "success" ) {        
            yield put(getQuestions(testId));
            yield put(fullScreenLoading(false));            
            yield put(createAlert("success", response.message));
        } else {
            const status = "error";
            let { message } = response?response:{message:"Something went wrong!"};
            message = message == "" ? "Something went wrong!" : message;            
            yield put(createAlert(status, message));            
            yield put(fullScreenLoading(false));
        }        
    }catch(err){
        yield put(createAlert("error", err.message));
        yield put(fullScreenLoading(false));        
    } 
}

export function* handleGetQuestion(action) {
    try {
        yield put(fullScreenLoading(true));
        const { token } = yield select(getToken);    
        const { questionId } = action;
        const response = yield call(getQuestionRequest, { token: token, uuid: questionId });                        
        if (response && response.status === "success" ) {                    
            yield put(fullScreenLoading(false));   
            yield put(setQuestion(response.results));                                         
        } else {
            const { message = "Something went wrong!" } = response || {};            
            yield put(createAlert("error", message));
            yield put(fullScreenLoading(false));
        }         
    }catch(err){
        yield put(createAlert("error", err.message));
        yield put(fullScreenLoading(false));
    }
}

export function* handleUpdateQuestion(action) {
    try{        
        const { token } = yield select(getToken);                    
        const response =  yield call (updateQuestionRequest, { question: action.question, token: token });                
        if (response && response.status === "success") {                    
            yield put(createAlert("success", response.message));            
            const { navigate } = action;
            navigate(`/questions/view/${action.question.uuid}`);            
        } else {
            const { message = "Something went wrong!" } = response || {};            
            yield put(createAlert("error", message));            
        }
    }catch(err){
        yield put(createAlert("error", err.message));        
    }    
}

export function* handleAddStudents(action) {
    try{        
        const { token } = yield select(getToken);
        const { students } = action;
        const response =  yield call (addStudentsToTestRequest, { students, token: token });                
        if (response && response.status === "success") {     
            const { test_id } = students;            
            yield put(createAlert("success", response.message));            
            yield put(getStudents(test_id)); 
            yield put(getUnAssignedStudents(test_id));                                    
        } else {
            const { message = "Something went wrong!" } = response || {};            
            yield put(createAlert("error", message));            
        }
    }catch(err){
        yield put(createAlert("error", err.message));        
    } 
}

export function* handlegetTestStudents (action){
    try{        
        const { token } = yield select(getToken);
        const { testId } = action;        
        const response =  yield call (getTestStudentsRequest, { testId, token: token });                
        if (response && response.status === "success") {                                
            if(response.results && response.results.length > 0){                
                yield put(setStudents(response.results));            
            }                        
        } else {
            const { message = "Something went wrong!" } = response || {};            
            yield put(createAlert("error", message));            
        }
    }catch(err){
        yield put(createAlert("error", err.message));        
    } 
}

export function* handlegetTestStudentsUnAssigned(action){
    try{        
        const { token } = yield select(getToken);
        const { testId } = action;        
        const response =  yield call (getTestStudentsUnAssignedRequest, { testId, token: token });                
        if (response && response.status === "success") {                                
            if(response.results && response.results.length > 0){                
                yield put(setUnAssignedStudents(response.results));            
            }                        
        } else {
            const { message = "Something went wrong!" } = response || {};            
            yield put(createAlert("error", message));            
        }
    }catch(err){
        yield put(createAlert("error", err.message));        
    } 
}

export function* handleRemoveStudent (action){
    try{        
        const { token } = yield select(getToken);
        const { uuid, testId } = action;        
        const response =  yield call (removeStudentRequest, { uuid, token: token });                
        if (response && response.status === "success") {                                
            yield put(createAlert("success", response.message));                                             
            yield put(getStudents(testId)); 
            yield put(getUnAssignedStudents(testId));  
        } else {
            const { message = "Something went wrong!" } = response || {};            
            yield put(createAlert("error", message));            
        }
    }catch(err){
        yield put(createAlert("error", err.message));        
    } 
}

export function* handleImportQuestions(action){
    try{        
        const { token } = yield select(getToken);
        const { importInput } = action;        
        const response =  yield call (importQuestionRequest, { importInput, token: token });                
        if (response && response.status === "success") {                                
            yield put(importSuccess(response.message));            
        } else {
            const { message = "Something went wrong!" } = response || {};            
            yield put(importFailed(message));            
        }
    }catch(err){
        yield put(importFailed(err.message));        
    } 
}

export function* handleGetTestQuestions(action){
    try{        
        const { token } = yield select(getToken);
        const { test_id, section_id } = action;                
        const response =  yield call (getTestQuestionsRequest, { test_id, section_id, token: token });                
        if (response && response.status === "success") {                                
             yield put(setImportedQuestions(response.results));
        } else {
            const { message = "Something went wrong!" } = response || {};            
            yield put(createAlert("error", message));        
        }
    }catch(err){
        yield put(createAlert("error", err.message));           
    } 
}

export function* handleRemoveTestQuestion(action){
    try{        
        const { token } = yield select(getToken);
        const { input } = action;                
        const response =  yield call (removeTestQuestionsRequest, { input, token: token });                
        if (response && response.status === "success") {                                            
            yield put(importSuccess(response.message));            
        } else {
            const { message = "Something went wrong!" } = response || {};            
            yield put(importFailed(message));            
        }
    }catch(err){
        yield put(importFailed(err.message));        
    } 
}