import { setGoBackLink } from "../../redux/reducers/util";
import { useParams } from "react-router-dom";
import Layout from "../layout/Layout";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Chart } from "react-google-charts";
import {
  Badge,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { styled } from "@mui/system";
import QuizIcon from "@mui/icons-material/Quiz";
import "./TestResults.css";
import { getStudentResults } from "../../redux/reducers/studentTest";
import Results from "../../pages/tests/results";

function TabPanel(props) {
  const { children, value, activeTab } = props;

  if (value !== activeTab) {
    return <div hidden={true}></div>;
  }
  return <Box sx={{ pt: "30px" }}>{children}</Box>;
}

const ResponsiveChart = styled(Chart)`
  width: 100%;
  height: 400px;

  @media (max-width: 960px) {
    height: 200px;
  }

  @media (max-width: 600px) {
    height: 300px;
  }
`;

const TestResults = () => {
  const { testId } = useParams();
  const [selectedTab, setSelectedTab] = useState("result_summary");
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { studentResults, isStudentResultsLoading } = useSelector(
    (state) => state.studentTest
  );
  const { id } = useSelector((state) => state.user);
  const [test, setTest] = useState(null);
  const [testStudent, setTestStudent] = useState(null);
  const [testSections, setTestSections] = useState(null);
  const [studentAnswers, setStudentAnswers] = useState(null);

  useEffect(() => {
    dispatch(setGoBackLink(`/test/${testId}`));
    dispatch(getStudentResults(testId));
  }, []);

  useEffect(() => {
    if (studentResults) {
      if (studentResults?.test) {
        setTest(studentResults.test);
      }
      if (studentResults?.testStudent) {
        setTestStudent(studentResults.testStudent);
      }
      if (studentResults?.testSections) {
        setTestSections(studentResults.testSections);
      }
      if (studentResults?.studentAnswers) {
        setStudentAnswers(studentResults.studentAnswers);
      }
    }
  }, [studentResults]);

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal);
  };

  if (!test || !studentResults || !studentAnswers) {
    return <>...</>;
  }

  const getSectionCorrectCount = (section) => {
    let CorrectAns = 0;
    for (let i = 0; i < studentAnswers.length; i++) {
      const ans = studentAnswers[i];
      if (ans.section_id === section.id && ans.isCorrect === "1") CorrectAns++;
    }
    return CorrectAns;
  };

  const getSectionInCorrectCount = (section) => {
    let InCorrectAns = 0;
    for (let i = 0; i < studentAnswers.length; i++) {
      const ans = studentAnswers[i];
      if (ans.section_id === section.id && ans.isCorrect === "0")
        InCorrectAns++;
    }
    return InCorrectAns;
  };

  let correctAnswers = 0;
  let InCorrectAnswers = 0;

  for (let i = 0; i < studentAnswers.length; i++) {
    const ans = studentAnswers[i];    
    if (Boolean(ans.attempted) === true) {
      if (ans.isCorrect === "1") {
        correctAnswers++;
      } else {
        InCorrectAnswers++;
      }
    }
  }

  const difference =
    new Date(testStudent.completed_at) - new Date(testStudent.started_at);

  const differenceInMinutes = difference / (1000 * 60);
  const totalQuestions = studentResults?.testQuestions?.length;
  const unattempted = totalQuestions - (correctAnswers + InCorrectAnswers);
  return (
    <Layout>
      {isStudentResultsLoading ? (
        <Box sx={{ textAlign: "center", mt: 15 }}>
          <CircularProgress size={12} /> Loading results
        </Box>
      ) : (
        <Grid
          container
          sx={{
            p: isSmallScreen ? 0 : 15,
            pt: 5,
            pl: 2,
            backgroundColor: "#FFF",
          }}
        >
          <Box sx={{ width: "100vw" }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="Tabs"
            // orientation="horizontal"
            >
              <Tab value="result_summary" label="Result summary" />
              <Tab
                value="result_with_correct_ans"
                label="Result with correct answers"
              />
              {test?.section_wise === 1 && (
                <Tab value="result_section_wise" label="Section Wise" />
              )}
              <Tab value="result_rank_list" label="Rank List" />
            </Tabs>
            <TabPanel
              value="result_summary"
              activeTab={selectedTab}
              sx={{ width: "100%" }}
            >
              <Grid
                container
                sx={{ p: isSmallScreen ? 0 : 10, pt: 5, width: "100%" }}
              >
                <Grid item md={12} sx={{ width: "100%" }}>
                  <Paper elevation={3} sx={{ mb: 5, p: 2 }}>
                    <Divider sx={{ mt: 3, fontWeight: "bold" }}>
                      Test Summary
                    </Divider>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              sx={{ fontWeight: "bold", width: "240px" }}
                            >
                              Maximum score
                            </TableCell>
                            <TableCell sx={{ textAlign: "left" }}>
                              {studentResults?.test?.max_score}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>
                              Minimum Score
                            </TableCell>
                            <TableCell>
                              {studentResults?.test?.min_score}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>
                              Total Questions
                            </TableCell>
                            <TableCell>
                              {studentResults?.test?.min_score}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>
                              Negative Marks
                            </TableCell>
                            <TableCell>
                              {studentResults?.test?.negative_mark}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>
                              Maximum Time Allowed
                            </TableCell>
                            <TableCell>
                              {studentResults?.test?.max_time} Minutes
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Divider sx={{ mt: 3, fontWeight: "bold" }}>
                      Result Summary
                    </Divider>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              sx={{ fontWeight: "bold", width: "240px" }}
                            >
                              Correct answers
                            </TableCell>
                            <TableCell>{correctAnswers}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>
                              Incorrect answers
                            </TableCell>
                            <TableCell>{InCorrectAnswers}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>
                              Unattempted Questions
                            </TableCell>
                            <TableCell>{unattempted}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>
                              Total score
                            </TableCell>
                            <TableCell>{testStudent.score}</TableCell>
                          </TableRow>
                          {test.negative_mark > 0 && (
                            <TableRow>
                              <TableCell sx={{ fontWeight: "bold" }}>
                                Total Negative marks
                              </TableCell>
                              <TableCell>
                                {(
                                  test.negative_mark * InCorrectAnswers
                                ).toFixed(2)}
                              </TableCell>
                            </TableRow>
                          )}
                          <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>
                              Total Time taken
                            </TableCell>
                            <TableCell>
                              {differenceInMinutes.toFixed(2)} Minutes
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
                <Grid item md={12} sx={{ width: "100%" }}>
                  <Paper elevation={3} sx={{ mb: 5 }}>
                    <ResponsiveChart
                      chartType="PieChart"
                      data={[
                        ["Title", "Percentage"],
                        ["Correct answers", correctAnswers],
                        ["Incorrect answers", InCorrectAnswers],
                      ]}
                      options={{
                        title: "Total",
                      }}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="result_with_correct_ans" activeTab={selectedTab}>
              {studentAnswers?.map((answer) => {
                const isQuestionPassed = answer.isCorrect === "1";
                let questionOptions = answer.option_answers
                  ? JSON.parse(answer.option_answers)
                  : [];

                return (
                  <Paper
                    key={answer.uuid}
                    elevation={2}
                    sx={{
                      minHeight: "210px",
                      borderLeft: isQuestionPassed
                        ? "15px solid green"
                        : "15px solid red",
                      p: 2,
                      mb: 5,
                    }}
                  >
                    <Box
                      sx={{ fontSize: "15px" }}
                      dangerouslySetInnerHTML={{
                        __html: answer?.quest_description,
                      }}
                    ></Box>
                    {answer?.quest_explanation && (
                      <Divider
                        sx={{ mb: 1, mt: 2, color: "#9e9e9e" }}
                        textAlign="left"
                      >
                        Explanation
                      </Divider>
                    )}
                    {answer?.quest_explanation && (
                      <Box
                        sx={{ fontSize: "15px" }}
                        dangerouslySetInnerHTML={{
                          __html: answer?.quest_explanation,
                        }}
                      ></Box>
                    )}
                    {answer?.option_answers && (
                      <>
                        <Divider
                          sx={{ mb: 1, mt: 2, color: "#9e9e9e" }}
                          textAlign="left"
                        >
                          Options
                        </Divider>
                        {questionOptions.map((option) => {
                          return (
                            <Paper
                              key={option.uuid}
                              elevation={3}
                              className={` question-option ${option.checked && option.is_correct === 1
                                  ? "correct"
                                  : ""
                                }`}
                            >
                              {option.checked && option.is_correct === 1 && (
                                <span className="qo-badge qo-correct-ans">
                                  Correct answer
                                </span>
                              )}
                              {!option.checked && option.is_correct === 1 && (
                                <span className="qo-badge qo-correct-ans-nc">
                                  Correct answer
                                </span>
                              )}
                              {option.checked && option.is_correct !== 1 && (
                                <span className="qo-badge inqo-correct-ans">
                                  Incorrect answer
                                </span>
                              )}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: option?.text,
                                }}
                              ></span>
                            </Paper>
                          );
                        })}
                      </>
                    )}
                  </Paper>
                );
              })}
            </TabPanel>
            <TabPanel value="result_section_wise" activeTab={selectedTab}>
              <Grid
                container
                sx={{ p: isSmallScreen ? 0 : 10, pt: 5, width: "100%" }}
              >
                {testSections.map((section) => (
                  <Grid
                    key={section.uuid}
                    item
                    md={6}
                    xs={12}
                    sx={{ width: "100%" }}
                  >
                    <Paper elevation={3} sx={{ mb: 5 }}>
                      <ResponsiveChart
                        chartType="PieChart"
                        data={[
                          ["Title", "Percentage"],
                          ["Correct answers", getSectionCorrectCount(section)],
                          [
                            "Incorrect answers",
                            getSectionInCorrectCount(section),
                          ],
                        ]}
                        options={{
                          title: section.name,
                        }}
                      />
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </TabPanel>
            <TabPanel value="result_rank_list" activeTab={selectedTab}>
              <Results testId={testId} studentId={id} />
            </TabPanel>
          </Box>
        </Grid>
      )}
    </Layout>
  );
};

export default TestResults;
