import { call, put, select } from "redux-saga/effects";
import { createCategoryRequest, getCategoryRequest, listCategoryRequest, removeCategoryRequest, updateCategoryRequest } from "../requests/category";
import { categoryLoading, setCategories, setCategory } from "../../reducers/category";
import { createAlert } from "../../reducers/util";
import { deleteQuestionsRequest, getAllCategoriesRequest, getQuestionsCountRequest, getQuestionsRequest, searchQuestionRequest } from "../requests/questions";
import { setAllCategories, setQuestions, setQuestionsCount, setSearchQuestions } from "../../reducers/questions";
const getToken = (state) => state.user;


export function* handleGetQuestions(action) {                
    const { token } = yield select(getToken);        
    const { params } = action;    
    const response = yield call(getQuestionsRequest, { params, token: token });                    
    if(response.status === 'success'){                      
        yield put(setQuestionsCount(response.total));             
        yield put(setQuestions(response.results));             
    }else{
        yield put(createAlert("error", "Something went wrong"));        
    }
}

export function* handleGetQuestionsCount(action) {            
    const { token } = yield select(getToken);        
    const response = yield call(getQuestionsCountRequest, { token: token });                
    if(response.status === 'success'){                
        yield put(setQuestionsCount(response.count));             
    }else{
        yield put(createAlert("error", "Something went wrong"));        
    }
}

export function* handleDeleteQuestion(action) {            
    const { uuid, navigate }= action;
    const { token } = yield select(getToken);        
    const response = yield call(deleteQuestionsRequest, { uuid, token: token });                
    if(response.status === 'success'){                
        navigate(`/questions`);                    
    }else{
        yield put(createAlert("error", "Something went wrong"));        
    }
}

export function* handleGetAllCategories(action) {                
    const { token } = yield select(getToken);        
    const response = yield call(getAllCategoriesRequest, { token: token });                    
    if(response.status === 'success'){       
        yield put(setAllCategories(response.results));                                      
    }else{
        yield put(createAlert("error", "Something went wrong"));        
    }
}

export function* handleSearchQuestions(action) {
    const { filter } = action;
    const { token } = yield select(getToken);            
    const response = yield call(searchQuestionRequest, { filter, token: token });
    if(response.status === 'success'){                                             
        yield put(setSearchQuestions(response.results));
    }else{                
        yield put(createAlert("error", "Something went wrong"));        
    }
}
