import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export const useAuth = () => {
  const dd = useSelector((state) => state.user);    
  const { token } = useSelector((state) => state.user);  
  return token !== null;
};

const ProtectedRoutes = () => {
  const session = useSelector((state) => state.user);       
  if(session?.token){
    if(session?.is_mobile_verified+"" !== "1" ){
      return <Navigate to="/verify" />;
    }
    return <Outlet />;
  }
  return <Navigate to="/" />;
};

export default ProtectedRoutes;
