/** Actions */
export const GET_QUESTIONS = "GET_QUESTIONS";
export const GET_QUESTIONS_COUNT = "GET_QUESTIONS_COUNT";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const QUESTIONS_SEARCH = "QUESTIONS_SEARCH";

const SET_QUESTIONS = "SET_QUESTIONS";
const SET_QUESTIONS_COUNT = "SET_QUESTIONS_COUNT";
const SET_ALL_CATEGORIES = "SET_ALL_CATEGORIES";
const SET_QUESTIONS_SEARCH = "SET_QUESTIONS_SEARCH";
const CONVERT_DOCX_JSON = "CONVERT_DOCX_JSON";

const initialState = {                
    loading: false,
    questions: [],
    questionsCount: null,
    allCategories: [],
    searchLoading: false,
    filteredQuestions: []
};

export const convertDocxToQuestions = (file) => ({
    type: CONVERT_DOCX_JSON,    
});

export const getQuestions = (params) => ({
    type: GET_QUESTIONS,
    params
});

export const setQuestions = (questions) => ({
    type: SET_QUESTIONS,
    questions
});

export const getQuestionsCount = () => ({
    type: GET_QUESTIONS_COUNT    
});

export const setQuestionsCount = (count) => ({
    type: SET_QUESTIONS_COUNT,
    count    
});

export const deleteQuestion = (uuid, navigate) => ({
    type: DELETE_QUESTION,
    uuid,
    navigate
});

export const getAllCategories = () => ({
    type: GET_ALL_CATEGORIES
});

export const setAllCategories = (categories) => ({
    type: SET_ALL_CATEGORIES,
    categories
});

export const searchQuestions = (filter) => ({
    type: QUESTIONS_SEARCH,
    filter
});

export const setSearchQuestions = (questions) => ({
    type: SET_QUESTIONS_SEARCH,
    questions
});

const categoryReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_QUESTIONS_COUNT:
            return { ...state, loading: true, questionsCount: null };
        case SET_QUESTIONS_COUNT:
            return { ...state, loading: false, questionsCount: action.count };
        case GET_QUESTIONS:
            return { ...state, loading: true };
        case SET_QUESTIONS:
            return { ...state, loading: false, questions: action.questions };
        case SET_ALL_CATEGORIES:
            return { ...state, allCategories: action.categories };
        case QUESTIONS_SEARCH:
            return { ...state, searchLoading: true };
        case SET_QUESTIONS_SEARCH: 
            return { ...state, searchLoading: false, filteredQuestions: action.questions };
        default: 
            return state;
    }
}

export default categoryReducer;