import { takeEvery } from "redux-saga/effects";
import { handleCreateUser, handleGetUsers, handleLoginUser, handleGetUserById, handleUpdateUser, handleUpdateUserPassword, handleDeleteUser } from "./handlers/user";
import { LOGIN_USER, CREATE_USER, GET_USER_LIST, GET_USER_BY_ID, UPDATE_USER, UPDATE_PASSWORD_ADMIN, DELETE_USER } from "../reducers/user";
import { CREATE_STUDENT, DELETE_STUDENT, GET_STUDENT_BY_ID, GET_STUDENT_LIST, STUDENT_UPDATE_PASSWORD, UPDATE_STUDENT } from "../reducers/student";
import { handleCreateStudent, handleDeleteStudent, handleGetStudentById, handleListStudent, handleUpdateStudent, handleUpdateStudentPassword } from "./handlers/student";
import { ADD_STUDENTS, CREATE_QUESTION, CREATE_TEST, GET_IMPORTED_QUESTIONS, GET_QUESTION, GET_STUDENTS_FOR_TEST, GET_TEST_BY_ID, GET_TEST_LIST, GET_UN_ASSIGNED_STUDENTS, IMPORT_QUESTIONS, REMOVE_STUDENTS, REMOVE_TEST_QUESTION, UNLINK_QUESTION, UPDATE_QUESTION, UPDATE_TEST } from "../reducers/test";
import { handleAddStudents, handleCreateQuestion, handleCreateTest, handleGetQuestion, handleGetTest, handleGetTestQuestions, handleGetTests, handleImportQuestions, handleRemoveStudent, handleRemoveTestQuestion, handleUnlinkQuestion, handleUpdateQuestion, handleUpdateTest, handlegetTestStudents, handlegetTestStudentsUnAssigned } from "./handlers/test";
import { GET_STUDENT_RESULTS, GET_TEST, GET_TESTS, START_PRACTICE_TEST, START_TEST, SUBMIT_TEST_ANSWERS } from "../reducers/studentTest";
import { handleGetStudentResults, handlePracticeTestStart, handleStudentGetTest, handleStudentGetTests, handleSubmitTestAnswers, handleTestStart } from "./handlers/studentTest";
import { CREATE_CATEGORY, GET_CATEGORIES, GET_CATEGORY, REMOVE_CATEGORY, UPDATE_CATEGORY } from "../reducers/category";
import { handleCreateCategory, handleGetCategories, handleGetCategory, handleRemoveCategory, handleUpdateCategory } from "./handlers/category";
import { DELETE_QUESTION, GET_ALL_CATEGORIES, GET_QUESTIONS, GET_QUESTIONS_COUNT, QUESTIONS_SEARCH } from "../reducers/questions";
import { handleDeleteQuestion, handleGetAllCategories, handleGetQuestions, handleGetQuestionsCount, handleSearchQuestions } from "./handlers/questions";
import { CREATE_SECTION, GET_SECTION, GET_SECTIONS, REMOVE_SECTION, UPDATE_SECTION } from "../reducers/section";
import { handleCreatesection, handleGetSection, handleGetSections, handleRemoveSection, handleUpdateSection } from "./handlers/sections";

export function* watcherSaga() {
  yield takeEvery(LOGIN_USER, handleLoginUser);
  yield takeEvery(CREATE_USER, handleCreateUser);
  yield takeEvery(GET_USER_LIST, handleGetUsers);
  yield takeEvery(GET_USER_BY_ID, handleGetUserById);
  yield takeEvery(UPDATE_USER, handleUpdateUser);
  yield takeEvery(UPDATE_PASSWORD_ADMIN, handleUpdateUserPassword);
  yield takeEvery(DELETE_USER, handleDeleteUser);

  /** Student */
  yield takeEvery(CREATE_STUDENT, handleCreateStudent);
  yield takeEvery(GET_STUDENT_LIST, handleListStudent);
  yield takeEvery(GET_STUDENT_BY_ID, handleGetStudentById);
  yield takeEvery(UPDATE_STUDENT, handleUpdateStudent);
  yield takeEvery(STUDENT_UPDATE_PASSWORD, handleUpdateStudentPassword);
  yield takeEvery(DELETE_STUDENT, handleDeleteStudent);
  yield takeEvery(GET_STUDENT_RESULTS, handleGetStudentResults);


  /** Tests */
  yield takeEvery(GET_TEST_BY_ID, handleGetTest);
  yield takeEvery(GET_TEST_LIST, handleGetTests);
  yield takeEvery(CREATE_TEST, handleCreateTest);
  yield takeEvery(CREATE_QUESTION, handleCreateQuestion);
  //  yield takeEvery(GET_QUESTIONS, handleGetQuestions);   
  yield takeEvery(UNLINK_QUESTION, handleUnlinkQuestion);
  yield takeEvery(GET_QUESTION, handleGetQuestion);
  yield takeEvery(UPDATE_QUESTION, handleUpdateQuestion);
  yield takeEvery(UPDATE_TEST, handleUpdateTest);
  yield takeEvery(ADD_STUDENTS, handleAddStudents);
  yield takeEvery(GET_STUDENTS_FOR_TEST, handlegetTestStudents);
  yield takeEvery(GET_UN_ASSIGNED_STUDENTS, handlegetTestStudentsUnAssigned);
  yield takeEvery(REMOVE_STUDENTS, handleRemoveStudent);


  /** Student tests */
  yield takeEvery(GET_TESTS, handleStudentGetTests);
  yield takeEvery(GET_TEST, handleStudentGetTest);
  yield takeEvery(START_TEST, handleTestStart);
  yield takeEvery(START_PRACTICE_TEST, handlePracticeTestStart);
  yield takeEvery(SUBMIT_TEST_ANSWERS, handleSubmitTestAnswers);


  /* Category */
  yield takeEvery(CREATE_CATEGORY, handleCreateCategory);
  yield takeEvery(GET_CATEGORIES, handleGetCategories);
  yield takeEvery(GET_CATEGORY, handleGetCategory);
  yield takeEvery(UPDATE_CATEGORY, handleUpdateCategory);
  yield takeEvery(REMOVE_CATEGORY, handleRemoveCategory);

  /** Questions */
  yield takeEvery(GET_QUESTIONS, handleGetQuestions);
  yield takeEvery(GET_QUESTIONS_COUNT, handleGetQuestionsCount);
  yield takeEvery(DELETE_QUESTION, handleDeleteQuestion);
  yield takeEvery(GET_ALL_CATEGORIES, handleGetAllCategories);


  /** SECTION */
  yield takeEvery(CREATE_SECTION, handleCreatesection);
  yield takeEvery(GET_SECTIONS, handleGetSections);
  yield takeEvery(GET_SECTION, handleGetSection);
  yield takeEvery(UPDATE_SECTION, handleUpdateSection);
  yield takeEvery(REMOVE_SECTION, handleRemoveSection);

  /* IMPORT QUESTIONS */
  yield takeEvery(QUESTIONS_SEARCH, handleSearchQuestions);
  yield takeEvery(IMPORT_QUESTIONS, handleImportQuestions);
  yield takeEvery(GET_IMPORTED_QUESTIONS, handleGetTestQuestions);
  yield takeEvery(REMOVE_TEST_QUESTION, handleRemoveTestQuestion);


}

