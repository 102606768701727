import axios from "axios";
import { env } from "../../../config";

export function verifyUserLogin(credentials) {
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/auth/signin/', {
            username: credentials.username,
            password: credentials.password,
        })
            .then(function (response) {
                if (response && response.data && response.data.status === "success") {
                    const { firstName, LastName, role, token, id, is_mobile_verified, mobile } = response.data;                    
                    resolve({
                        status: 'success',
                        token: token,
                        name: firstName,
                        role: role,
                        is_mobile_verified: is_mobile_verified,
                        mobile,
                        id: id
                    });
                } else if (response && response.data && response.data.status === "error") {
                    resolve({
                        status: 'failed',
                        message: 'Invalid username or password!'
                    });
                } else {
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });
                }
            })
            .catch(function (error) {
                reject({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}


export function createUserRequest(request) {    
    const { user, token } = request;    
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/user/create', user, { headers: headers })
            .then(function (response) {
                if(response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                        result: response.data.result
                    });
                }else{
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });    
                }                
            })
            .catch(function (error) {
                reject({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function getUserList(request) {    
    const { token } = request;    
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.get(env.REACT_APP_API_HOST + '/api/access/user/list', { headers: headers })
            .then(function (response) {
                if(response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                        result: response.data.result
                    });
                }else{
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });    
                }                
            })
            .catch(function (error) {
                reject({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function getUser(request) {    
    const { token, userId } = request;        
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/user/get', { userId: userId }, { headers: headers })
            .then(function (response) {
                if(response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                        result: response.data.result
                    });
                }else{
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });    
                }                
            })
            .catch(function (error) {
                reject({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function updateUser(request) {    
    const { token, user } = request;        
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/user/update', user, { headers: headers })
            .then(function (response) {
                if(response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                        result: response.data.result
                    });
                }else{
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });    
                }                
            })
            .catch(function (error) {
                reject({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function updateUserPassword(request) {    
    const { token, user } = request;        
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/user/passwordupdate', { userId: user.userId, password: user.passwordUpdate }, { headers: headers })
            .then(function (response) {
                if(response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                        result: response.data.result
                    });
                }else{
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });    
                }                
            })
            .catch(function (error) {
                reject({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function deleteUserRequest(request) {        
    const { userId, token } = request;    
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/user/delete', {userId:userId}, { headers: headers })
            .then(function (response) {
                if(response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message
                    });
                }else{
                    resolve({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });    
                }                
            })
            .catch(function (error) {
                resolve({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}