import { useEffect, useState, useRef, forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";

// material-ui
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Link,
  IconButton,
  InputAdornment,
  Typography,
  TextField,
  Alert,
  Container,
  CssBaseline,
  LinearProgress,
  CircularProgress,
  Stack,
} from "@mui/material";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import { env } from "../../../config";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import axios from "axios";
import { IndianPhoneInput } from "./AuthRegister";
import ReCAPTCHA from "react-google-recaptcha";
import InputMask from "react-input-mask";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const AuthForgotPassword = () => {
  const session = useSelector((state) => state.user);
  const [error, setError] = useState("");
  const [showOtpScreen, setShowOtpScreen] = useState(false);
  const [showAllSuccess, setShowAllSuccess] = useState(false);
  const [showCreatePassword, setShowCreatePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const recaptchaRef = useRef(null);
  const phoneInputRef = useRef();
  const [resetToken, setResetToken] = useState("");

  const initFormik = useFormik({
    initialValues: {
      mobile: "+91",
      captchaKey: "",
    },
    validationSchema: Yup.object().shape({
      mobile: Yup.string()
        .required("Mobile is required")
        .matches(/^\+91\d{10}$/, "Please enter a valid mobile number"),
      captchaKey: Yup.string().required("Please complete the reCaptcha"),
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        if (loading) return;
        setError("");
        await axios.post(
          env.REACT_APP_API_HOST + "/api/auth/forgot-password",
          values
        );
        setShowOtpScreen(true);
      } catch (err) {
        console.error(err);
        setStatus({ success: false });
        setErrors(err.message ?? "Something went wrong");
        setSubmitting(false);
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
      }
    },
  });

  const Otpformik = useFormik({
    initialValues: {
      otp: "",
      captchaKey: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .matches(/^[0-9]{6}$/, "Enter a valid 6-digit OTP")
        .required("OTP is required"),
      captchaKey: Yup.string().required("Please complete the reCaptcha"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await axios.post(
          env.REACT_APP_API_HOST + "/api/auth/forgot-password-validate",
          {
            otp: values.otp,
            mobile: initFormik.values.mobile,
            captchaKey: values.captchaKey,
          }
        );
        if (response.data.status === "success") {
          setResetToken(response.data.token);
          setShowCreatePassword(true);
        } else {
          setError(error.message ?? "Something went wrong");
          console.error("OTP validation failed:", response.data.message);
        }
      } catch (error) {
        setError(error.response.data.message ?? "Something went wrong");
        console.error("Error during OTP validation:", error.message);
      }
    },
  });

  const changeCatchaValue = (value) => {
    initFormik.setFieldValue("captchaKey", value ? value : "");
  };

  const changeOtpCaptchaValue = (value) => {
    Otpformik.setFieldValue("captchaKey", value ? value : "");
  };

  const createPasswordFormik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("Required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Required"),
    }),
    onSubmit: async (values) => {      
      try {
        const response = await axios.post(
          env.REACT_APP_API_HOST + "/api/auth/forgot-password-create-pw",
          {
            token: resetToken,
            password: values.password,
          }
        );
        if (response.data.status === "success") {
          setShowAllSuccess(true);
        } else {
          setError(error.message ?? "Something went wrong");
          console.error("OTP validation failed:", response.data.message);
        }
      } catch (error) {
        setError(error.response.data.message ?? "Something went wrong");
        console.error("Error during OTP validation:", error.message);
      }
    },
  });

  if (showAllSuccess) {
    return (
      <Box>
        <Alert severity="info" sx={{ mb: 2 }}>
          Success! Your new password has been successfully created.
        </Alert>
        <Link component={RouterLink} to="/logout">
          Click here to Login
        </Link>
      </Box>
    );
  }
  if (showCreatePassword) {
    return (
      <Box
        component="form"
        onSubmit={createPasswordFormik.handleSubmit}
        noValidate
        sx={{ mt: 1 }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={createPasswordFormik.values.password}
          onChange={createPasswordFormik.handleChange}
          error={
            createPasswordFormik.touched.password &&
            Boolean(createPasswordFormik.errors.password)
          }
          helperText={
            createPasswordFormik.touched.password &&
            createPasswordFormik.errors.password
          }
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          id="confirmPassword"
          value={createPasswordFormik.values.confirmPassword}
          onChange={createPasswordFormik.handleChange}
          error={
            createPasswordFormik.touched.confirmPassword &&
            Boolean(createPasswordFormik.errors.confirmPassword)
          }
          helperText={
            createPasswordFormik.touched.confirmPassword &&
            createPasswordFormik.errors.confirmPassword
          }
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Save new password
        </Button>
      </Box>
    );
  }

  if (showOtpScreen) {
    return (
      <Box>
        <Button
          onClick={() => {
            setShowOtpScreen(false);
          }}
          startIcon={<ChevronLeftIcon />}
        >
          Change Number
        </Button>
        <Alert severity="info">
          Thank you for providing your mobile number. We are now checking if
          it's registered with our system. If your number is associated with an
          account, you will receive a One-Time Password (OTP) via SMS shortly.
          Please use this OTP to proceed with resetting your password.
        </Alert>
        <Alert severity="info" sx={{ mt: 1 }}>
          If you do not receive an OTP within the next few minutes, please
          ensure the mobile number entered is correct and try again, or contact
          our support team for assistance.
        </Alert>
        {error && (
          <Box sx={{ mt: 3, mb: 1 }}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Enter OTP
            </Typography>
            <Box
              component="form"
              onSubmit={Otpformik.handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                fullWidth
                id="otp"
                name="otp"
                label="OTP"
                type="text"
                variant="outlined"
                margin="normal"
                autoComplete="off"
                value={Otpformik.values.otp}
                onChange={Otpformik.handleChange}
                onBlur={Otpformik.handleBlur}
                error={Otpformik.touched.otp && Boolean(Otpformik.errors.otp)}
                helperText={Otpformik.touched.otp && Otpformik.errors.otp}
                sx={{ textAlign: "center", fontSize: "18px" }}
              />
              <Stack sx={{ mt: 3, mb: 1 }}>
                <ReCAPTCHA
                  sitekey={env.GOOGLE_SITE_KEY}
                  onChange={changeOtpCaptchaValue}
                />
                {Boolean(Otpformik.errors.captchaKey) && (
                  <Typography sx={{ mt: 1 }} color="error">
                    {Otpformik.errors.captchaKey}
                  </Typography>
                )}
              </Stack>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={Otpformik.submitForm}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    );
  }
  return (
    <Box>
      {error && (
        <Box sx={{ mt: 3, mb: 1 }}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box noValidate sx={{ mt: 1 }}>
            <IndianPhoneInput
              value={initFormik.values.mobile}
              onChange={initFormik.handleChange}
              ref={phoneInputRef}
              name="mobile"
              autoComplete="off"
            />
            {Boolean(initFormik.errors.mobile) && (
              <Typography sx={{ mt: 1 }} color="error">
                {initFormik.errors.mobile}
              </Typography>
            )}
            {/* <TextField
              fullWidth
              label="Registered Mobile Number"
              type="text"
              variant="outlined"
              value={session?.mobile}
              InputProps={{
                readOnly: true,
              }}
            /> */}
            <Stack sx={{ mt: 3, mb: 1 }}>
              <ReCAPTCHA
                sitekey={env.GOOGLE_SITE_KEY}
                onChange={changeCatchaValue}
              />
              {Boolean(initFormik.errors.captchaKey) && (
                <Typography sx={{ mt: 1 }} color="error">
                  {initFormik.errors.captchaKey}
                </Typography>
              )}
            </Stack>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={initFormik.submitForm}
              startIcon={
                loading && <CircularProgress sx={{ color: "#fff" }} size={14} />
              }
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AuthForgotPassword;
