import axios from "axios";
import { env } from "../../../config";

export function getQuestionsRequest(request) {
    const { token, params } = request;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/question/list', { page: params.page, pageSize: params.pageSize, searchQuery: params.searchQuery }, { headers: headers })
            .then(function (response) {
                if (response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                        results: response.data.results,
                        total: response.data.total
                    });
                } else {
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });
                }
            })
            .catch(function (error) {
                reject({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function getQuestionsCountRequest(request) {
    const { token } = request;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.get(env.REACT_APP_API_HOST + '/api/access/question/count', { headers: headers })
            .then(function (response) {
                if (response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        count: response.data.count
                    });
                } else {
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });
                }
            })
            .catch(function (error) {
                reject({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function deleteQuestionsRequest(request) {
    const { token, uuid } = request;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/question/remove', { uuid: uuid }, { headers: headers })
            .then(function (response) {
                if (response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                    });
                } else {
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });
                }
            })
            .catch(function (error) {
                reject({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function getAllCategoriesRequest(request) {
    const { token } = request;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.get(env.REACT_APP_API_HOST + '/api/access/question/allcategories', { headers: headers })
            .then(function (response) {
                if (response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        message: response.data.message,
                        results: response.data.results
                    });
                } else {
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });
                }
            })
            .catch(function (error) {
                reject({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}

export function searchQuestionRequest(request) {
    const { token, filter } = request;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/question/search', filter , { headers: headers })
            .then(function (response) {
                if (response && response.data && response.data.status) {
                    resolve({
                        status: response.data.status,
                        results: response.data.results,
                    });
                } else {
                    reject({
                        status: 'failed',
                        message: 'Something went wrong!'
                    });
                }
            })
            .catch(function (error) {
                reject({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}

export async function convertDocxToQuestions(request) {
    const { token, formData } = request;    
    const headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/readDocx/convert', formData , { headers: headers })
            .then(function (response) {
                if (response && response.data && response.data.success) {
                    resolve({
                        status: 'success',
                        results: response.data.questionsJson,
                    });
                } else {
                    resolve({
                        status: 'failed',
                        message: 'Invalid document!'
                    });
                }
            })
            .catch(function (error) {
                resolve({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}

export async function bulkImportQuestion(request) {
    const { token, importData } = request;    
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axios.post(env.REACT_APP_API_HOST + '/api/access/question/bulkimportquestions', importData , { headers: headers })
            .then(function (response) {
                // console.log("response", response);
                if (response && response.data && response.data.success) {
                    resolve({
                        status: 'success',                        
                    });
                } else {
                    resolve({
                        status: 'failed',
                        message: 'Invalid questions!'
                    });
                }
            })
            .catch(function (error) {
                resolve({
                    status: 'failed',
                    message: 'Something went wrong!'
                });
            });
    });
}