import { Alert, Box, Breadcrumbs, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputAdornment, LinearProgress, Pagination, TextField, Typography } from "@mui/material";
import MainCard from "../../components/MainCard";
import MainLayout from "../../layout/MainLayout";
import React, { useEffect, useState } from "react";
import { ITEM_CATEGORY, ITEM_VIDEOS, ITEM_YOUTUBE } from "../courses/constants";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import SearchIcon from '@mui/icons-material/Search';
import YouTubeIcon from "@mui/icons-material/YouTube";
import "./library.css"
import YoutubeItem from "./components/YoutubeItem";
import axios from "axios";
import { env } from "../../config";
import { useSelector } from "react-redux";
import ItemList from "./components/itemlist/ItemList";
import VideoItem from "./components/VideoItem";
import CategoryIcon from "@mui/icons-material/Category";
import CategoryItem from "./components/CategoryItem";
import { Link, useParams } from "react-router-dom";

export const Library = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [updateItem, setUpdateItem] = useState(null);
  const [openItem, setOpenItem] = useState("");
  const [listLoading, setListLoading] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [libraryItems, setLibraryItems] = useState([]);
  const [deleteItem, setDeleteItem] = useState(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    total: 0
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [categories, setCategories] = useState([]);

  const { cateId } = useParams();

  const headers = {
    "Content-Type": "application/json",
    Authorization: token,
  };

  useEffect(() => {
    fetchCourseItems();
  }, [pagination.currentPage, pagination.pageSize, cateId]);

  const fetchCourseItems = async () => {
    try {
      setListLoading(true)
      let url = `${env.REACT_APP_API_HOST}/api/access/library/list?page=${pagination.currentPage}&pageSize=${pagination.pageSize}&searchTerm=${searchTerm}`;
      if (cateId) {
        url += `&cateId=${cateId}`;
      }
      const { data } = await axios.get(url, { headers: headers });
      setLibraryItems(data.courseLibraries);
      setPagination(prevState => ({
        ...prevState,
        total: data.pagination.total,
      }));

      if (cateId) {
        const { data } = await axios.post(env.REACT_APP_API_HOST + '/api/access/library/get-library-categories',
          {
            uuid: cateId
          },
          {
            headers: headers
          });
        if (data.success && data.categories?.length > 0) {
          setCategories(data.categories.reverse());
        }
      }
    } catch (err) {
      console.error(err)
    } finally {
      setListLoading(false);
    }
  }

  const handleSearch = () => {
    // Trigger search
    setPagination(prevState => ({
      ...prevState,
      currentPage: 1
    }));
    // Fetch items based on search term
    fetchCourseItems();
  };

  const handlePaginationChange = (event, value) => {
    setPagination(prevState => ({
      ...prevState,
      currentPage: value
    }));
  };

  const handleItemClose = () => {
    setOpenItem("");
  };

  const successCallback = (message) => {
    setOpenItem("");
    setSuccessMessage(message);
    fetchCourseItems();
  };

  const handleSetOpenItem = (item) => {
    setUpdateItem(null);
    setOpenItem(item);
  };

  const handleEditLibraryItem = (item) => {
    setUpdateItem(item);
    setOpenItem(item.type);
  }

  const handleSetDeleteLibraryItem = (item) => {
    setDeleteItem(item);
  }
  const handleDeleteDialogClose = () => {
    setDeleteItem(null);
  };

  const handleDeleteConfirm = async () => {
    try {
      setListLoading(true)
      const { data } = await axios.post(
        env.REACT_APP_API_HOST + "/api/access/library/remove", { uuid: deleteItem?.uuid },
        { headers: headers }
      );
      setSuccessMessage("Item has been successfully removed");
    } catch (err) {
      console.error(err``)
    } finally {
      setListLoading(false);
      fetchCourseItems();
      setDeleteItem(null);
    }
  };

  return (
    <MainLayout>
      <MainCard title="Library">
        {successMessage !== "" && (
          <Alert severity="success">{successMessage}</Alert>
        )}
        {errorMessage !== "" && <Alert severity="error">{errorMessage}</Alert>}
        {deleteItem !== null && (
          <Dialog
            open={deleteItem !== null}
            onClose={handleDeleteDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" sx={{ fontWeight: "bold" }}>
              {"Confirm Course Item Deletion"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`Are you sure you want to remove the course item named '${deleteItem?.name ?? ""
                  }'?`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteDialogClose}>No</Button>
              <Button variant="contained" onClick={handleDeleteConfirm}>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {openItem === ITEM_CATEGORY && (
          <CategoryItem
            type={ITEM_CATEGORY}
            open={openItem === ITEM_CATEGORY}
            handleClose={handleItemClose}
            onSuccess={successCallback}
            updateItem={updateItem}
          />
        )}
        <YoutubeItem
          type={ITEM_YOUTUBE}
          open={openItem === ITEM_YOUTUBE}
          handleClose={handleItemClose}
          onSuccess={successCallback}
          updateItem={updateItem}
        />
        {openItem === ITEM_VIDEOS && (
          <VideoItem
            type={ITEM_VIDEOS}
            open={openItem === ITEM_VIDEOS}
            handleClose={handleItemClose}
            onSuccess={successCallback}
            updateItem={updateItem}
          />
        )}
        <Box sx={{ mt: 1, mb: 2 }}>
          {cateId !== undefined && (
            <Breadcrumbs>
              <Link
                className="cate-breadcrumb-link"
                underline="hover"
                color="inherit"
                to={`/library`}
              >
                Root
              </Link>
              {categories.map((category, index) => {
                if (categories.length !== index + 1) {
                  return (
                    <Link
                      key={index}
                      className="cate-breadcrumb-link"
                      underline="hover"
                      color="inherit"
                      to={`/library/${category.uuid}`}
                    >
                      {category.name}
                    </Link>
                  );
                }
                return (
                  <Typography
                    className="cate-breadcrumb-text"
                    key={index}
                    color="text.primary"
                  >
                    {category.name}
                  </Typography>
                );
              })}
            </Breadcrumbs>
          )}
        </Box>
        <Box className="curatoritem-container">
          <span
            onClick={() => handleSetOpenItem(ITEM_CATEGORY)}
            className="curatoritem-menu"
          >
            <span className="curatoritem-menu-c">
              <CategoryIcon className="curatoritem-menu-txt" />
              Category
            </span>
          </span>
          <span
            onClick={() => handleSetOpenItem(ITEM_VIDEOS)}
            className="curatoritem-menu"
          >
            <span className="curatoritem-menu-c">
              <PlayCircleOutlineIcon className="curatoritem-menu-txt" />
              Videos
            </span>
          </span>
          <span
            onClick={() => handleSetOpenItem(ITEM_YOUTUBE)}
            className="curatoritem-menu"
          >
            <span className="curatoritem-menu-c">
              <YouTubeIcon className="curatoritem-menu-txt" />
              Youtube
            </span>
          </span>
        </Box>
        <Box className="curatoritem-listcontainer">
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, mb: 1, ml: 4 }}>
            <TextField
              id="search"
              label="Search"
              variant="outlined"
              value={searchTerm}
              autoComplete="off"
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleSearch} edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {listLoading && <LinearProgress />}
          {libraryItems?.length === 0 && (
            <Box sx={{ p: 2, pl: 4 }} >
              <Alert severity="info" >No item has been added yet</Alert>
            </Box>
          )}
          <ItemList
            libraryItems={libraryItems}
            handleUpdate={handleEditLibraryItem}
            handleDelete={handleSetDeleteLibraryItem}
          />
          <Pagination
            count={Math.ceil(pagination.total / pagination.pageSize)}
            page={pagination.currentPage}
            onChange={handlePaginationChange}
          />
        </Box>
      </MainCard>
    </MainLayout>
  );
}