import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { setGoBackLink } from "../../redux/reducers/util";
import { Alert, Box, Container, Grid, LinearProgress } from "@mui/material";
import CourseCard from "./CourseCard";
import { getStudentCourses } from "../../redux/sagas/requests/course";

export default function Index() {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    dispatch(setGoBackLink(`/`));
    init();
  }, []);

  const init = async () => {
    setLoading(true);
    const response = await getStudentCourses({ token });
    setCourses(response.courses);
    setLoading(false);
  };

  return (
    <Layout>
      {loading && <LinearProgress />}
      <Grid container sx={{ p: 5 }}>
        {courses?.length === 0 && (
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <Container>
                <Alert severity="info">No courses have been assigned yet.</Alert>
            </Container>
          </Grid>
        )}
        {courses.map((course, index) => (
          <Grid key={index} container>
            <Grid item xs={0} sm={0} md={2} xl={3}></Grid>
            <Grid item xs={12} sm={12} md={8} xl={6} sx={{ mb: 2 }}>
              <CourseCard course={course} />
            </Grid>
            <Grid item xs={0} sm={0} md={2} xl={3}></Grid>
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
}
