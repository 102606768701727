import React from 'react';
import { Container, Card, CardContent, Typography, List, ListItem, ListItemText, Button } from '@mui/material';
import { Info, QuestionAnswer, GetApp  } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import docsFile from "../../assets/downloads/doc.docx";

const Instructions = () => {
  return (
    <Container maxWidth="sm">
      <Card sx={{ mt: 4, backgroundColor: '#f0f7fc' }}>
        <CardContent>
          <Typography variant="h5" sx={{ mb: 2 }}>
            <Info sx={{ fontSize: 28, verticalAlign: 'middle', mr: 1 }} /> Document Format Instructions
          </Typography>
          <Typography variant="body1">
            To follow the docx format, use the following guidelines:
          </Typography>
          <List sx={{ mt: 2 }}>
            <ListItem>
              <ListItemText
                primary="Start each question with the text '$#Question,' followed by the actual question itself."
              />
            </ListItem>
            <Typography variant="body2" sx={{ ml: 2 }}>
              <QuestionAnswer sx={{ fontSize: 20, verticalAlign: 'middle', mr: 1 }} /> Example: $#Question What is the capital of France?
            </Typography>
            <ListItem>
              <ListItemText
                primary="After each question, provide the options for that particular question. Begin the options section with '$#options' on a new line."
              />
            </ListItem>
            <Typography variant="body2" sx={{ ml: 2 }}>
              <QuestionAnswer sx={{ fontSize: 20, verticalAlign: 'middle', mr: 1 }} /> Example:
              <br />
              $#options
              <br />
              &nbsp;&nbsp;&nbsp; $#O Paris
              <br />
              &nbsp;&nbsp;&nbsp; $#O London
              <br />
              &nbsp;&nbsp;&nbsp; $#O Berlin
              <br />
              &nbsp;&nbsp;&nbsp; $#C Rome
            </Typography>
            <ListItem>
              <ListItemText
                primary="In the options section, mark the correct answer with '$#C.' Ensure that only one option has '$#C' before it."
              />
              
            </ListItem>
            <ListItem>
              <ListItemText
                primary="To add a new line, use <br/>."
              />
              
            </ListItem>
          </List>          
          <Button
            variant="contained"
            color="primary"
            startIcon={<GetApp />}
            sx={{ mt: 3 }}
            target='_bank'
            component={Link}
            to={ docsFile }
          >
            Download Sample Document
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Instructions;
