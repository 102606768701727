import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Modal,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Divider from "@mui/material/Divider";
import { useFormik, useFormikContext } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  createUser,
  deleteUser,
  getUserById,
  setSelectedUser,
  updatePassword,
  updateUser,
} from "../../../redux/reducers/user";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";
import { createUserRequest } from "../../../redux/sagas/requests/user";

const UserForm = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { token, role  } = useSelector((state) => state.user);

  const type =
    props && props.type && props.type == "update" ? "update" : "create";
  const selectedUser =
    props && props.selectedUser ? props.selectedUser : undefined;
  const userId = props && props.userId ? props.userId : undefined;
  const [modalOpen, setModalOpen] = useState(false);

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleModalClose() {
    setModalOpen(false);
  }

  function handleModalOpen() {
    setModalOpen(true);
  }

  let schema = {
    firstname: yup
      .string("Enter the First name")
      .required("First name is required"),
    lastname: yup
      .string("Enter the Last name")
      .required("Last name is required"),
    role: yup.string("Select the role").required("Last name is required"),
    email: yup
      .string("Enter the email")
      .email("Enter a valid email")
      .required("Email is required"),
  };
  if (type == "create") {
    schema["password"] = yup
      .string("Enter your password")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required");
  }
  const validationSchema = yup.object(schema);

  const validationSchemaP = yup.object({
    passwordUpdate: yup
      .string("Enter your password")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });

  function generateP() {
    var pass = "";
    var str =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ" + "abcdefghijklmnopqrstuvwxyz0123456789@#$";

    for (let i = 1; i <= 16; i++) {
      var char = Math.floor(Math.random() * str.length + 1);

      pass += str.charAt(char);
    }

    return pass;
  }
  useEffect(() => {
    if (selectedUser) {
      if (selectedUser.first_name)
        formik.setFieldValue("firstname", selectedUser.first_name);
      if (selectedUser.last_name)
        formik.setFieldValue("lastname", selectedUser.last_name);
      if (selectedUser.email) formik.setFieldValue("email", selectedUser.email);
      if (selectedUser.role) formik.setFieldValue("role", selectedUser.role);
    }
  }, [selectedUser]);

  let initValues = {
    firstname: selectedUser ? selectedUser.first_name : "",
    lastname: selectedUser ? selectedUser.last_name : "",
    email: selectedUser ? selectedUser.email : "",
    role: selectedUser ? selectedUser.role : 30,
  };
  if (type == "create") {
    initValues["password"] = "";
  }
  const formik = useFormik({
    initialValues: initValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setError("");
      if (type == "create") {
        const res = await createUserRequest({
          user: values,
          token: token,
        });
        if (res.status === "success") {
          navigate("/users/view/" + btoa(res.result));
        } else {
          setError(res.message ?? "Something went wrong");
        }
      } else if (type == "update") {
        values["userId"] = atob(userId);
        dispatch(updateUser(values, navigate));
      }
    },
  });

  const formikP = useFormik({
    initialValues: {
      passwordUpdate: "",
    },
    validationSchema: validationSchemaP,
    onSubmit: (values) => {
      values["userId"] = atob(userId);
      dispatch(updatePassword(values, navigate));
    },
  });

  const handleGeneratePassword = () => {
    formik.setFieldValue("password", generateP());
  };

  const handleGeneratePasswordUpdate = () => {
    formikP.setFieldValue("passwordUpdate", generateP());
  };

  const handleDeleteAction = () => {
    dispatch(deleteUser(atob(userId), navigate));
  };

  const handleDialogOpen = () => {
    setDeleteDialog(true);
  };

  const handleDialogClose = () => {
    setDeleteDialog(false);
  };

  return (
    <Box>
      {error && (
        <Box sx={{ mt: 2, mb: 2 }}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      <Dialog
        fullScreen={fullScreen}
        open={deleteDialog}
        onClose={handleDialogClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Would like to delete this user?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deleting this user will make the user won't be able to log in to the
            application. If the user created any records in the application,
            this action will make the user restrict to login but the name will
            appear on the records.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDialogClose}>
            No
          </Button>
          <Button autoFocus onClick={handleDeleteAction}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xl={3} xs={12} sm={6} md={3} sx={{ p: 1 }}>
            <TextField
              name="firstname"
              fullWidth={true}
              id="input-firstname"
              label="First name"
              variant="standard"
              autoComplete="off"
              value={formik.values.firstname}
              onChange={formik.handleChange}
              error={
                formik.touched.firstname && Boolean(formik.errors.firstname)
              }
              helperText={formik.touched.firstname && formik.errors.firstname}
            />
          </Grid>
          <Grid item xl={3} xs={12} sm={6} md={3} sx={{ p: 1 }}>
            <TextField
              name="lastname"
              fullWidth={true}
              id="input-lastname"
              label="Last name"
              variant="standard"
              autoComplete="off"
              value={formik.values.lastname}
              onChange={formik.handleChange}
              error={formik.touched.lastname && Boolean(formik.errors.lastname)}
              helperText={formik.touched.lastname && formik.errors.lastname}
            />
          </Grid>
          <Grid item xl={3} xs={12} sm={6} md={3} sx={{ p: 1 }}>
            <TextField
              name="email"
              fullWidth={true}
              id="input-email"
              label="Email"
              variant="standard"
              autoComplete="off"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xl={3} xs={12} sm={6} md={3} sx={{ p: 1 }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="select-role-label">Role</InputLabel>
              <Select
                labelId="select-role-label"
                id="demo-simple-select"
                label="Role"
                name="role"
                value={formik.values.role}
                onChange={formik.handleChange}
              >
                <MenuItem value={30}>Faculty</MenuItem>
                <MenuItem value={20}>Top Faculty</MenuItem>
                {role<10 && (
                    <MenuItem value={10}>Admin</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          {type == "create" && (
            <>
              <Grid item xl={3} xs={12} sm={6} md={3} sx={{ p: 1 }}>
                <TextField
                  name="password"
                  fullWidth={true}
                  id="input-password"
                  label="Password"
                  variant="standard"
                  autoComplete="off"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              <Grid item xl={3} xs={12} sm={6} md={3} sx={{ p: 1, pt: 2 }}>
                <Button
                  className="button-responsive-auto"
                  onClick={handleGeneratePassword}
                  variant="contained"
                >
                  Generate Password
                </Button>
              </Grid>
            </>
          )}
          <Grid item xl={12} xs={12} sm={12} md={12} sx={{ pt: 2 }}>
            <Divider />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xl={12} xs={12} sm={12} md={12} sx={{ pt: 2 }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              maxWidth={true}
            >
              {type == "update" && (
                <>
                  <Grid item xs={12} md="auto">
                    <Button
                      onClick={handleModalOpen}
                      className="button-responsive-auto"
                      variant="outlined"
                      sx={{ mr: 2, mb: 2 }}
                      startIcon={<LockIcon />}
                    >
                      Update Password
                    </Button>
                  </Grid>
                  <Grid item xs={12} md="auto">
                    <Button
                      onClick={handleDialogOpen}
                      className="button-responsive-auto"
                      variant="outlined"
                      sx={{ mr: 2, mb: 2 }}
                      startIcon={<DeleteIcon />}
                    >
                      Delete
                    </Button>
                  </Grid>
                </>
              )}
              <Grid item xs={12} md="auto">
                <Button
                  className="button-responsive-auto"
                  component={Link}
                  to="/users"
                  sx={{ mr: 2, mb: 2 }}
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} md="auto">
                <Button
                  type="submit"
                  className="button-responsive-auto"
                  variant="contained"
                  color="success"
                >
                  {type == "create" ? "Save" : "Update"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Grid item>
            <Paper className="modal-design" variant="outlined">
              <form onSubmit={formikP.handleSubmit}>
                <Box sx={{ p: 1 }}>
                  <h4 className="modal-heading">Update password</h4>
                </Box>
                <Divider />
                <Box sx={{ p: 2 }}>
                  <TextField
                    name="passwordUpdate"
                    fullWidth={true}
                    id="input-passwordUpdate"
                    label="Password"
                    variant="standard"
                    value={formikP.values.passwordUpdate}
                    onChange={formikP.handleChange}
                    error={
                      formikP.touched.passwordUpdate &&
                      Boolean(formikP.errors.passwordUpdate)
                    }
                    helperText={
                      formikP.touched.passwordUpdate &&
                      formikP.errors.passwordUpdate
                    }
                  />
                </Box>
                <Box sx={{ p: 2 }}>
                  <Button
                    onClick={handleGeneratePasswordUpdate}
                    className="button-responsive-auto"
                    variant="contained"
                  >
                    Generate Password
                  </Button>
                </Box>
                <Box sx={{ p: 2, mt: 1 }}>
                  <Divider />
                  <Grid
                    sx={{ mt: 1 }}
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    maxWidth={true}
                  >
                    <Grid item xs={12} md="auto">
                      <Button
                        onClick={handleModalClose}
                        className="button-responsive-auto"
                        sx={{ mr: 2, mb: 2 }}
                        variant="outlined"
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={12} md="auto">
                      <Button
                        type="submit"
                        className="button-responsive-auto"
                        variant="contained"
                        color="success"
                      >
                        Update
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Modal>
    </Box>
  );
};

export default UserForm;
